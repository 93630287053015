import OnlineCell from '@/generic/components/Table/components/OnlineCell';
import { getStatus } from '@/pages/ReportingView/components/DefectiveBeacons/components/ReportingMap';
import type { CellContext, Row } from '@tanstack/react-table';
import { DeviceTypes } from 'common/types';
import LuminaireIcon from 'generic/components/LuminaireIcon';
import Pill from 'generic/components/Pill';
import PrivateWrapper from 'generic/components/PrivateWrapper';
import Tooltip from 'generic/components/Tooltip';
import { MqttSystems } from 'graphql/types';
import { useCallback, useEffect, useState } from 'react';
import { LuArrowRight, LuBadgeCheck, LuCircleX, LuTrash } from 'react-icons/lu';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';
import { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';
import useMqttMessage, {
  Category,
  type DeviceAlert,
  Severity,
} from 'utils/mqtt/useMqttMessage';
import type { LiveBeaconsQueryData } from '../RemoveBeaconModal/RemoveBeaconModal';

export default function ProgressBar({
  ref: forwardedRef,
  progress,
  ...rest
}: { progress: number } & {
  ref?: React.RefObject<HTMLDivElement>;
}) {
  return (
    <div
      ref={forwardedRef}
      className="w-full bg-neutral-200 rounded-full h-1.5 dark:bg-neutral-700"
      {...rest}
    >
      <div
        className="bg-primary-500 h-1.5 rounded-full"
        style={{ width: `${progress}%` }}
      />
    </div>
  );
}

export function AutoUpdateCell({ row }: { row: Row<LiveBeaconsQueryData> }) {
  if (row.original.MqttBeaconSource.Name !== MqttSystems.Mda2) {
    return '-';
  }

  return row.original.IsExcludedFromUpdates ? (
    <LuCircleX className="size-5 text-red-500" />
  ) : (
    <LuBadgeCheck className="size-5 text-green-500" />
  );
}

export const getIncompliantFirmwares = (data: LiveBeaconsQueryData) => {
  return (
    data.MqttBeaconFirmwares?.filter((f) =>
      data.FirmwarePackage?.FirmwarePackageFirmwares?.find(
        (f2) =>
          f2.Firmware.ModuleId === f.Firmware.ModuleId &&
          f2.Firmware.Version !== f.Firmware.Version,
      ),
    ) ?? []
  );
};

export function FWPackageCell({ row }: { row: Row<LiveBeaconsQueryData> }) {
  const firmwarePackage = row.original.FirmwarePackage?.Version;
  const incompliantFirmwares = getIncompliantFirmwares(row.original);
  const compliantPackage = incompliantFirmwares.length === 0;

  return firmwarePackage ? (
    <div className="flex flex-col">
      <div className="flex space-x-1 items-center">
        <div>
          {compliantPackage ? (
            <LuBadgeCheck className="size-5 text-green-500" />
          ) : (
            <LuCircleX className="size-5 text-red-500" />
          )}
        </div>
        <div>{firmwarePackage}</div>
      </div>
      {!compliantPackage && (
        <div className="text-xxs">
          <FormattedMessage id="Non-compliant" />:
          {incompliantFirmwares?.map((i) => (
            <div key={i.Firmware.Id} className="flex space-x-1 items-center">
              <div>
                {i.Firmware.Module.Name} ({i.Index}):
              </div>
              <div className="text-red-500">{i.Firmware.Version}</div>
              <div>
                <LuArrowRight className="size-2 block" />
              </div>
              <div className="text-green-500">
                {
                  row.original.FirmwarePackage?.FirmwarePackageFirmwares.find(
                    (f) => f.Firmware.ModuleId === i.Firmware.ModuleId,
                  )?.Firmware.Version
                }
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    '-'
  );
}

export function DeleteCell({
  onClick,
}: {
  onClick: () => void;
}) {
  return (
    <PrivateWrapper roleRequired={HasuraPermissions.DELETE_MQTTBEACON}>
      <Tooltip
        content={
          <p>
            <LuTrash
              onClick={onClick}
              className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
              data-test-id="delete-beacon"
            />
          </p>
        }
      >
        <FormattedMessage id="Delete" />
      </Tooltip>
    </PrivateWrapper>
  );
}

export function StatusCell({
  cell,
}: CellContext<LiveBeaconsQueryData, unknown>) {
  const [progressBarVisible, setProgressBarVisible] = useState(false);
  const { message: alertData } = useMqttMessage({
    beaconId: cell.row.original.UniqueIdentifier,
    skip:
      cell.row.original.MqttBeaconSource.Name !== MqttSystems.Mda2 ||
      cell.row.original.IsOffline,
  });
  const { IsOffline } = cell.row.original;

  const getProgress = useCallback(
    (data: DeviceAlert) =>
      Number.parseInt(data.payload.message.slice(0, -1) ?? '0', 10),
    [],
  );

  useEffect(() => {
    if (
      alertData?.category === Category.OTA &&
      alertData.severity === Severity.INFO
    ) {
      setProgressBarVisible(true);

      // Hide the progress bar after download finishes
      if (getProgress(alertData) === 100) {
        setTimeout(() => setProgressBarVisible(false), 10_000);
      }
    } else {
      setProgressBarVisible(false);
    }
  }, [alertData?.payload.message, alertData?.severity, alertData, getProgress]);

  return (
    <div className="flex flex-col space-y-2">
      <OnlineCell isOffline={IsOffline} />
      {alertData && progressBarVisible && (
        <Tooltip content={<ProgressBar progress={getProgress(alertData)} />}>
          {getProgress(alertData)}%
        </Tooltip>
      )}
    </div>
  );
}

export function CompliantCell({ row }: { row: Row<LiveBeaconsQueryData> }) {
  const compliant = getIncompliantFirmwares(row.original).length === 0;

  return (
    <Pill
      className={
        compliant
          ? 'bg-green-500! text-green-100!'
          : 'bg-red-500! text-red-100!'
      }
    >
      <FormattedMessage id={compliant ? 'Compliant' : 'Non-compliant'} />
    </Pill>
  );
}

export function BeaconStatusCell({ row }: { row: Row<LiveBeaconsQueryData> }) {
  const statusCode = getStatus(
    row.original.StatusCode,
    row.original.DeviceType?.Name as DeviceTypes,
  );

  return (
    <Pill
      className={
        statusCode === 'Ok'
          ? 'bg-green-500! text-green-100!'
          : 'bg-red-500! text-red-100!'
      }
    >
      {statusCode}
    </Pill>
  );
}

export function SensorTypesCell({
  row,
}: CellContext<LiveBeaconsQueryData, unknown>) {
  const sensors = row.original.Sensors;
  const uniqueSensors = [
    ...new Set(sensors.map((s) => s.SensorType.Name)),
  ].sort((a, b) => a.localeCompare(b));

  return (
    <div className="grid grid-cols-2 gap-1">
      {uniqueSensors.length
        ? uniqueSensors.map((sensorType) => (
            <div
              key={sensorType}
              className="col-span-1 hover:w-fit transition-all"
            >
              <Pill className="hover:shadow-sm z-0 hover:z-40 relative">
                <p className="text-ellipsis overflow-hidden hover:overflow-visible">
                  <FormattedMessage id={sensorType as IntlMessageKeys} /> (
                  {
                    sensors.filter((s) => s.SensorType.Name === sensorType)
                      .length
                  }
                  )
                </p>
              </Pill>
            </div>
          ))
        : '-'}
    </div>
  );
}

export function DeviceModeCell({
  row,
}: CellContext<LiveBeaconsQueryData, unknown>) {
  const modes = row.original.MqttBeaconModes;

  return (
    <div className="grid grid-cols-2 gap-1">
      {modes.length
        ? modes.map(({ Mode, Enabled }) => (
            <div
              key={Mode.Name}
              className="col-span-1 hover:w-fit transition-all"
            >
              <Tooltip
                content={
                  <Pill
                    className={
                      Enabled
                        ? 'bg-green-500! text-green-100!'
                        : 'bg-red-500! text-red-100!'
                    }
                  >
                    <p className="text-ellipsis overflow-hidden hover:overflow-visible">
                      <FormattedMessage id={Mode.Name as IntlMessageKeys} />
                    </p>
                  </Pill>
                }
              >
                <FormattedMessage id={Enabled ? 'Enabled' : 'Disabled'} />
              </Tooltip>
            </div>
          ))
        : '-'}
    </div>
  );
}

export function DeviceTypeCell(
  props: CellContext<LiveBeaconsQueryData, unknown>,
) {
  const { row } = props;
  return (
    <Tooltip
      content={
        <p className="dark:bg-neutral-600 rounded-full p-1">
          <LuminaireIcon
            device={{
              deviceType:
                (row.original.DeviceType?.Name as DeviceTypes | undefined) ??
                DeviceTypes.NODE,
              desksInUse: row.original.NumberOfAvailableDesks ?? 0,
            }}
            size={50}
          />
        </p>
      }
    >
      <p>
        {row.original.DeviceType?.Name ?? <FormattedMessage id="Unknown" />}
      </p>
    </Tooltip>
  );
}
