import Card from '@/generic/components/Card';
import DurationMessage from '@/generic/components/DurationMessage';
import StyledButton from '@/generic/components/Form/Button/StyledButton';
import Subtitle from '@/generic/components/Subtitle';
import type {
  BuildingPartsFragment,
  FloorPartsFragment,
} from '@/graphql/types';
import useStore from '@/model/store';
import { FormattedMessage, useIntl } from '@/translations/Intl';
import { lower, upper } from '@/utils/date';
import { LuCalendarPlus, LuMap, LuStar } from 'react-icons/lu';

interface ReservedProps {
  type: 'Desk' | 'Room';
  name: string;
  building?: BuildingPartsFragment;
  floor?: FloorPartsFragment;
  isReserved: boolean;
  reservationsDuration: string;
  reserveAction: () => void;
  fetching: boolean;
  numberOfTimesReserved: number;
}

export default function Reserved({
  type,
  name,
  building,
  floor,
  isReserved,
  reservationsDuration,
  reserveAction,
  fetching,
  numberOfTimesReserved,
}: ReservedProps) {
  const intl = useIntl();
  const setBuilding = useStore((state) => state.userApi.setBuilding);
  const setFloor = useStore((state) => state.userApi.setFloor);
  const setSelectedFeature = useStore((state) => state.setSelectedFeature);

  return (
    <Card noPadding className="md:p-2 px-2 py-2 w-full md:w-fit">
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col">
          <div className="flex items-center space-x-1">
            <LuStar className="size-5 text-yellow-400" />
            <Subtitle value={intl.formatMessage({ id: `Favorite ${type}` })} />
          </div>
          <div>
            {fetching ? (
              <div className="animate-pulse items-center bg-neutral-200 h-6 w-16 rounded-md" />
            ) : (
              <div className="flex flex-col items-start">
                <div className="flex items-center space-x-1">
                  <div className="font-bold">{name}</div>
                  <div className="text-sm">
                    <FormattedMessage
                      id="Building Floor"
                      values={{
                        building: building?.Name,
                        number: floor?.Number,
                      }}
                    />
                  </div>
                </div>
                {isReserved && (
                  <div className="text-sm">
                    <FormattedMessage id="Not available" />.{' '}
                    <DurationMessage
                      start={lower(reservationsDuration)}
                      end={upper(reservationsDuration)}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col space-y-1 justify-between md:justify-start">
          <div className="text-sm">
            <FormattedMessage
              id="Reserved times"
              values={{ number: numberOfTimesReserved }}
            />
          </div>
          <div className="flex flex-row space-x-1 justify-between md:justify-start">
            <StyledButton
              className="py-1 px-1 md:py-1 md:px-2 flex items-center w-full md:w-fit"
              disabled={isReserved}
              onClick={reserveAction}
            >
              <LuCalendarPlus className="size-5 mr-2" />
              <FormattedMessage id="Reserve it" />
            </StyledButton>
            <StyledButton
              data-test-id={`show-map-button-${type}`}
              className="py-1 px-1 md:py-1 md:px-2 flex items-center w-full md:w-fit"
              onClick={() => {
                if (floor) setFloor(floor);
                if (building) setBuilding(building);
                setSelectedFeature(name);
              }}
            >
              <LuMap className="size-5 mr-2" />
              <FormattedMessage id="Show" />
            </StyledButton>
          </div>
        </div>
      </div>
    </Card>
  );
}
