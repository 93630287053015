import type { Floor } from '@/common/types';
import PrivateWrapper from '@/generic/components/PrivateWrapper';
import Subtitle from '@/generic/components/Subtitle';
import EditFloorModal from '@/pages/AdminView/BuildingView/components/AddFloorModal';
import { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';
import useDecodedParams from '@/utils/useDecodedParams';
import Tooltip from 'generic/components/Tooltip';
import { useState } from 'react';
import { LuPencil, LuSettings2, LuTrash } from 'react-icons/lu';
import { FormattedMessage, useIntl } from 'translations/Intl';
import RemoveFloorModal from '../../../RemoveFloorModal';
import FloorRoomMap from './components/FloorRoomMap';
import MeasureDistanceToggler from './components/MeasureDistanceToggler';

type FloorRoomViewProps = {
  floor: Floor;
};

export default function FloorRoomView({ floor }: FloorRoomViewProps) {
  const intl = useIntl();
  const { buildingName } = useDecodedParams();
  const [panelOpen, setPanelOpen] = useState(false);
  const [
    activateMeasureDistanceInteraction,
    setActivateMeasureDistanceInteraction,
  ] = useState(false);
  const [isEditingFloor, setIsEditingFloor] = useState(false);
  const [floorToRemove, setFloorToRemove] = useState<Floor | undefined>(
    undefined,
  );

  return (
    <div className="space-y-4 overflow-hidden">
      <div className="flex justify-between">
        <div className="flex space-x-2 items-center">
          <Subtitle
            value={intl.formatMessage(
              {
                id: '{number} Floor',
              },
              { number: floor.Number },
            )}
            data-test-id="map-floor-name"
          />
          <div className="flex items-center space-x-1">
            <PrivateWrapper roleRequired={HasuraPermissions.WRITE_FLOOR}>
              <Tooltip
                content={
                  <p>
                    <LuPencil
                      className="size-5 cursor-pointer hover:text-primary-500 transition-colors"
                      id="edit-floor"
                      onClick={() => setIsEditingFloor(true)}
                    />
                  </p>
                }
              >
                <FormattedMessage id="Edit" />
              </Tooltip>
            </PrivateWrapper>
            <PrivateWrapper roleRequired={HasuraPermissions.DELETE_FLOOR}>
              <Tooltip
                content={
                  <p>
                    <LuTrash
                      data-test-id={`delete-floor-${floor.Number}`}
                      className="size-5 cursor-pointer hover:text-primary-500 transition-colors"
                      onClick={() => setFloorToRemove(floor)}
                    />
                  </p>
                }
              >
                <FormattedMessage id="Delete" />
              </Tooltip>
            </PrivateWrapper>
            <PrivateWrapper roleRequired={HasuraPermissions.FEATURE_ROOMMODES}>
              <MeasureDistanceToggler
                floor={floor}
                isActive={activateMeasureDistanceInteraction}
                setIsActive={setActivateMeasureDistanceInteraction}
              />
            </PrivateWrapper>
          </div>
        </div>
        <div>
          <LuSettings2
            className="size-6 cursor-pointer hover:text-primary-500"
            onClick={() => setPanelOpen(true)}
          />
        </div>
      </div>

      <FloorRoomMap
        floor={floor}
        buildingName={buildingName}
        panelOpen={panelOpen}
        setPanelOpen={setPanelOpen}
        activateMeasureDistanceInteraction={activateMeasureDistanceInteraction}
        setActivateMeasureDistanceInteraction={
          setActivateMeasureDistanceInteraction
        }
      />

      <RemoveFloorModal
        toRemove={floorToRemove}
        setToRemove={setFloorToRemove}
      />

      <EditFloorModal
        floor={floor}
        isAdding={isEditingFloor}
        isEditAction
        setIsAdding={setIsEditingFloor}
      />
    </div>
  );
}
