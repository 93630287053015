import { LuUndo } from 'react-icons/lu';
import { FormattedMessage } from 'translations/Intl';
import CanvasButton from '../../../CanvasButton';

interface ResetGeometryButtonProps {
  onGeometryClose: () => void;
}

export default function ResetGeometryButton({
  onGeometryClose,
}: ResetGeometryButtonProps) {
  return (
    <CanvasButton
      tooltip={<FormattedMessage id="Reset" />}
      data-test-id="reset-geometry-button"
      onClick={onGeometryClose}
    >
      <LuUndo className="size-5" />
    </CanvasButton>
  );
}
