import { Action } from '@/common/types';
import Accordion from '@/generic/components/Accordion';
import Card from '@/generic/components/Card';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import Transition from '@/generic/components/Transition';
import useStore from '@/model/store';
import {
  Mda2MessageType,
  generateMda2Topic,
} from '@/pages/AdminView/BuildingView/components/FloorList/components/FloorRoomView/components/FloorRoomMap/components/SelectNewBeaconCard/components/EnrollDevices/components/TopicCell/TopicCell';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import groupBy from '@/utils/groupBy';
import { Button } from '@headlessui/react';
import { MqttSystems, useSetDeviceModesMutation } from 'graphql/types';
import { useMemo, useState } from 'react';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';

export type ModeBeacons = {
  mqttTopic: string;
  mqttSystem: MqttSystems;
  isOffline: boolean;
  name: string;
};

enum DeviceModes {
  DEV = 'dev',
  SERVICE = 'service',
  DEMO = 'demo',
  DEBUG = 'debug',
}

function DeviceModeButton({
  mode,
  setDeviceMode,
}: {
  mode: DeviceModes;
  setDeviceMode: React.Dispatch<
    React.SetStateAction<Map<DeviceModes, boolean>>
  >;
}) {
  const [enabled, setEnabled] = useState(false);

  return (
    <Button
      onClick={() => {
        setEnabled(!enabled);
        setDeviceMode((prev) => new Map([...prev, [mode, enabled]]));
      }}
    >
      <Card
        className={`cursor-pointer col-span-1 h-16 w-full flex items-center justify-center transition-colors delay-150 duration-300 ease-in-out ${enabled ? 'bg-green-100! text-green-500' : 'bg-red-100! text-red-500'}`}
        key={mode}
        noPadding
      >
        <div className="text-center">
          <FormattedMessage id={mode as IntlMessageKeys} />
        </div>
      </Card>
    </Button>
  );
}

interface SetDeviceModesModalProps {
  setBeaconsToSetModes: (beaconsToSetModes?: ModeBeacons[]) => void;
  beaconsToSetModes?: ModeBeacons[];
}

export default function SetDeviceModesModal({
  beaconsToSetModes,
  setBeaconsToSetModes,
}: SetDeviceModesModalProps) {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const toast = useToast();
  const userRoles = useStore((state) => state.user)?.roles;
  const [deviceMode, setDeviceMode] = useState(new Map<DeviceModes, boolean>());
  const [{ fetching }, setDeviceModes] = useSetDeviceModesMutation();

  const resetValues = () => setBeaconsToSetModes(undefined);

  const beacons = useMemo(
    () => beaconsToSetModes && groupBy(beaconsToSetModes, (i) => i.mqttSystem),
    [beaconsToSetModes],
  );

  const mda2OnlineBeacons = beacons?.MDA2?.filter((b) => !b.isOffline);

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Set Modes',
      })}
      action={Action.UPDATE}
      open={!!beaconsToSetModes}
      setShowModal={resetValues}
      footer={
        <ModalFooter
          isLoading={fetching}
          proceed={intl.formatMessage({
            id: 'Set Modes',
          })}
          // Disable it when there is only Bluerange, as only MDA2 is supported
          disabled={
            (beacons &&
              MqttSystems.Bluerange in beacons &&
              beacons?.Bluerange?.length === beaconsToSetModes?.length) ||
            !!beacons?.MDA2?.filter((b) => b.isOffline).length
          }
          action={Action.UPDATE}
          onProceed={() => {
            if (mda2OnlineBeacons?.length) {
              setDeviceModes(
                {
                  Input: {
                    mqttSystem: MqttSystems.Mda2,
                    modeInfos: [...deviceMode.entries()].flatMap(
                      ([mode, enabled]) =>
                        mda2OnlineBeacons?.map((b) => ({
                          mode: { name: mode, enabled },
                          topic: generateMda2Topic(
                            b.mqttTopic,
                            Mda2MessageType.CMD,
                          ),
                        })),
                    ),
                  },
                },
                hasuraHeader(HasuraPermissions.FEATURE_DEVICEMODES),
              ).then((data) => {
                if (!data.error) {
                  resetValues();
                }
                toast(data, {
                  message: {
                    type: 'success',
                    content: intl.formatMessage({ id: 'Set device modes' }),
                  },
                });
              });
            }
          }}
          onCancel={resetValues}
        />
      }
    >
      {beacons &&
        Object.values(MqttSystems).map(
          (system) =>
            system in beacons && (
              <Accordion
                key={system}
                initialStateOpen
                title={
                  <FormattedMessage
                    id="Mqtt system elements"
                    values={{
                      mqttSystem: system,
                      count: beacons[system]?.filter((b) => !b.isOffline)
                        .length,
                    }}
                  />
                }
              >
                <>
                  <Transition show={system === MqttSystems.Bluerange}>
                    <FormattedMessage id="Not supported" />
                  </Transition>
                  <Transition
                    show={system === MqttSystems.Mda2}
                    className="space-y-2"
                  >
                    <Transition show={!!mda2OnlineBeacons?.length}>
                      <div className="grid grid-cols-2 gap-2">
                        {Object.values(DeviceModes)
                          .filter((mode) =>
                            userRoles?.includes(HasuraPermissions.READ_ALL)
                              ? true
                              : mode === DeviceModes.DEMO,
                          )
                          .map((mode) => (
                            <DeviceModeButton
                              key={mode}
                              mode={mode as DeviceModes}
                              setDeviceMode={setDeviceMode}
                            />
                          ))}
                      </div>
                    </Transition>
                    <Transition
                      show={
                        !!beacons[system]?.filter((b) => b.isOffline).length
                      }
                    >
                      <div className="text-red-500 text-xs">
                        <FormattedMessage id="Command won't be sent for the following offline beacons" />
                        <ul className="list-disc pl-5">
                          {beacons[system]
                            ?.filter((b) => b.isOffline)
                            .map((b) => (
                              <li key={b.name}>{b.name}</li>
                            ))}
                        </ul>
                      </div>
                    </Transition>
                  </Transition>
                </>
              </Accordion>
            ),
        )}
    </Modal>
  );
}
