import Switch from '@/generic/components/Form/Switch';
import { FormattedMessage } from '@/translations/Intl';
import useStore from 'model/store';
import CheckMark from '../Tile/CheckMark';
import TileButton from '../Tile/TileButton';

export default function BackgroundSwitch() {
  const backgroundEnabled = useStore(
    (state) => state.userSettings.backgroundEnabled,
  );
  const setBackgroundEnabled = useStore(
    (state) => state.userApi.setBackgroundEnabled,
  );
  const backgroundAnimationEnabled = useStore(
    (state) => state.userSettings.backgroundAnimationEnabled,
  );
  const setBackgroundAnimationEnabled = useStore(
    (state) => state.userApi.setBackgroundAnimationEnabled,
  );

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-6 xl:space-x-6 xl:space-y-0 xl:flex-row w-auto relative">
        <TileButton onClick={() => setBackgroundEnabled(false)}>
          <div className="w-auto relative">
            <div className="transition-all shadow-sm hover:shadow-lg rounded-lg">
              <div className="bg-neutral-50 dark:bg-neutral-900 h-24 rounded-lg" />
            </div>
            {!backgroundEnabled && <CheckMark />}
          </div>
        </TileButton>
        <TileButton onClick={() => setBackgroundEnabled(true)}>
          <div className="w-auto relative">
            <div className="transition-all flex shadow-sm hover:shadow-lg rounded-lg bg-neutral-50 dark:bg-neutral-900">
              <div className="bg-(image:--pattern) dark:bg-(image:--pattern-dark) h-24 bg-cover w-48 rounded-lg" />
            </div>
            {backgroundEnabled && <CheckMark />}
          </div>
        </TileButton>
      </div>
      <Switch
        isEnabled={backgroundAnimationEnabled}
        onSetEnable={setBackgroundAnimationEnabled}
        label={<FormattedMessage id="Background Animation" />}
      />
    </div>
  );
}
