import Tooltip from 'generic/components/Tooltip';
import { LuCheck, LuCircleAlert, LuRuler } from 'react-icons/lu';
import { FormattedMessage } from 'translations/Intl';

import type { Floor } from 'common/types';
import { useMemo } from 'react';

interface MeasureDistanceTogglerProps {
  floor: Floor;
  isActive: boolean;
  setIsActive: (checked: boolean) => void;
}

export default function MeasureDistanceToggler({
  floor,
  isActive,
  setIsActive,
}: MeasureDistanceTogglerProps): React.JSX.Element {
  const isMissing = useMemo(() => floor.GeometryUnitPerMeter === null, [floor]);

  return (
    <div className="flex justify-center items-center relative">
      <Tooltip
        content={
          <div
            className={`flex justify-center items-center rounded-full pr-2 ${
              isActive ? 'bg-primary-200 text-primary-500' : ''
            }`}
          >
            <LuRuler
              className="size-5 cursor-pointer hover:text-primary-500 transition-colors"
              id="measure-floor-ratio"
              onClick={() => setIsActive(!isActive)}
            />
          </div>
        }
      >
        <FormattedMessage
          id={isMissing ? 'Missing-Measure' : 'Measure reference line'}
        />
      </Tooltip>
      {!isActive &&
        (isMissing ? (
          <LuCircleAlert
            className="absolute top-0 right-0 bg-red-200/70 rounded-full size-4 p-1 text-red-500"
            id="measure-floor-ratio"
          />
        ) : (
          <LuCheck
            className="absolute top-0 right-0 bg-green-200/70 rounded-full size-4 p-1 text-green-500"
            id="measure-floor-ratio"
          />
        ))}
    </div>
  );
}
