import Button from '@/generic/components/Form/Button';
import useStore from 'model/store';
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { LuExpand, LuShrink } from 'react-icons/lu';
import type { IntlMessageKeys } from 'translations/Intl';
import Tooltip from '../Tooltip';

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
  }
}

function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen().catch(() => {});
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
}

export interface CardProps {
  id?: string;
  active?: boolean;
  title?: string | IntlMessageKeys;
  children: React.ReactNode;
  className?: string;
  noPadding?: boolean;
  infoText?: string;
  fullScreenButton?: boolean;
  ref?: React.RefObject<HTMLDivElement>;
}

let drag = false;

const onMouseMove = () => {
  drag = true;
};

const onMouseDown = () => {
  drag = false;
};

export default function Card({
  ref: forwardedRef,
  id,
  title,
  children,
  active = false,
  className = '',
  noPadding = false,
  infoText,
  fullScreenButton = false,
  ...rest
}: CardProps) {
  const internalRef = useRef<HTMLDivElement>(null);
  // In order to merge local ref (for full screen) & forwardedRef (for tooltip) https://stackoverflow.com/a/77055174
  useImperativeHandle(
    forwardedRef,
    () => internalRef.current as HTMLInputElement,
  );

  const [isFullScreen, setIsFullScreen] = useState(false);
  const setIsFullScreenOn = useStore((state) => state.setIsFullScreenOn);

  const onFullScreenChange = useCallback(() => {
    if (!document.fullscreenElement) {
      setIsFullScreen(false);
    }
  }, []);

  const onClick = useCallback(() => {
    if (!drag) {
      // Delay to let the event 'click' to propagate to the children (onFeaturesClick on map)
      setTimeout(() => {
        setIsFullScreenOn(false);
        exitFullscreen();
      }, 500);
    }
  }, [setIsFullScreenOn]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: only react on isFullScreen
  useEffect(() => {
    if (internalRef?.current && setIsFullScreenOn) {
      if (isFullScreen) {
        setIsFullScreenOn(true);
        internalRef?.current.requestFullscreen();
        document.removeEventListener('mousedown', onMouseDown);
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('click', onClick);
        document.removeEventListener('fullscreenchange', onFullScreenChange);

        document.addEventListener('mousedown', onMouseDown);
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onClick);
        document.addEventListener('fullscreenchange', onFullScreenChange);
      } else {
        setIsFullScreenOn(false);
        exitFullscreen();
      }
    }
    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onClick);
      document.removeEventListener('fullscreenchange', onFullScreenChange);
    };
  }, [isFullScreen]);

  return (
    <div
      ref={internalRef}
      id={id}
      className={`bg-primary-0 dark:bg-neutral-800 ${
        !isFullScreen ? 'rounded-2xl' : ''
      } ${
        active
          ? 'shadow-sm'
          : 'border border-neutral-200 dark:border-neutral-700'
      } ${noPadding ? 'px-0 py-0 md:p-0' : 'py-1 px-2 md:p-6'} ${className ?? ''}`}
      {...rest}
    >
      {title && (
        <span className="text-xs font-semibold absolute top-2 left-2">
          {title}
        </span>
      )}
      {infoText && (
        <span className="text-xs absolute top-2 right-2 z-20">
          <Tooltip>{infoText}</Tooltip>
        </span>
      )}
      {fullScreenButton && (
        <Button
          className="absolute bottom-6 right-6 z-20 cursor-pointer hover:text-primary-500"
          onClick={(evt) => {
            setIsFullScreen(!isFullScreen);
            evt.stopPropagation(); // To avoid triggering onClick Callback
          }}
        >
          {isFullScreen ? (
            <LuShrink className="size-5" />
          ) : (
            <LuExpand className="size-5" />
          )}
        </Button>
      )}
      {children}
    </div>
  );
}
