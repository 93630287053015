import {
  type ClimateType,
  type InitialSetup,
  Languages,
  RoomTypes,
  Themes,
  type UserProps,
} from '@/common/types';
import type {
  BuildingPartsFragment,
  FloorPartsFragment,
  RoomPartsFragment,
  SetupMqttDeviceInput,
} from '@/graphql/types';
import keycloak from '@/keycloak';
import { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';
import { hexToBase64Image } from '@/utils/hexToBase64';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface State {
  user?: UserProps;
  AAWActivated: boolean;
  selectedFeature?: string;
  subscriptionPermission: HasuraPermissions;
  token?: string;
  enrollmentConfiguration?: {
    configuration?: Omit<SetupMqttDeviceInput, 'topics'>;
  };
  initialSetupSteps: InitialSetup;
  isSetup: boolean;
  organizationSettings: {
    warmMinutesPolicy: number;
    reservationLimit?: number;
    organizationUuid?: string;
    organizationName?: string;
    offlineMinutes?: number;
    organizationLogo: { dark?: string; light?: string };
    cleaningTimes: { clean: number; check: number };
    businessHours: {
      BusinessHours: string;
      LunchHours?: string;
    };
  };
  userSettings: {
    theme: { color: Themes; useDeviceTheme: boolean };
    themeColor: string;
    backgroundEnabled: boolean;
    backgroundAnimationEnabled: boolean;
    language: Languages;
    dateRange: { start: string; end?: string };
    building?: BuildingPartsFragment;
    floor?: FloorPartsFragment;
    room?: RoomPartsFragment;
    roomTypes: RoomTypes[];
    climateTypes: ClimateType[] | null;
    labels?: string[];
    reportDate?: string;
  };
  isFullScreenOn: boolean;
}

interface Action {
  userApi: {
    setTheme: (theme: State['userSettings']['theme']) => void;
    setThemeColor: (themeColor: State['userSettings']['themeColor']) => void;
    setBackgroundEnabled: (
      background: State['userSettings']['backgroundEnabled'],
    ) => void;
    setBackgroundAnimationEnabled: (
      background: State['userSettings']['backgroundAnimationEnabled'],
    ) => void;
    setLanguage: (language: State['userSettings']['language']) => void;
    setBuilding: (building: State['userSettings']['building']) => void;
    setFloor: (floor: State['userSettings']['floor']) => void;
    setRoom: (room: State['userSettings']['room']) => void;
    setRoomTypes: (roomTypes: State['userSettings']['roomTypes']) => void;
    setDateRange: (dateRange: State['userSettings']['dateRange']) => void;
    setClimateTypes: (
      climateTypes: State['userSettings']['climateTypes'],
    ) => void;
    setLabels: (labels: State['userSettings']['labels']) => void;
    setReportDate: (reportDate: State['userSettings']['reportDate']) => void;
  };
  organizationApi: {
    setWarmMinutesPolicy: (
      warmMinutesPolicy: State['organizationSettings']['warmMinutesPolicy'],
    ) => void;
    setReservationLimit: (
      reservationLimit: State['organizationSettings']['reservationLimit'],
    ) => void;
    setCleaningTimes: (
      cleaningTimes: State['organizationSettings']['cleaningTimes'],
    ) => void;
    setBusinessHours: (
      businessHours: State['organizationSettings']['businessHours'],
    ) => void;
    setOrganizationUuid: (
      organizationUuid: State['organizationSettings']['organizationUuid'],
    ) => void;
    setOrganizationName: (
      organizationName: State['organizationSettings']['organizationName'],
    ) => void;
    setOfflineMinutes: (
      offlineMinutes: State['organizationSettings']['offlineMinutes'],
    ) => void;
    setOrganizationLogo: (
      organizationLogo: State['organizationSettings']['organizationLogo'],
    ) => void;
  };
  setUser: (user: State['user']) => void;
  setSelectedFeature: (selectedFeature: State['selectedFeature']) => void;
  setSubscriptionPermission: (
    subscriptionPermission: State['subscriptionPermission'],
  ) => void;
  setToken: (token: State['token']) => void;
  setEnrollmentConfiguration: (
    enrollmentConfiguration: State['enrollmentConfiguration'],
  ) => void;
  setInitialSetupSteps: (
    initialSetupSteps: keyof State['initialSetupSteps'],
  ) => void;
  setIsSetup: (isSetup: State['isSetup']) => void;
  setIsFullScreenOn: (isFullScreenOn: State['isFullScreenOn']) => void;
  setAAWActivated: (AAWActivated: State['AAWActivated']) => void;
}

const useStore = create<State & Action>()(
  persist(
    (set) => ({
      userSettings: {
        theme: {
          color: window.matchMedia('(prefers-color-scheme: dark)').matches
            ? Themes.DARK
            : Themes.LIGHT,
          useDeviceTheme: true,
        },
        themeColor: 'indigo',
        backgroundEnabled: false,
        backgroundAnimationEnabled: false,
        language: navigator.language.includes('en')
          ? Languages.EN
          : Languages.DE,
        dateRange: {
          start: startOfDay(subMonths(new Date(), 1)).toISOString(),
          end: endOfDay(new Date()).toISOString(),
        },
        building: undefined,
        floor: undefined,
        room: undefined,
        roomTypes: [RoomTypes.DESKS, RoomTypes.MEETING],
        climateTypes: [],
        labels: undefined,
        reportDate: undefined,
      },
      organizationSettings: {
        warmMinutesPolicy: 0,
        reservationLimit: undefined,
        cleaningTimes: {
          check: 10,
          clean: 30,
        },
        businessHours: {
          BusinessHours: '[09:00:00, 16:30:00]',
          LunchHours: '[11:30:00, 13:30:00]',
        },
        offlineMinutes: 360,
        organizationUuid: undefined,
        organizationLogo: {
          dark: undefined,
          light: undefined,
        },
        organizationName: undefined,
      },
      userApi: {
        setTheme: (theme) =>
          set((state) => {
            window.document.documentElement.setAttribute(
              'data-dark',
              `${theme.color === Themes.DARK}`,
            );
            return {
              userSettings: {
                ...state.userSettings,
                theme,
              },
            };
          }),
        setThemeColor: (themeColor) =>
          set((state) => {
            window.document.documentElement.setAttribute(
              'data-theme',
              `${themeColor}`,
            );

            return {
              userSettings: {
                ...state.userSettings,
                themeColor,
              },
            };
          }),
        setBackgroundEnabled: (backgroundEnabled) =>
          set((state) => {
            const root = window.document.body;
            if (backgroundEnabled) {
              root.classList.add(
                'bg-(image:--pattern)',
                'dark:bg-(image:--pattern-dark)',
                'bg-cover',
              );
            } else {
              root.classList.remove(
                'bg-(image:--pattern)',
                'dark:bg-(image:--pattern-dark)',
                'bg-cover',
              );
            }

            return {
              userSettings: {
                ...state.userSettings,
                backgroundEnabled,
              },
            };
          }),
        setBackgroundAnimationEnabled: (backgroundAnimationEnabled) =>
          set((state) => ({
            userSettings: {
              ...state.userSettings,
              backgroundAnimationEnabled,
            },
          })),
        setLanguage: (language) =>
          set((state) => ({
            userSettings: {
              ...state.userSettings,
              language,
            },
          })),
        setBuilding: (building) =>
          set((state) => ({
            userSettings: {
              ...state.userSettings,
              building,
            },
          })),
        setFloor: (floor) =>
          set((state) => ({
            userSettings: {
              ...state.userSettings,
              floor,
            },
          })),
        setRoom: (room) =>
          set((state) => ({
            userSettings: {
              ...state.userSettings,
              room,
            },
          })),
        setRoomTypes: (roomTypes) =>
          set((state) => ({
            userSettings: {
              ...state.userSettings,
              roomTypes,
            },
          })),
        setDateRange: (dateRange) =>
          set((state) => ({
            userSettings: {
              ...state.userSettings,
              dateRange,
            },
          })),
        setClimateTypes: (climateTypes) =>
          set((state) => ({
            userSettings: {
              ...state.userSettings,
              climateTypes,
            },
          })),
        setLabels: (labels) =>
          set((state) => ({
            userSettings: {
              ...state.userSettings,
              labels,
            },
          })),
        setReportDate: (reportDate) =>
          set((state) => ({
            userSettings: {
              ...state.userSettings,
              reportDate,
            },
          })),
      },
      organizationApi: {
        setWarmMinutesPolicy: (warmMinutesPolicy) =>
          set((state) => ({
            organizationSettings: {
              ...state.organizationSettings,
              warmMinutesPolicy,
            },
          })),
        setReservationLimit: (reservationLimit) =>
          set((state) => ({
            organizationSettings: {
              ...state.organizationSettings,
              reservationLimit,
            },
          })),
        setCleaningTimes: (cleaningTimes) =>
          set((state) => ({
            organizationSettings: {
              ...state.organizationSettings,
              cleaningTimes,
            },
          })),
        setBusinessHours: (businessHours) =>
          set((state) => ({
            organizationSettings: {
              ...state.organizationSettings,
              businessHours,
            },
          })),
        setOrganizationUuid: (organizationUuid) =>
          set((state) => ({
            organizationSettings: {
              ...state.organizationSettings,
              organizationUuid,
            },
          })),
        setOrganizationName: (organizationName) =>
          set((state) => ({
            organizationSettings: {
              ...state.organizationSettings,
              organizationName,
            },
          })),
        setOfflineMinutes: (offlineMinutes) =>
          set((state) => ({
            organizationSettings: {
              ...state.organizationSettings,
              offlineMinutes,
            },
          })),
        setOrganizationLogo: (organizationLogo) =>
          set((state) => ({
            organizationSettings: {
              ...state.organizationSettings,
              organizationLogo: {
                light: organizationLogo.light
                  ? hexToBase64Image(organizationLogo.light)
                  : undefined,
                dark: organizationLogo.dark
                  ? hexToBase64Image(organizationLogo.dark)
                  : undefined,
              },
            },
          })),
      },
      user: undefined,
      setUser: (user) => set(() => ({ user })),
      selectedFeature: undefined,
      setSelectedFeature: (selectedFeature) => set(() => ({ selectedFeature })),
      subscriptionPermission: HasuraPermissions.READ,
      setSubscriptionPermission: (subscriptionPermission) =>
        set(() => ({ subscriptionPermission })),
      token: keycloak.token,
      setToken: (token) => set(() => ({ token })),
      enrollmentConfiguration: undefined,
      setEnrollmentConfiguration: (enrollmentConfiguration) =>
        set(() => ({ enrollmentConfiguration })),
      initialSetupSteps: {
        clickedAdmin: false,
        addedBuilding: false,
        clickedBuilding: false,
        addedFloor: false,
        clickedAddDevice: false,
        clickedEnroll: false,
      },
      setInitialSetupSteps: (initialSetupSteps) =>
        set((state) => ({
          initialSetupSteps: {
            ...state.initialSetupSteps,
            [initialSetupSteps]: true,
          },
        })),
      isSetup: true,
      setIsSetup: (isSetup) => set(() => ({ isSetup })),
      isFullScreenOn: false, // Store if FullScreen is on (https://developer.mozilla.org/en-US/docs/Web/API/Fullscreen_API)
      setIsFullScreenOn: (isFullScreenOn) => set(() => ({ isFullScreenOn })),
      AAWActivated: false,
      setAAWActivated: (AAWActivated) => set(() => ({ AAWActivated })),
    }),
    {
      name: 'app-storage',
      partialize: (state) =>
        Object.fromEntries(
          // Do not save the token and enrollmentConfiguration in local storage for security resons!
          // Also prevent saving the functions as they will otherwise be undefined
          Object.entries(state).filter(
            ([key]) =>
              ![
                'token',
                'enrollmentConfiguration',
                'organizationApi',
                'userApi',
                // Organization settings will be fetched from database
                'organizationSettings',
              ].includes(key),
          ),
        ),
    },
  ),
);

export default useStore;
