import Tab from '@/generic/components/Tab';
import Title from '@/generic/components/Title';
import Loader from '@/generic/components/layout/BarLoader';
import { useOrganizationInfoQuery } from '@/graphql/types';
import useStore from '@/model/store';
import { formattedDistance, lower, upper } from '@/utils/date';
import format from '@/utils/format';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import { subWeeks } from 'date-fns';
import { useMemo, useState } from 'react';
import {
  LuChartBarBig,
  LuChartBarStacked,
  LuGlobe,
  LuSparkles,
} from 'react-icons/lu';
import { FormattedMessage } from 'translations/Intl';

export default function AnalyticsView(): React.JSX.Element {
  const [variables] = useState({
    Start: subWeeks(new Date(), 4),
    End: new Date(),
  });
  const warmMinutesPolicy = useStore(
    (state) => state.organizationSettings.warmMinutesPolicy,
  );
  const businessHours = useStore(
    (state) => state.organizationSettings.businessHours,
  );
  const hasuraHeader = useHasuraHeader();

  const [{ data, fetching: loading }] = useOrganizationInfoQuery({
    variables,
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
  });

  const updatedAt = useMemo(
    () =>
      data?.f_history_desks_occupancy_hourly.length &&
      data.f_history_desks_occupancy_hourly[0]?.UpdatedAt
        ? new Date(data.f_history_desks_occupancy_hourly[0].UpdatedAt)
        : new Date(),
    [
      data,
      data?.f_history_desks_occupancy_hourly,
      data?.f_history_desks_occupancy_hourly[0]?.UpdatedAt,
    ],
  );

  const businessHoursWithoutLunch = useMemo(
    () =>
      businessHours.BusinessHours &&
      `${format(lower(businessHours.BusinessHours), 'HH:mm')}-${format(
        upper(businessHours.BusinessHours),
        'HH:mm',
      )}`,
    [businessHours],
  );

  const businessHoursWithLunch = useMemo(
    () =>
      businessHours.LunchHours &&
      `${format(lower(businessHours.BusinessHours), 'HH:mm')}-${format(
        lower(businessHours.LunchHours),
        'HH:mm',
      )} / ${format(upper(businessHours.LunchHours), 'HH:mm')}-${format(
        upper(businessHours.BusinessHours),
        'HH:mm',
      )}`,
    [businessHours],
  );

  return (
    <>
      <Loader loading={loading} />
      <div className="flex flex-col md:flex-row justify-between items-center space-y-2 pb-2 md:space-y-0 md:pb-0">
        <Title value="Analytics" />
        <div className="flex justify-between">
          <div className="text-xs flex flex-col text-center md:text-right">
            <div>
              <div>
                <FormattedMessage id="Warm minutes policy" />{' '}
                <b>{warmMinutesPolicy}</b> <FormattedMessage id="minutes" />
              </div>
              <FormattedMessage id="Business hours" />{' '}
              <b>{businessHoursWithLunch ?? businessHoursWithoutLunch}</b>
            </div>
            <div>
              <FormattedMessage id="Last updated" />{' '}
              <b>{formattedDistance(updatedAt)}</b>
            </div>
          </div>
        </div>
      </div>

      <Tab
        tabs={[
          {
            path: '',
            end: true, // avoid to be always active for all routes
            userRole: HasuraPermissions.VIEW_ANALYTICS,
            name: 'Desk Occupancy',
            renderIcon: ({ className }) => (
              <LuChartBarStacked className={className} />
            ),
          },
          {
            path: 'rooms',
            userRole: HasuraPermissions.VIEW_ANALYTICS,
            name: 'Meeting Room Occupancy',
            renderIcon: ({ className }) => (
              <LuChartBarBig className={className} />
            ),
          },
          {
            path: 'cleaning',
            userRole: HasuraPermissions.VIEW_CLEANING,
            name: 'Cleaning',
            renderIcon: ({ className }) => <LuSparkles className={className} />,
          },
          {
            path: 'climate',
            userRole: HasuraPermissions.VIEW_CLIMATE,
            name: 'Climate',
            renderIcon: ({ className }) => <LuGlobe className={className} />,
          },
        ]}
      />
    </>
  );
}
