import type OLMap from 'ol/Map';
import CanvasButton from 'pages/AdminView/BuildingView/components/FloorList/components/FloorRoomView/components/FloorRoomMap/components/CanvasButton';
import { useMemo } from 'react';
import { LuMinus, LuPlus } from 'react-icons/lu';
import { FormattedMessage } from 'translations/Intl';

interface ZoomButtonsProps {
  zoom: number;
  map: OLMap;
}

export default function ZoomButtons({
  zoom,
  map,
}: ZoomButtonsProps): React.JSX.Element {
  const updateZoom = (zoomAction: number) => {
    const newZoom = zoom + zoomAction;
    map.getView().cancelAnimations();
    map.getView().animate({
      zoom: newZoom,
      duration: 700,
    });
  };

  const zoomInDisabled = useMemo(
    () =>
      zoom >=
      (map.getView().getConstrainedZoom(map.getView().getMaxZoom()) ?? 0),
    [zoom, map],
  );

  const zoomOutDisabled = useMemo(
    () =>
      zoom <=
      (map.getView().getConstrainedZoom(map.getView().getMinZoom()) ?? 0),
    [zoom, map],
  );

  return (
    <div className="flex flex-col space-y-2">
      <CanvasButton
        onClick={() => updateZoom(1)}
        disabled={zoomInDisabled}
        tooltip={<FormattedMessage id="Zoom in" />}
      >
        <LuPlus className="size-5" />
      </CanvasButton>
      <CanvasButton
        onClick={() => updateZoom(-1)}
        disabled={zoomOutDisabled}
        tooltip={<FormattedMessage id="Zoom out" />}
      >
        <LuMinus className="size-5" />
      </CanvasButton>
    </div>
  );
}
