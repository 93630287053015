import PrivateWrapper from '@/generic/components/PrivateWrapper';
import Tooltip from '@/generic/components/Tooltip';
import Transition from '@/generic/components/Transition';
import useStore from '@/model/store';
import { FormattedMessage } from '@/translations/Intl';
import { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';
import type { CellContext, Row } from '@tanstack/react-table';
import { LuPencil, LuTrash, LuUser } from 'react-icons/lu';
import type { KeycloakTableUser } from '../AddUserModal/AddUserModal';

export function NameCell(props: CellContext<KeycloakTableUser, unknown>) {
  const { row } = props;

  return (
    <div className="flex items-center">
      <div className="shrink-0 size-10">
        <div
          className={`${
            !row.original.enabled
              ? 'bg-primary-200 dark:bg-primary-400'
              : 'bg-primary-500 dark:bg-primary-700'
          } size-10 mr-4 focus:outline-hidden rounded-full shadow-md flex items-center justify-center text-white select-none capitalize`}
        >
          {row.original.username[0]}
        </div>
      </div>
      <div className="ml-4 text-sm ">{row.original.username}</div>
    </div>
  );
}

export function ActionCell({
  row,
  setSelectedUsers,
  setOpenConfirmationModal,
  setOpenDeleteUserModal,
  setDisabled,
  setSelectedUser,
}: {
  row: Row<KeycloakTableUser>;
  setSelectedUser: (users: KeycloakTableUser) => void;
  setSelectedUsers: (users: Row<KeycloakTableUser>[] | undefined) => void;
  setOpenConfirmationModal: (open: boolean) => void;
  setOpenDeleteUserModal: (open: boolean) => void;
  setDisabled: (disabled: boolean) => void;
}) {
  const user = useStore((state) => state.user);

  const { enabled } = row.original;

  return (
    <div className="flex space-x-2 space-x-reverse flex-row-reverse items-center">
      <Transition show={user?.email !== row.original.email}>
        <PrivateWrapper roleRequired={HasuraPermissions.VIEW_USERGROUP}>
          <Tooltip
            content={
              <p>
                <LuUser
                  onClick={() => {
                    setDisabled(!!enabled);
                    setOpenConfirmationModal(true);
                    setSelectedUsers([row]);
                  }}
                  className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
                  data-test-id="disable-user"
                />
              </p>
            }
          >
            <FormattedMessage id={enabled ? 'Disable' : 'Enable'} />
          </Tooltip>
        </PrivateWrapper>
      </Transition>
      <Transition show={user?.email !== row.original.email}>
        <PrivateWrapper roleRequired={HasuraPermissions.VIEW_USERGROUP}>
          <Tooltip
            content={
              <p>
                <LuTrash
                  onClick={() => {
                    setSelectedUsers([row]);
                    setOpenDeleteUserModal(true);
                  }}
                  className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
                  data-test-id="delete-user"
                />
              </p>
            }
          >
            <FormattedMessage id="Delete" />
          </Tooltip>
        </PrivateWrapper>
      </Transition>
      <PrivateWrapper roleRequired={HasuraPermissions.VIEW_USERGROUP}>
        <Tooltip
          content={
            <p>
              <LuPencil
                className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
                onClick={() => {
                  setSelectedUser(row.original as KeycloakTableUser);
                }}
                data-test-id="edit-user"
              />
            </p>
          }
        >
          <FormattedMessage id="Edit" />
        </Tooltip>
      </PrivateWrapper>
    </div>
  );
}
