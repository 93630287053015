import BreadCrumbs from '@/generic/components/BreadCrumbs';
import PrivateWrapper from '@/generic/components/PrivateWrapper';
import Title from '@/generic/components/Title';
import Loader from '@/generic/components/layout/BarLoader';
import { useBuildingQuery } from '@/graphql/types';
import { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';
import useDecodedParams from '@/utils/useDecodedParams';
import Tooltip from 'generic/components/Tooltip';
import Transition from 'generic/components/Transition/Transition';
import useStore from 'model/store';
import { useMemo, useState } from 'react';
import { LuPencil } from 'react-icons/lu';
import { FormattedMessage } from 'translations/Intl';
import EditBuildingModal from '../components/BuildingList/components/AddBuildingModal';
import FloorList from './components/FloorList';

export default function EditBuildingView() {
  const { buildingName, floorNumber } = useDecodedParams();
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [floor, setFloor] = useState(
    Number.parseInt(typeof floorNumber === 'string' ? floorNumber : '1', 10),
  );
  const organizationName = useStore(
    (state) => state.organizationSettings.organizationName,
  );

  const [{ data: buildingData, fetching: loadingBuilding }] = useBuildingQuery({
    variables: { BuildingName: buildingName },
  });

  const building = useMemo(
    () => buildingData?.Buildings[0],
    [buildingData?.Buildings[0]],
  );

  return (
    <div className="relative space-y-4">
      <div>
        <Title value="Building settings" />
      </div>
      <Loader loading={loadingBuilding} />
      <div className="flex space-x-4">
        {!!buildingName && (
          <BreadCrumbs
            items={[
              { to: '/admin', text: organizationName ?? '' },
              {
                text: buildingName,
                component: (
                  <PrivateWrapper
                    roleRequired={HasuraPermissions.WRITE_BUILDING}
                  >
                    <div className="flex items-center">
                      {building ? (
                        <Tooltip
                          content={
                            <p>
                              <LuPencil
                                className="size-5 cursor-pointer hover:text-primary-500 transition-all"
                                id="edit-building"
                                onClick={() => setIsEditing(true)}
                              />
                            </p>
                          }
                        >
                          <FormattedMessage id="Edit" />
                        </Tooltip>
                      ) : (
                        <Transition show={loadingBuilding}>
                          <div className="size-5 animate-pulse bg-neutral-200 rounded-md" />
                        </Transition>
                      )}
                    </div>
                  </PrivateWrapper>
                ),
              },
              { text: floor.toString() },
            ]}
          />
        )}
      </div>

      <FloorList
        isAdding={isAdding}
        setIsAdding={setIsAdding}
        setFloor={setFloor}
      />

      <EditBuildingModal
        isAdding={isEditing}
        setIsAdding={setIsEditing}
        building={building}
      />
    </div>
  );
}
