import { RoomTypes } from 'common/types';
import Button from 'generic/components/Form/Button';
import LocationSelect from 'generic/components/LocationSelect';
import Pill from 'generic/components/Pill';
import Tooltip from 'generic/components/Tooltip/Tooltip';
import { getLocation } from 'generic/components/layout/FilterBar/FilterBar';
import { FormattedMessage, useIntl } from 'translations/Intl';
import { uuidv4 } from 'utils/uuid';

import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import { LuChevronDown, LuCirclePlus, LuCircleX } from 'react-icons/lu';
import { useLocation } from 'react-router-dom';

import type { ChartVariables } from '../LineChart/LineChart';

interface FilterProps {
  chartVariables: ChartVariables[];
  setChartVariables: React.Dispatch<React.SetStateAction<ChartVariables[]>>;
  meetingRoomOccupancy?: boolean;
}

export default function Filter({
  chartVariables,
  setChartVariables,
  meetingRoomOccupancy = false,
}: FilterProps) {
  const intl = useIntl();
  const location = useLocation();

  return (
    <div className="absolute top-12 left-2 flex items-center space-x-2">
      {chartVariables.map((c) => (
        <div className="flex flex-col" key={c.Id}>
          <Menu key={c.Id} as="div" className="relative text-left">
            <MenuButton>
              <Pill>
                <div className="flex space-x-2 items-center">
                  <LuCircleX
                    onClick={() =>
                      setChartVariables([
                        ...chartVariables.filter((d) => d.Id !== c.Id),
                      ])
                    }
                    className="size-5"
                  />

                  <div className="whitespace-nowrap">
                    {getLocation(
                      intl,
                      location,
                      c.Building,
                      c.Floor,
                      c.Room,
                      c.Labels,
                    )}
                  </div>
                  <div>
                    <LuChevronDown className="size-5" />
                  </div>
                </div>
              </Pill>
            </MenuButton>
            <MenuItems
              transition
              className="z-40 absolute max-h-fit left-0 w-max min-w-40 mt-2 origin-top-right bg-white dark:bg-neutral-800 dark:text-white divide-y divide-neutral-100 dark:divide-black rounded-md shadow-lg ring-1 ring-black/5 dark:ring-neutral-700  focus:outline-hidden text-sm transition duration-200 ease-out data-closed:scale-95 data-closed:opacity-0"
            >
              <div className="flex flex-col relative overscroll-contain py-2 px-4 space-y-2">
                <LocationSelect
                  roomFilter={(r) =>
                    meetingRoomOccupancy
                      ? r.RoomType.Name === RoomTypes.MEETING
                      : r.RoomType.Name === RoomTypes.DESKS
                  }
                  isDeselectable
                  buildingName={c.Building ?? null}
                  floorNumber={c.Floor ?? null}
                  roomName={c.Room ?? null}
                  labelNames={c.Labels ?? null}
                  hasRoomFilter
                  onBuildingSelect={(selected) => {
                    const previous = chartVariables.find((v) => v.Id === c.Id);

                    if (previous) {
                      setChartVariables((prev) =>
                        prev.map((p) =>
                          p.Id === c.Id
                            ? { Id: p.Id, Building: selected?.Name }
                            : p,
                        ),
                      );
                    } else {
                      setChartVariables((prev) => [
                        ...prev,
                        {
                          Id: c.Id,
                          Building: selected?.Name,
                        },
                      ]);
                    }
                  }}
                  onFloorSelect={(selected) => {
                    const previous = chartVariables.find((v) => v.Id === c.Id);

                    if (previous) {
                      setChartVariables((prev) =>
                        prev.map((p) =>
                          p.Id === c.Id
                            ? {
                                Id: p.Id,
                                Building: previous.Building,
                                Floor: selected?.Number,
                              }
                            : p,
                        ),
                      );
                    } else {
                      setChartVariables((prev) => [
                        ...prev,
                        {
                          Id: c.Id,
                          Building: c.Building,
                          Floor: selected?.Number,
                        },
                      ]);
                    }
                  }}
                  onRoomSelect={(selected) => {
                    const previous = chartVariables.find((v) => v.Id === c.Id);

                    if (previous) {
                      setChartVariables((prev) =>
                        prev.map((p) =>
                          p.Id === c.Id
                            ? {
                                Id: p.Id,
                                Building: previous.Building,
                                Floor: previous.Floor,
                                Room: selected?.Name,
                              }
                            : p,
                        ),
                      );
                    } else {
                      setChartVariables((prev) => [
                        ...prev,
                        {
                          Id: c.Id,
                          Building: c.Building,
                          Floor: c.Floor,
                          Room: selected?.Name,
                        },
                      ]);
                    }
                  }}
                  onLabelSelect={(selected) => {
                    const previous = chartVariables.find((v) => v.Id === c.Id);

                    if (previous) {
                      setChartVariables((prev) =>
                        prev.map((p) =>
                          p.Id === c.Id
                            ? {
                                Id: p.Id,
                                Building: previous.Building,
                                Floor: previous.Floor,
                                Room: previous.Room,
                                Labels: Array.isArray(selected)
                                  ? selected?.map((s) => s.Name)
                                  : [selected?.Name ?? ''],
                              }
                            : p,
                        ),
                      );
                    } else {
                      setChartVariables((prev) => [
                        ...prev,
                        {
                          Id: c.Id,
                          Building: c.Building,
                          Floor: c.Floor,
                          Room: c.Room,
                          Labels: Array.isArray(selected)
                            ? selected?.map((s) => s.Name)
                            : [selected?.Name ?? ''],
                        },
                      ]);
                    }
                  }}
                />
              </div>
            </MenuItems>
          </Menu>
        </div>
      ))}
      <Tooltip
        content={
          <Button
            onClick={() =>
              setChartVariables((prev) => [
                ...prev,
                {
                  Id: uuidv4(),
                  Room: null,
                  Floor: null,
                  Building: null,
                },
              ])
            }
          >
            <LuCirclePlus className="size-5" />
          </Button>
        }
      >
        <FormattedMessage id="Add a location to compare it to the selected one" />
      </Tooltip>
    </div>
  );
}
