import type { Row } from '@tanstack/react-table';
import Button from 'generic/components/Form/Button';
import { LuChevronRight } from 'react-icons/lu';

interface ExpanderCellProps<T> {
  row: Row<T>;
  columnId: string;
}

export default function ExpanderCell<T>({
  row,
  columnId,
}: ExpanderCellProps<T>) {
  // TODO: Remove when updated https://github.com/TanStack/table/issues/5567
  'use no memo';

  return !row.getIsGrouped() && row.getCanExpand() ? (
    <Button
      data-test-id={`row-expander-${row.getValue(columnId)}`}
      onClick={row.getToggleExpandedHandler()}
      className="cursor-pointer"
    >
      <LuChevronRight
        className={`size-5 transition-transform ${
          row.getIsExpanded() ? 'rotate-90' : ''
        }`}
      />
    </Button>
  ) : null;
}
