import PrivateWrapper from '@/generic/components/PrivateWrapper';
import Loader from '@/generic/components/layout/BarLoader';
import { useKeycloakGroupsQuery } from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import type { CellContext, ColumnDef, Row } from '@tanstack/react-table';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import Table, { ACTION_ID } from 'generic/components/Table/Table';
import Tooltip from 'generic/components/Tooltip';
import { useMemo, useState } from 'react';
import { LuCirclePlus, LuPencil, LuTrash } from 'react-icons/lu';
import { FormattedMessage, useIntl } from 'translations/Intl';
import AddGroupModal, {
  ADMIN_GROUP,
} from './components/AddGroupModal/AddGroupModal';
import RemoveGroupModal from './components/RemoveGroupModal';
import type { KeycloakGroup } from './components/RemoveGroupModal/RemoveGroupModal';

function NameCell(
  props: CellContext<KeycloakGroup, unknown>,
): React.JSX.Element | null {
  const { row } = props;

  return (
    <div className="flex items-center">
      <div className="shrink-0 size-10">
        <div className="size-10 mr-4 bg-primary-500 dark:bg-primary-700 focus:outline-hidden rounded-full shadow-md flex items-center justify-center text-white select-none capitalize">
          {row.original.name[0]}
        </div>
      </div>
      <div className="ml-4 text-sm ">{row.original.name}</div>
    </div>
  );
}

function ActionCell({
  row,
  setSelectedGroup,
  setOpenDeleteGroupModal,
  setOpenAddGroupModal,
}: {
  row: Row<KeycloakGroup>;
  setSelectedGroup: (user: KeycloakGroup | undefined) => void;
  setOpenDeleteGroupModal: (open: boolean) => void;
  setOpenAddGroupModal: (open: boolean) => void;
}): React.JSX.Element | null {
  return (
    <div className="flex space-x-2 space-x-reverse flex-row-reverse items-center">
      {row.original.name !== ADMIN_GROUP && (
        <PrivateWrapper roleRequired={HasuraPermissions.VIEW_USERGROUP}>
          <Tooltip
            content={
              <p>
                <LuTrash
                  onClick={() => {
                    setSelectedGroup(row.original);
                    setOpenDeleteGroupModal(true);
                  }}
                  className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
                  data-test-id="delete-group"
                />
              </p>
            }
          >
            <FormattedMessage id="Delete" />
          </Tooltip>
        </PrivateWrapper>
      )}
      <PrivateWrapper roleRequired={HasuraPermissions.VIEW_USERGROUP}>
        <Tooltip
          content={
            <p>
              <LuPencil
                className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
                onClick={() => {
                  setSelectedGroup(row.original as KeycloakGroup);
                  setOpenAddGroupModal(true);
                }}
                data-test-id="edit-group"
              />
            </p>
          }
        >
          <FormattedMessage id="Edit" />
        </Tooltip>
      </PrivateWrapper>
    </div>
  );
}

export default function GroupsTable() {
  const [openAddGroupModal, setOpenAddGroupModal] = useState(false);
  const [openDeleteGroupModal, setOpenDeleteGroupModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<KeycloakGroup | undefined>(
    undefined,
  );
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [{ data: groups, fetching: loadingGroups }] = useKeycloakGroupsQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_USERGROUP),
      [hasuraHeader],
    ),
  });

  const defaultColumns: ColumnDef<KeycloakGroup>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        id: 'name',
        header: intl.formatMessage({ id: 'Name' }),
        cell: NameCell,
      },
      {
        enableGrouping: false,
        id: ACTION_ID,
        cell: ({ row }) => (
          <ActionCell
            row={row}
            setSelectedGroup={setSelectedGroup}
            setOpenAddGroupModal={setOpenAddGroupModal}
            setOpenDeleteGroupModal={setOpenDeleteGroupModal}
          />
        ),
      },
    ],
    [intl.formatMessage],
  );

  const data = useMemo(() => {
    if (groups?.GetSubGroups) {
      return groups.GetSubGroups;
    }
    return [];
  }, [groups]);

  return (
    <>
      <Loader loading={loadingGroups} />
      <Table<KeycloakGroup>
        id="groups"
        columns={defaultColumns}
        data={data}
        getRowId={(row) => row.id}
        loading={loadingGroups}
        initialState={{ sorting: [{ id: 'name', desc: false }] }}
        renderAdditionalAction={
          <PrivateWrapper roleRequired={HasuraPermissions.VIEW_USERGROUP}>
            <Tooltip
              content={
                <StyledButton onClick={() => setOpenAddGroupModal(true)}>
                  <LuCirclePlus data-test-id="add-group" className="size-5" />
                </StyledButton>
              }
            >
              <FormattedMessage id="Add group" />
            </Tooltip>
          </PrivateWrapper>
        }
      />

      <RemoveGroupModal
        groupToRemove={selectedGroup}
        setGroupToRemove={setSelectedGroup}
        open={openDeleteGroupModal}
        setOpen={setOpenDeleteGroupModal}
      />

      <AddGroupModal
        selectedGroup={selectedGroup}
        open={openAddGroupModal}
        setOpen={setOpenAddGroupModal}
        setSelectedGroup={setSelectedGroup}
      />
    </>
  );
}
