import PrivateWrapper from '@/generic/components/PrivateWrapper';
import { useSetDefaultSensorPolicyMutation } from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import type { CellContext, Row } from '@tanstack/react-table';
import Card from 'generic/components/Card/Card';
import Checkbox from 'generic/components/Form/Checkbox/Checkbox';
import Tooltip from 'generic/components/Tooltip';
import useStore from 'model/store';
import { LuBadgeCheck, LuLoaderCircle } from 'react-icons/lu';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';

import Transition from 'generic/components/Transition';
import { LuPencil, LuTrash } from 'react-icons/lu';
import type { SensorPolicy } from '../RemoveSensorPolicyModal/RemoveSensorPolicyModal';

export function NameCell(props: CellContext<SensorPolicy, unknown>) {
  const { row } = props;

  return (
    <div className="flex items-center">
      <div className="shrink-0 size-10">
        <div className="size-10 mr-4 bg-primary-500 dark:bg-primary-700 focus:outline-hidden rounded-full shadow-md flex items-center justify-center text-white select-none capitalize">
          {row.original.Name[0]}
        </div>
      </div>
      <div className="ml-4 text-sm flex space-x-1 items-center">
        <div>{row.original.Name}</div>
      </div>
    </div>
  );
}

export function ConfigurationCell({ row }: CellContext<SensorPolicy, unknown>) {
  const intl = useIntl();

  const sensorTypes = [
    ...new Set(
      Object.keys(row.original.Configuration).map((key) => {
        const splitSensor = key.split('_');

        if (
          (key.startsWith('linecount_in') || key.startsWith('linecount_out')) &&
          key !== 'linecount_interval_s'
        ) {
          return `${splitSensor[0]}_${splitSensor[1]}`;
        }
        return splitSensor[0] ?? '';
      }),
    ),
  ];

  return (
    <div className="whitespace-pre grid grid-cols-2 gap-1">
      {sensorTypes
        .sort((a, b) =>
          intl
            .formatMessage({ id: `clc_${a}` as IntlMessageKeys })
            .localeCompare(
              intl.formatMessage({ id: `clc_${b}` as IntlMessageKeys }),
            ),
        )
        .map((sensorType) => {
          const [interval, onChange] = Object.entries<number>(
            row.original.Configuration,
          ).filter(
            ([key]) =>
              key === `${sensorType}_send_on_change` ||
              key === `${sensorType}_interval_s`,
          );

          const intervalValue = interval ? interval[1] : 0;
          const onChangeValue = onChange ? onChange[1] : 0;

          const deactivated = onChangeValue === 0 && intervalValue === 0;

          return (
            <Card
              data-test-id={`sensor-${sensorType}-card`}
              key={sensorType}
              noPadding
              className={`!p-1 rounded-md col-span-1 w-full ${
                deactivated
                  ? 'bg-neutral-100! dark:bg-neutral-700!'
                  : 'bg-primary-200! dark:bg-primary-700!'
              }`}
            >
              <div className="flex flex-col space-y-0 text-xs ">
                <div className="font-bold overflow-hidden text-ellipsis hover:overflow-visible">
                  <FormattedMessage
                    id={`clc_${sensorType}` as IntlMessageKeys}
                  />
                </div>
                {intervalValue > 0 && (
                  <div className="break-words whitespace-normal">
                    <FormattedMessage id="Cyclical" />: {intervalValue}s
                  </div>
                )}
                {onChangeValue > 0 && (
                  <div className="break-words whitespace-normal">
                    <FormattedMessage id="Threshold" />: {onChangeValue}
                  </div>
                )}
                <Transition show={sensorType === 'demo'}>
                  <div className="break-words whitespace-normal">
                    <FormattedMessage id="Follow up time" />: {onChangeValue}s
                  </div>
                </Transition>
                <Transition show={sensorType === 'firmware'}>
                  <div className="break-words whitespace-normal">
                    {intervalValue}
                  </div>
                </Transition>
                {deactivated && <FormattedMessage id="Deactivated" />}
              </div>
            </Card>
          );
        })}
    </div>
  );
}

export function DefaultCell({ row }: CellContext<SensorPolicy, unknown>) {
  const hasuraHeader = useHasuraHeader();
  const userRoles = useStore((state) => state.user)?.roles;
  const [{ fetching }, setDefaultPolicy] = useSetDefaultSensorPolicyMutation();

  return userRoles?.includes(HasuraPermissions.WRITE_ORGANIZATION) &&
    !row.original.Default ? (
    <Tooltip
      content={
        fetching ? (
          <p>
            <LuLoaderCircle className="size-5 animate-spin text-primary-500" />
          </p>
        ) : (
          <Checkbox
            setChecked={() =>
              setDefaultPolicy(
                {
                  Id: row.original.Id,
                },
                hasuraHeader(HasuraPermissions.WRITE_ORGANIZATION),
              )
            }
          />
        )
      }
    >
      <FormattedMessage id="Set as default" />
    </Tooltip>
  ) : (
    <Tooltip
      content={
        <p>
          <LuBadgeCheck className="size-5 text-green-400" />
        </p>
      }
    >
      <FormattedMessage id="Default policy" />
    </Tooltip>
  );
}

export function ActionCell({
  row,
  setSelectedPolicy,
  setOpenDeleteSensorPolicyModal,
  setOpenAddSensorPolicyModal,
}: {
  row: Row<SensorPolicy>;
  setSelectedPolicy: (policy: SensorPolicy | undefined) => void;
  setOpenDeleteSensorPolicyModal: (open: boolean) => void;
  setOpenAddSensorPolicyModal: (open: boolean) => void;
}): React.JSX.Element | null {
  return (
    <div className="flex space-x-2 space-x-reverse flex-row-reverse items-center">
      {!row.original.Default && (
        <PrivateWrapper roleRequired={HasuraPermissions.WRITE_ORGANIZATION}>
          <Tooltip
            content={
              <p>
                <LuTrash
                  onClick={() => {
                    setSelectedPolicy(row.original);
                    setOpenDeleteSensorPolicyModal(true);
                  }}
                  className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
                  data-test-id="delete-policy-button"
                />
              </p>
            }
          >
            <FormattedMessage id="Delete" />
          </Tooltip>
        </PrivateWrapper>
      )}
      <PrivateWrapper roleRequired={HasuraPermissions.WRITE_ORGANIZATION}>
        <Tooltip
          content={
            <p>
              <LuPencil
                className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
                onClick={() => {
                  setSelectedPolicy(row.original);
                  setOpenAddSensorPolicyModal(true);
                }}
                data-test-id="edit-policy-button"
              />
            </p>
          }
        >
          <FormattedMessage id="Edit" />
        </Tooltip>
      </PrivateWrapper>
    </div>
  );
}
