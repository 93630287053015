import type { AccuracyFeatureType } from 'generic/layers/AccuracyLayer';
import type { AssetFeatureType } from 'generic/layers/AssetLayer';
import { FormattedMessage } from 'translations/Intl';

import PopupBody from '@/generic/components/layout/PopupBody';
import { formattedDistance } from '@/utils/date';
import { LuCircleAlert, LuCpu } from 'react-icons/lu';

interface AssetPopupProps {
  hoveredFeature: AssetFeatureType | AccuracyFeatureType;
}

const chipIcon = (
  <div className="bg-primary-300 shrink-0 flex items-center justify-center size-8 rounded-full mr-2">
    <LuCpu className="mx-auto size-6 text-primary-700" />
  </div>
);

const offlineIcon = (
  <div className="bg-neutral-300 shrink-0 flex items-center justify-center size-8 rounded-full mr-2">
    <LuCircleAlert className="mx-auto size-6 text-neutral-700" />
  </div>
);

export default function AssetPopup({
  hoveredFeature,
}: AssetPopupProps): React.JSX.Element {
  const { Name, DisplayName, IsOffline, UpdatedAt } =
    hoveredFeature.getProperties();
  const lastActivity = formattedDistance(new Date(UpdatedAt), {
    includeSeconds: true,
  });
  const offline = !!IsOffline;
  return (
    <PopupBody
      title={DisplayName ?? Name}
      renderIcon={offline ? () => offlineIcon : () => chipIcon}
    >
      <div className="flex flex-col">
        {offline ? (
          <div className="flex flex-col text-sm">
            <FormattedMessage id="The device may be offline" />
            <br />
            <div className="flex items-center">
              <FormattedMessage id="Last Activity" />: {lastActivity}
            </div>
          </div>
        ) : (
          UpdatedAt && (
            <div className="flex items-center">
              <FormattedMessage id="Last Activity" />: {lastActivity}
            </div>
          )
        )}
      </div>
    </PopupBody>
  );
}
