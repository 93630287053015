import Tab from 'generic/components/Tab';
import Title from 'generic/components/Title/Title';
import { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';

import {
  LuBuilding2,
  LuCpu,
  LuRocket,
  LuServer,
  LuSettings,
  LuUser,
  LuUserRoundCog,
  LuUsers,
} from 'react-icons/lu';

export default function SettingsView() {
  return (
    <div>
      <Title value="Settings" />
      <Tab
        tabs={[
          {
            end: true, // avoid to be always active for all routes
            path: '',
            userRole: HasuraPermissions.READ,
            name: 'Personal',
            renderIcon: ({ className }) => (
              <LuUserRoundCog className={className} />
            ),
          },
          {
            path: 'organization',
            userRole: HasuraPermissions.WRITE_ORGANIZATION,
            name: 'Organization',
            renderIcon: ({ className }) => <LuSettings className={className} />,
          },
          {
            path: 'mapping',
            userRole: HasuraPermissions.WRITE_MQTTMAPPING,
            name: 'External devices',
            renderIcon: ({ className }) => <LuServer className={className} />,
          },
          {
            path: 'sensors',
            userRole: [
              HasuraPermissions.WRITE_ORGANIZATION,
              HasuraPermissions.MDA2,
            ],
            name: 'Sensor policies',
            renderIcon: ({ className }) => <LuCpu className={className} />,
          },
          {
            path: 'firmwares',
            userRole: HasuraPermissions.READ_ALL,
            name: 'Firmwares',
            renderIcon: ({ className }) => <LuRocket className={className} />,
          },
          {
            path: 'user',
            userRole: HasuraPermissions.VIEW_USERGROUP,
            name: 'Users',
            renderIcon: ({ className }) => <LuUser className={className} />,
          },
          {
            path: 'group',
            userRole: HasuraPermissions.VIEW_USERGROUP,
            name: 'Groups',
            renderIcon: ({ className }) => <LuUsers className={className} />,
          },
          {
            path: 'organizations',
            userRole: HasuraPermissions.VIEW_ORGANIZATIONS,
            name: 'Organizations',
            renderIcon: ({ className }) => (
              <LuBuilding2 className={className} />
            ),
          },
        ]}
      />
    </div>
  );
}
