import format from '@/utils/format';
import type { JsonGeometry } from 'common/types';
import PopupBody from 'generic/components/layout/PopupBody';
import type TypedFeature from 'generic/layers/TypedFeature';
import type { Polygon } from 'ol/geom';
import { LuCpu } from 'react-icons/lu';
import { FormattedMessage } from 'translations/Intl';

export type AssetHistoryFeatureType = TypedFeature<Asset, Polygon>;
export type Asset = {
  id: number;
  floorId: number;
  now: boolean;
  name: string;
  date: Date;
  Geometry: JsonGeometry;
};

interface AssetHistoryPopupProps {
  hoveredFeature: AssetHistoryFeatureType;
}

export default function AssetHistoryPopup({
  hoveredFeature,
}: AssetHistoryPopupProps): React.JSX.Element {
  const { date, name } = hoveredFeature.getProperties();
  const timestamp = format(new Date(date), 'PPp');

  return (
    <PopupBody
      title={name}
      renderIcon={() => (
        <div className="bg-primary-300 shrink-0 flex items-center justify-center size-8 rounded-full mr-2">
          <LuCpu className="mx-auto size-6 text-primary-700" />
        </div>
      )}
    >
      <div className="flex flex-col">
        <div className="flex items-center">
          <FormattedMessage id="Timestamp" />: {timestamp}
        </div>
      </div>
    </PopupBody>
  );
}
