import { ModuleType } from '@/common/types';
import Title from '@/generic/components/Title';
import Transition from '@/generic/components/Transition';
import Loader from '@/generic/components/layout/BarLoader';
import { useLiveFloorRoomOccupancyQuery } from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import usePolling from '@/utils/graphql/usePolling';
import Tooltip from 'generic/components/Tooltip';
import { useMemo } from 'react';
import { LuUsers } from 'react-icons/lu';
import { FormattedMessage } from 'translations/Intl';

interface TotalVisitorsProps {
  buildingName: string;
}

export default function TotalVisitors({
  buildingName,
}: TotalVisitorsProps): React.JSX.Element {
  const hasuraHeader = useHasuraHeader();
  const [{ data: liveData, fetching: loading }, reexecuteQuery] =
    useLiveFloorRoomOccupancyQuery({
      variables: {
        Building: buildingName,
      },
      context: useMemo(
        () =>
          hasuraHeader(HasuraPermissions.VIEW_DASHBOARD, [
            'LiveFloorRoomOccupancy',
            'FloorSensor',
          ]),
        [hasuraHeader],
      ),
    });
  usePolling(loading, reexecuteQuery, 1000 * 30);

  const totalVisitors = useMemo(() => {
    if (
      liveData?.f_live_floors_occupancy &&
      liveData.f_live_floors_occupancy.length > 0
    ) {
      return liveData.f_live_floors_occupancy
        .map(
          (r) =>
            r.FloorOccupancy *
            (r.SensorType === ModuleType.LINECOUNT_OUT ? -1 : 1),
        )
        .reduce((acc, curr) => acc + curr, 0);
    }
    return null;
  }, [liveData]);

  return (
    <>
      <div className="flex items-center flex-col">
        <LuUsers className="size-5 mr-1" />
        <Title value="Total Visitors" />
      </div>
      {buildingName}
      <Loader loading={loading} />
      {liveData && (
        <span className="text-bold w-min rounded-full p-4 flex items-center justify-center">
          {!totalVisitors && liveData.f_live_floors_occupancy.length === 0 && (
            <Tooltip content={<p>-</p>}>
              <FormattedMessage id="There are no sensor selected to count the floors's number of visitors" />
            </Tooltip>
          )}
          <Transition show={totalVisitors !== null}>
            <span
              data-test-id="visitor-count"
              className="text-primary-500 text-5xl"
            >
              {totalVisitors}
            </span>
          </Transition>
        </span>
      )}
    </>
  );
}
