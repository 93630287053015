export enum ClimateStatus {
  GOOD = 'good',
  ACCEPTABLE = 'acceptable',
  POOR = 'poor',
}

const getClimateStatus = (
  value: number,
  limits: {
    good: {
      start?: number;
      end?: number;
    };
    acceptable: {
      start?: number;
      end?: number;
    };
    poor: {
      start?: number;
      end?: number;
    };
  },
): ClimateStatus => {
  if (limits.good.start && limits.good.end) {
    if (value >= limits.good.start && value < limits.good.end) {
      return ClimateStatus.GOOD;
    }
  } else if (limits.good.end && value < limits.good.end) {
    return ClimateStatus.GOOD;
  }

  if (
    limits.acceptable.start &&
    limits.acceptable.end &&
    value >= limits.acceptable.start &&
    value < limits.acceptable.end
  ) {
    return ClimateStatus.ACCEPTABLE;
  }

  return ClimateStatus.POOR;
};

export default getClimateStatus;
