import StyledButton from 'generic/components/Form/Button/StyledButton';
import { FormattedMessage } from 'translations/Intl';

import Logo from '@/generic/components/layout/Logo';
import { LuHouse } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

export default function Error() {
  const navigate = useNavigate();

  return (
    <div className="bg-white dark:bg-neutral-800 flex h-screen">
      <div className="max-w-[50rem] mx-auto w-full">
        <main id="content" className="h-full">
          <div className="flex flex-col text-center py-10 px-4 sm:px-6 lg:px-8 h-full justify-center">
            <div className="flex w-full text-2xl font-bold text-white sm:text-4xl justify-center">
              <Logo />
            </div>
            <p className="mt-3 text-lg text-neutral-700 dark:text-white">
              <FormattedMessage id="Something went wrong" />
            </p>
            <div className="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
              <StyledButton onClick={() => navigate('/', { replace: true })}>
                <LuHouse className="size-5" />
                <FormattedMessage id="Go home" />
              </StyledButton>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
