import {
  LS_SHOW_ASSET_POSITION,
  LS_SHOW_PEOPLE_FLOW,
  LS_SHOW_ROOM_BEACONS,
  LS_SHOW_ROOM_OCCUPANCY,
  LS_SHOW_ROOM_SENSOR,
} from '@/constants';
import { ExtendedMap } from '@/generic/components/BasicMap';
import LastPolled from '@/generic/components/LastPolled';
import { getBeaconColor } from '@/pages/AdminView/BuildingView/components/FloorList/components/FloorRoomView/components/FloorRoomMap/utils/helpers';
import { TooltipWithBounds } from '@visx/tooltip';
import {
  ModuleType,
  RoomTypes,
  isAssetAccuracyFeature,
  isAssetFeature,
  isDeskInUseFeature,
  isRoomBeaconFeature,
  isRoomFeature,
} from 'common/types';
import Card from 'generic/components/Card';
import EmbeddedButton from 'generic/components/EmbeddedButton';
import EmbeddedWrapper from 'generic/components/EmbeddedWrapper';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import LayerInfoTogglers, {
  type SwitchInterface,
} from 'generic/components/LayerInfoTogglers/LayerInfoTogglers';
import LayerToggler from 'generic/components/LayerToggler';
import type { Layers } from 'generic/components/LayerToggler/LayerToggler';
import LivePing from 'generic/components/LivePing';
import Map from 'generic/components/Map';
import Panel from 'generic/components/Panel';
import PrivateWrapper from 'generic/components/PrivateWrapper';
import Subtitle from 'generic/components/Subtitle';
import Transition from 'generic/components/Transition';
import Loader from 'generic/components/layout/BarLoader';
import AccuracyLayer from 'generic/layers/AccuracyLayer';
import AssetLayer from 'generic/layers/AssetLayer';
import BaseLayer from 'generic/layers/BaseLayer';
import DeskInUseLayer, {
  type DeskInUseFeatureType,
} from 'generic/layers/DeskInUseLayer';
import type Layer from 'generic/layers/Layer';
import RoomBeaconLayer, {
  type RoomBeaconFeatureType,
} from 'generic/layers/RoomBeaconLayer';
import RoomInUseLayer, {
  type RoomInUseFeature,
  type RoomInUseFeatureType,
} from 'generic/layers/RoomInUseLayer';
import {
  type OccupancyMapDesksSubscription,
  type OccupancyMapQuery,
  useFloorImageQuery,
  useOccupancyMapAssetsQuery,
  useOccupancyMapDesksSubscription,
  useOccupancyMapQuery,
} from 'graphql/types';
import defaultFloorplan from 'img/default_floorplan.jpg';
import useStore from 'model/store';
import type MapBrowserEvent from 'ol/MapBrowserEvent';
import { unByKey } from 'ol/Observable';
import type { EventsKey } from 'ol/events';
import { touchOnly } from 'ol/events/condition';
import type Point from 'ol/geom/Point';
import type VectorSource from 'ol/source/Vector';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LuArrowUpDown,
  LuCodepen,
  LuCpu,
  LuSettings2,
  LuSignal,
  LuUsers,
} from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'translations/Intl';
import { lower, upper } from 'utils/date';
import deskIsUsed from 'utils/deskIsUsed';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import usePolling from 'utils/graphql/usePolling';
import useRoomDeskFilter from 'utils/graphql/useRoomDeskFilter';
import useDecodedLocation from 'utils/useDecodedLocation';
import useDeviceDetect from 'utils/useDeviceDetect';
import ReservationModal from '../ReservationModal';
import OccupancyMapPopupBody, {
  type OccupancyMapFeature,
} from './components/OccupancyMapPopupBody/OccupancyMapPopupBody';
import ReservationBanner from './components/ReservationBanner/ReservationBanner';

const olListenersKeys: EventsKey[] = [];

interface OccupancyMapProps {
  className?: string;
  hideButtons?: boolean;
  selected?: { floor?: number; building?: string };
}

const getDeskFeatures = (
  desks?: OccupancyMapQuery['Desks'],
  occupancySubscription?: OccupancyMapDesksSubscription['Sensors_stream'],
) => [
  ...(desks ?? []).map((d) => {
    const subscriptionSensor = !d.Sensor
      ? null
      : occupancySubscription?.find((sensor) => sensor.Id === d.Sensor?.Id);
    return {
      ...d,
      Sensor:
        d.Sensor && subscriptionSensor
          ? {
              ...d.Sensor,
              Value: subscriptionSensor.Value,
              UpdatedAt: subscriptionSensor.UpdatedAt,
            }
          : d.Sensor,
    };
  }),
];

const getRoomFeatures = (
  roomSensors: OccupancyMapQuery['RoomSensor'],
  rooms?: OccupancyMapQuery['f_live_rooms_occupancy'],
  occupancySubscription?: OccupancyMapDesksSubscription['Sensors_stream'],
) => [
  ...(rooms ?? []).map((r) => {
    const subscriptionSensor = !r.Id
      ? undefined
      : occupancySubscription?.find((sensor) =>
          sensor.RoomSensors.find((rs) => rs.Room.Id === r.Id),
        );

    const { offline, lineCountIn, lineCountOut } = calculateLinecount(
      roomSensors?.filter((rS) => rS.RoomsId === r.Id),
      subscriptionSensor ? [subscriptionSensor] : undefined,
      r.Id,
    );

    return {
      ...r,
      IsOffline: offline ?? r.IsOffline,
      RoomOccupancy:
        subscriptionSensor?.SensorType.Name === ModuleType.AREACOUNT
          ? (subscriptionSensor?.Value ?? r.RoomOccupancy)
          : r.RoomOccupancy,
      RoomOccupancyPercentage:
        subscriptionSensor?.SensorType.Name === ModuleType.AREACOUNT
          ? subscriptionSensor?.Value
            ? Math.round(
                ((100 * subscriptionSensor.Value) / r.Capacity) * 100,
              ) / 100
            : r.RoomOccupancyPercentage
          : r.RoomOccupancyPercentage,
      LineInValue: lineCountIn ?? undefined,
      LineOutValue: lineCountOut ?? undefined,
    };
  }),
];

const calculateLinecount = (
  lineCounters: OccupancyMapQuery['RoomSensor'],
  occupancySubscription?: OccupancyMapDesksSubscription['Sensors_stream'],
  roomId?: number,
) => {
  const liveData = occupancySubscription?.map((s) => ({
    Sensor: {
      SensorType: { Name: s.SensorType.Name },
      Value: s.Value,
      UpdatedAt: s.UpdatedAt,
    },
    Sign: s.RoomSensors.find((rs) => rs.Room.Id === roomId)?.Sign ?? 1,
  }));

  const lineCountInSensors = [
    ...(lineCounters ?? []),
    ...(liveData ?? []),
  ]?.filter(
    (lineCounter) =>
      (lineCounter.Sensor.SensorType.Name === ModuleType.LINECOUNT_IN &&
        lineCounter.Sign === 1) ||
      (lineCounter.Sensor.SensorType.Name === ModuleType.LINECOUNT_OUT &&
        lineCounter.Sign === -1),
  );
  const lineCountOutSensors = [
    ...(lineCounters ?? []),
    ...(liveData ?? []),
  ]?.filter(
    (lineCounter) =>
      (lineCounter.Sensor.SensorType.Name === ModuleType.LINECOUNT_OUT &&
        lineCounter.Sign === 1) ||
      (lineCounter.Sensor.SensorType.Name === ModuleType.LINECOUNT_IN &&
        lineCounter.Sign === -1),
  );

  return {
    offline: lineCounters?.length
      ? lineCounters.every((lc) => lc.Sensor.MqttBeacon.IsOffline)
      : undefined,
    lineCountIn: lineCountInSensors?.length
      ? lineCountInSensors
          .map((lineCounter) => lineCounter.Sensor.Value ?? 0)
          .reduce((acc, curr) => acc + curr, 0)
      : undefined,
    lineCountOut: lineCountOutSensors?.length
      ? lineCountOutSensors
          .map((lineCounter) => lineCounter.Sensor.Value ?? 0)
          .reduce((acc, curr) => acc + curr, 0)
      : undefined,
  };
};

export default function OccupancyMap({
  className,
  hideButtons,
  selected,
}: OccupancyMapProps) {
  const intl = useIntl();
  const { isMobile } = useDeviceDetect();
  const [baseLayer] = useState(new BaseLayer());
  const [deskInUseLayer] = useState(new DeskInUseLayer());
  const [roomInUseLayer] = useState(new RoomInUseLayer());
  const [assetLayer] = useState(new AssetLayer());
  const [accuracyLayer] = useState(new AccuracyLayer());
  const [roomBeaconLayer] = useState(new RoomBeaconLayer());
  const [panelOpen, setPanelOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [reservationBannerOpen, setReservationBannerOpen] = useState(false);
  const warmMinutesPolicy = useStore(
    (state) => state.organizationSettings.warmMinutesPolicy,
  );
  const userRoles = useStore((state) => state.user)?.roles;
  const buildingName = useStore((state) => state.userSettings.building)?.Name;
  const selectedFloor = useStore((state) => state.userSettings.floor);
  const roomTypes = useStore((state) => state.userSettings.roomTypes);
  const params = useMemo(() => new URLSearchParams(), []);
  const navigate = useNavigate();
  const room = useDecodedLocation('room');
  const desk = useDecodedLocation('desk');
  const building = useDecodedLocation('building');
  const decodedFloor = useDecodedLocation('floor');
  const floor = decodedFloor ? Number.parseInt(decodedFloor, 10) : null;
  const hasuraHeader = useHasuraHeader();
  const showAll = useMemo(() => room === null && desk === null, [room, desk]);
  const [now] = useState(new Date());
  const [showRoomBeacons, setShowRoomBeacons] = useState(
    localStorage.getItem(LS_SHOW_ROOM_BEACONS) === 'true',
  );
  const hasAssetPermission = userRoles?.includes(
    HasuraPermissions.VIEW_FINDMYASSET,
  );
  const [showAssets, setShowAssets] = useState(
    localStorage.getItem(LS_SHOW_ASSET_POSITION) === 'true' &&
      hasAssetPermission,
  );
  const [deskInUseFeatures, setDeskInUseFeatures] = useState<
    DeskInUseFeatureType[] | undefined
  >();
  const [lastUpdate, setLastUpdate] = useState(new Date());
  const [map] = useState(new ExtendedMap());
  const [hoveredFeature, setHoveredFeature] =
    useState<OccupancyMapFeature | null>(null);
  const [clickedFeature, setClickedFeature] = useState<
    RoomInUseFeatureType | DeskInUseFeatureType | null
  >(null);
  const [isTouchedEvt, setIsTouchedEvt] = useState(false);
  const [openReservationModal, setOpenReservationModal] = useState(false);
  const layers = useMemo<Layer[]>(
    () =>
      hasAssetPermission
        ? [
            baseLayer,
            accuracyLayer,
            roomInUseLayer,
            deskInUseLayer,
            assetLayer,
            roomBeaconLayer,
          ]
        : [baseLayer, roomInUseLayer, deskInUseLayer, roomBeaconLayer],
    [
      accuracyLayer,
      assetLayer,
      baseLayer,
      deskInUseLayer,
      hasAssetPermission,
      roomBeaconLayer,
      roomInUseLayer,
    ],
  );
  const [togglerLayers] = useState<Layers[]>([
    {
      name: 'assetLayer',
      layers: [assetLayer, accuracyLayer],
      permissions: HasuraPermissions.VIEW_FINDMYASSET,
      onSetEnable: (enabled: boolean) => {
        setShowAssets(enabled);
        localStorage.setItem(LS_SHOW_ASSET_POSITION, JSON.stringify(enabled));
      },
      image: (
        <div className="bg-primary-300 shrink-0 flex items-center justify-center size-8 rounded-full">
          <LuCpu className="mx-auto size-6 text-primary-700" />
        </div>
      ),
    },
    {
      name: 'roomBeaconLayer',
      layers: [roomBeaconLayer],
      permissions: HasuraPermissions.READ,
      onSetEnable: (enabled: boolean) => {
        setShowRoomBeacons(enabled);
        localStorage.setItem(LS_SHOW_ROOM_BEACONS, JSON.stringify(enabled));
      },
      image: (
        <div className="bg-primary-300 shrink-0 flex items-center justify-center size-8 rounded-full">
          <LuSignal className="mx-auto size-6 text-primary-700" />
        </div>
      ),
    },
  ]);
  const [showRoomOccupancy, setShowRoomOccupancy] = useState(
    !![null, 'true'].includes(localStorage.getItem(LS_SHOW_ROOM_OCCUPANCY)),
  );
  const [showPeopleFlow, setShowPeopleFlow] = useState(
    !![null, 'true'].includes(localStorage.getItem(LS_SHOW_PEOPLE_FLOW)),
  );
  const [showRoomSensor, setShowRoomSensor] = useState(
    localStorage.getItem(LS_SHOW_ROOM_SENSOR) === 'true',
  );
  const [occupancySubscriptionStreams, setOccupancySubscriptionStreams] =
    useState([] as OccupancyMapDesksSubscription['Sensors_stream']);

  const switches: SwitchInterface[] = useMemo(
    () => [
      {
        name: 'Room Occupancy',
        isEnabled: showRoomOccupancy,
        onSetEnable: (enabled: boolean) => {
          setShowRoomOccupancy(enabled);
          localStorage.setItem(LS_SHOW_ROOM_OCCUPANCY, JSON.stringify(enabled));
        },
        renderIcon: ({ className }) => <LuUsers className={className} />,
        info: 'Display the current room occupancy. Measuring devices need to be installed and configured accordingly.',
      },
      {
        name: 'People Flow',
        isEnabled: showPeopleFlow,
        onSetEnable: (enabled: boolean) => {
          setShowPeopleFlow(enabled);
          localStorage.setItem(LS_SHOW_PEOPLE_FLOW, JSON.stringify(enabled));
        },
        renderIcon: ({ className }) => <LuArrowUpDown className={className} />,
        info: 'Display the total number of people entering and leaving the room. Counting devices need to be installed and configured accordingly.',
      },
      {
        name: 'Room Sensor Information',
        isEnabled: showRoomSensor,
        onSetEnable: (enabled: boolean) => {
          setShowRoomSensor(enabled);
          localStorage.setItem(LS_SHOW_ROOM_SENSOR, JSON.stringify(enabled));
        },
        renderIcon: ({ className }) => <LuCodepen className={className} />,
        info: 'Display all the individual room sensors which are used to determine the occupancy.',
      },
    ],
    [showRoomOccupancy, showPeopleFlow, showRoomSensor],
  );

  const getIsPrivate = (
    feat: DeskInUseFeatureType | RoomInUseFeatureType,
  ): boolean =>
    isDeskInUseFeature(feat)
      ? !!feat.getProperties().Sensor?.IsPrivate
      : feat.getProperties().IsPrivate;

  useEffect(() => {
    unByKey(olListenersKeys);
    return () => {
      unByKey(olListenersKeys);
    };
  }, []);

  const usedBuilding = selected?.building ?? building ?? buildingName;
  const usedFloor = selected?.floor ?? floor ?? selectedFloor?.Number;

  const [{ data: mapData, fetching: loading }, reexecuteQuery] =
    useOccupancyMapQuery({
      variables: {
        Building: usedBuilding ?? '',
        Floor: usedFloor ?? 0,
        ShowBeacons: showRoomBeacons,
        Date: now,
        ...useRoomDeskFilter(),
      },
      context: useMemo(
        () =>
          hasuraHeader(
            userRoles?.includes(HasuraPermissions.VIEW_DASHBOARD)
              ? HasuraPermissions.VIEW_DASHBOARD
              : HasuraPermissions.VIEW_FINDMYPLACE,
            ['RoomReservations', 'MqttBeacons'],
          ),
        [hasuraHeader, userRoles],
      ),
      pause: !userRoles || !usedBuilding || typeof usedFloor !== 'number',
    });

  const [{ data: assetData }, reexecuteAssetQuery] = useOccupancyMapAssetsQuery(
    {
      variables: {
        Building: usedBuilding ?? '',
        Floor: usedFloor ?? 0,
      },
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_FINDMYASSET),
        [hasuraHeader],
      ),
      pause: !showAssets || !usedBuilding || typeof usedFloor !== 'number',
    },
  );

  const [{ data: floorImageData, fetching: imageLoading }] = useFloorImageQuery(
    {
      variables: {
        BuildingName: usedBuilding,
        FloorNumber: usedFloor,
      },
      pause: !usedBuilding || typeof usedFloor !== 'number',
    },
  );

  const [{ data: desks }] = useOccupancyMapDesksSubscription({
    variables: {
      Building: usedBuilding,
      Floor: usedFloor,
      Now: useMemo(() => new Date(), []),
    },
    pause:
      !useRoomDeskFilter().Desks ||
      !usedBuilding ||
      typeof usedFloor !== 'number',
  });

  usePolling(loading, reexecuteQuery, 1000 * 15, setLastUpdate);
  usePolling(loading, reexecuteAssetQuery, 1000 * 15);

  useEffect(() => {
    deskInUseLayer.warmTimeMinutes = warmMinutesPolicy;

    deskInUseLayer.olLayer.changed();
  }, [deskInUseLayer, warmMinutesPolicy]);

  useEffect(() => {
    if (floorImageData) {
      baseLayer.setImage(floorImageData.Floors[0]?.Image ?? '');
    }
    // Set a dummy room if there are no floors added yet
    if (floorImageData && floorImageData.Floors.length === 0) {
      baseLayer.setDefault(defaultFloorplan);
    }
  }, [
    baseLayer,
    floorImageData?.Floors[0]?.Image,
    floorImageData?.Floors.length,
    floorImageData,
  ]);

  // Use subscription stream only for sensor data in order to live update the desk/room occupancy
  // biome-ignore lint/correctness/useExhaustiveDependencies: Only reacts on "Sensors_stream" new data
  useEffect(() => {
    const newStreamedSensor = desks?.Sensors_stream[0];
    if (newStreamedSensor) {
      const newOccupancykSubscriptionStreams = [
        ...occupancySubscriptionStreams,
      ];
      const existingSensorStream = newOccupancykSubscriptionStreams.find(
        (dSS) => dSS.Id === newStreamedSensor.Id,
      );
      if (existingSensorStream) {
        Object.assign(existingSensorStream, newStreamedSensor);
      } else {
        newOccupancykSubscriptionStreams.push(newStreamedSensor);
      }
      setOccupancySubscriptionStreams(newOccupancykSubscriptionStreams);
    }
  }, [desks?.Sensors_stream]);

  useEffect(() => {
    if (mapData?.Desks && floorImageData?.Floors[0]?.Image) {
      if (showAll && roomTypes.includes(RoomTypes.DESKS)) {
        const deskFeatures = getDeskFeatures(
          mapData.Desks,
          occupancySubscriptionStreams,
        );
        deskInUseLayer.setFeatures(deskFeatures);
        return;
      }

      if (desk) {
        const deskFeatures = getDeskFeatures(
          mapData.Desks.filter((ds) => ds.Name === desk),
          occupancySubscriptionStreams,
        );
        deskInUseLayer.setFeatures(deskFeatures);
      } else {
        deskInUseLayer.setFeatures([]);
      }
    } else {
      deskInUseLayer.setFeatures([]);
    }
  }, [
    desk,
    deskInUseLayer,
    occupancySubscriptionStreams,
    floorImageData?.Floors[0]?.Image,
    mapData,
    mapData?.Desks,
    roomTypes,
    showAll,
  ]);

  useEffect(() => {
    if (assetData?.MqttAssetTrackers) {
      assetLayer.setFeatures(assetData.MqttAssetTrackers);
      accuracyLayer.setFeatures(assetData.MqttAssetTrackers);
    } else {
      assetLayer.setFeatures([]);
      accuracyLayer.setFeatures([]);
    }
  }, [accuracyLayer, assetLayer, assetData?.MqttAssetTrackers]);

  useEffect(() => {
    if (mapData?.MqttBeacons) {
      roomBeaconLayer.setFeatures(mapData.MqttBeacons);
    } else {
      roomBeaconLayer.setFeatures([]);
    }
  }, [mapData?.MqttBeacons, roomBeaconLayer]);

  useEffect(() => {
    if (mapData?.f_live_rooms_occupancy) {
      if (showAll && roomTypes.includes(RoomTypes.MEETING)) {
        const newFeats: RoomInUseFeature[] = mapData.f_live_rooms_occupancy.map(
          (roomFeature) => {
            // Used for the different reserved icon if it is a personal reservation
            const nextReserved = mapData.RoomReservations.find(
              (dR) => dR.RoomId === roomFeature.Id,
            );

            return {
              ...roomFeature,
              IsPersonal: nextReserved?.IsPersonal || undefined,
            };
          },
        );
        const roomFeatures = getRoomFeatures(
          mapData.RoomSensor,
          newFeats,
          occupancySubscriptionStreams,
        );
        roomInUseLayer.setFeatures(roomFeatures);
        return;
      }

      if (room) {
        const roomFeatures = getRoomFeatures(
          mapData.RoomSensor,
          mapData.f_live_rooms_occupancy.filter((r) => r.Name === room),
          occupancySubscriptionStreams,
        );
        roomInUseLayer.setFeatures(roomFeatures);
      } else {
        roomInUseLayer.setFeatures([]);
      }
    } else {
      roomInUseLayer.setFeatures([]);
    }
  }, [
    mapData,
    mapData?.f_live_rooms_occupancy,
    room,
    roomInUseLayer,
    roomTypes,
    showAll,
    occupancySubscriptionStreams,
  ]);

  useEffect(() => {
    roomInUseLayer.set('showRoomOccupancy', showRoomOccupancy);
    roomInUseLayer.olLayer.changed();
  }, [roomInUseLayer, showRoomOccupancy]);

  useEffect(() => {
    roomInUseLayer.set('showPeopleFlow', showPeopleFlow);
    roomInUseLayer.olLayer.changed();
  }, [roomInUseLayer, showPeopleFlow]);

  useEffect(() => {
    // Add a small delay as it won't be immediately fully visible after all data is available
    const timeout = setTimeout(() => {
      if (!loading && !imageLoading) {
        setDataLoaded(true);
      }
    }, 300);

    return () => clearTimeout(timeout);
  }, [imageLoading, loading]);

  const availableDesks = useMemo(
    () =>
      mapData?.Desks?.map((ds) => ({
        deskId: ds.Id,
        deskName: ds.Name,
        // Desks that are unavailable:
        // 1. Desk is being used
        // 2. Desk is within warm minute policy
        // 3. Desk that is private
        deskInUse: deskIsUsed(
          ds.Sensor?.UpdatedAt,
          ds.Sensor?.Value ?? 0,
          ds.Sensor?.IsPrivate ?? true,
        ),
        offline: ds.Sensor.MqttBeacon.IsOffline,
        reservations: ds.DeskReservations.map((dr) => ({
          start: lower(dr.Duration),
          end: upper(dr.Duration),
          cancelled: dr.Cancelled,
        })).filter(
          (r) => r.start <= new Date() && r.end >= new Date() && !r.cancelled,
        ),
      }))
        // Do not show desks that are currently in use and that aren't reserved for that duration
        .filter((d) => !d.deskInUse && d.reservations.length === 0)
        // Prefer non offline desks
        .sort((a, b) => (a.offline === b.offline ? 0 : a.offline ? 1 : -1))
        .map((d) => ({ id: d.deskId, name: d.deskName })) ?? [],
    [mapData?.Desks],
  );

  const highlightedFeatures = useMemo(
    () => ({
      features: deskInUseFeatures
        ?.filter((f) => {
          const reservations = f.getProperties().DeskReservations;
          return (
            reservations.length > 0 &&
            reservations.find(
              (reservation) =>
                !reservation.Cancelled &&
                lower(reservation.Duration) < new Date() &&
                upper(reservation.Duration) > new Date() &&
                reservation.IsPersonal,
            )
          );
        })
        .map((f) => ({
          geometry: f.getGeometry() as Point,
          radius: f.getProperties().Radius,
          color: getBeaconColor(f, warmMinutesPolicy),
        })),
      layer: deskInUseLayer,
    }),
    [deskInUseFeatures, deskInUseLayer, warmMinutesPolicy],
  );

  const setListener = useCallback(() => {
    olListenersKeys.push(
      baseLayer.on('change', (evt: any) => {
        assetLayer.setBaseImageSize(evt.width, evt.height);
        accuracyLayer.setBaseImageSize(evt.width, evt.height);
      }),
    );
  }, [accuracyLayer, assetLayer, baseLayer]);

  const defineLayersInitialVisibility = useCallback(() => {
    if (!showAssets) {
      assetLayer.setVisible(false);
      accuracyLayer.setVisible(false);
    }
    if (!showRoomBeacons) {
      roomBeaconLayer.setVisible(false);
    }
  }, [accuracyLayer, assetLayer, roomBeaconLayer, showAssets, showRoomBeacons]);

  useEffect(() => {
    defineLayersInitialVisibility();
    setListener();
    return () => {
      unByKey(olListenersKeys);
    };
  }, [setListener, defineLayersInitialVisibility]);

  useEffect(() => {
    olListenersKeys.push(
      deskInUseLayer.olLayer.on('change', () =>
        setDeskInUseFeatures(
          (
            deskInUseLayer.olLayer.getSource() as VectorSource<any>
          ).getFeatures() as DeskInUseFeatureType[],
        ),
      ),
    );
  }, [deskInUseLayer.olLayer.getSource, deskInUseLayer.olLayer.on]);

  const openReservationPopup = (
    feat: RoomBeaconFeatureType | DeskInUseFeatureType | RoomInUseFeatureType,
  ) => {
    if (userRoles?.includes(HasuraPermissions.VIEW_RESERVATION)) {
      // Prevent clicking for asset and private features
      if (
        isAssetFeature(feat) ||
        isRoomBeaconFeature(feat) ||
        isAssetAccuracyFeature(feat) ||
        getIsPrivate(feat)
      ) {
        return;
      }

      if (isDeskInUseFeature(feat) || isRoomFeature(feat)) {
        setClickedFeature(feat);
        setOpenReservationModal(true);
      }
    }
  };

  const showFeatureInfo = (
    evt: MapBrowserEvent<PointerEvent>,
    feat?: OccupancyMapFeature,
  ) => {
    if (feat) {
      setHoveredFeature(feat);
      if (isAssetFeature(feat) || isAssetAccuracyFeature(feat)) {
        for (const l of [roomInUseLayer, deskInUseLayer, roomBeaconLayer]) {
          l.hoveredFeature = undefined;
          l.olLayer.changed();
        }
        if (isAssetFeature(feat)) {
          assetLayer.hoveredFeature = feat;
          assetLayer.olLayer.changed();
        }
        if (isAssetAccuracyFeature(feat)) {
          accuracyLayer.hoveredFeature = feat;
          accuracyLayer.olLayer.changed();
        }
      } else if (isRoomBeaconFeature(feat)) {
        for (const l of [
          roomInUseLayer,
          deskInUseLayer,
          assetLayer,
          accuracyLayer,
        ]) {
          l.hoveredFeature = undefined;
          l.olLayer.changed();
        }
        roomBeaconLayer.hoveredFeature = feat;
        roomBeaconLayer.olLayer.changed();
      } else {
        for (const l of [assetLayer, accuracyLayer, roomBeaconLayer]) {
          l.hoveredFeature = undefined;
          l.olLayer.changed();
        }
        if (isDeskInUseFeature(feat)) {
          deskInUseLayer.hoveredFeature = feat;
          deskInUseLayer.olLayer.changed();
        }
        if (isRoomFeature(feat)) {
          roomInUseLayer.hoveredFeature = feat;
          roomInUseLayer.olLayer.changed();
        }
      }
    } else {
      setHoveredFeature(null);
      for (const l of [
        roomInUseLayer,
        deskInUseLayer,
        assetLayer,
        accuracyLayer,
        roomBeaconLayer,
      ]) {
        l.hoveredFeature = undefined;
        l.olLayer.changed();
      }
    }

    evt.map.getTargetElement().style.cursor =
      feat &&
      !(isAssetAccuracyFeature(feat) || isAssetFeature(feat)) &&
      !isRoomBeaconFeature(feat) &&
      !getIsPrivate(feat)
        ? 'pointer'
        : '';
  };

  return (
    <div className="relative w-full">
      <EmbeddedWrapper>
        <Loader loading={loading || imageLoading} />
      </EmbeddedWrapper>
      <div className="flex gap-2 md:gap-0 group">
        <EmbeddedWrapper>
          <div className="flex flex-col w-full">
            <div className="flex justify-between items-center">
              <div className="flex space-x-3">
                <LivePing />
                <div className="flex items-center space-x-2">
                  <Subtitle
                    value={intl.formatMessage(
                      {
                        id: 'Building Floor',
                      },
                      { number: usedFloor, building: buildingName },
                    )}
                  />
                  <Transition show={!!buildingName}>
                    <EmbeddedButton
                      urlParams={[
                        ['building', buildingName ?? ''],
                        ['floor', usedFloor?.toString() ?? '0'],
                      ]}
                      component="occupancymap"
                    />
                  </Transition>
                </div>
              </div>
              <div>
                <Transition show={!showAll}>
                  <StyledButton
                    className="text-sm md:text-base"
                    onClick={() => {
                      params.delete('room');
                      params.delete('desk');
                      navigate({ search: params.toString() });
                    }}
                  >
                    <FormattedMessage id="Show all desks and rooms" />
                  </StyledButton>
                </Transition>
              </div>
              <Transition show={!hideButtons}>
                <div>
                  <LuSettings2
                    className="size-6 cursor-pointer hover:text-primary-500"
                    onClick={() => setPanelOpen(true)}
                    data-test-id="open-occupancy-panel"
                  />
                </div>
              </Transition>
            </div>
            <Transition show={!hideButtons}>
              <div>
                <LastPolled lastPoll={lastUpdate} />
              </div>
            </Transition>
          </div>
        </EmbeddedWrapper>
      </div>
      <Map<OccupancyMapFeature>
        map={map}
        enableZoomButton={!hideButtons}
        layers={layers}
        isLoadingFeatures={!dataLoaded}
        highlightedFeatures={highlightedFeatures}
        onFeaturesClick={(features, evt) => {
          const [feat] = features;
          // if touch screen click, open the hover Popup with an extra button to reserve
          if (touchOnly(evt)) {
            setIsTouchedEvt(true);
            showFeatureInfo(evt, feat);
          } else {
            setIsTouchedEvt(false);
            if (
              feat &&
              (isDeskInUseFeature(feat) ||
                isRoomBeaconFeature(feat) ||
                isRoomFeature(feat))
            ) {
              openReservationPopup(feat);
            }
          }
        }}
        onFeaturesHover={(hoveredFeatures, evt) => {
          if (isTouchedEvt) {
            setIsTouchedEvt(false);
          }
          const [feat] = hoveredFeatures.map((hF) => hF.feature);
          showFeatureInfo(evt, feat);
        }}
        renderTooltip={({
          top,
          left,
          className,
          'data-test-id': dataTestId,
        }) => {
          if (!hoveredFeature) return undefined;

          if (isMobile) {
            return (
              <Card className="absolute bottom-0 right-0 left-0 z-20">
                <OccupancyMapPopupBody
                  hoveredFeature={hoveredFeature}
                  isTouchedEvt={isTouchedEvt}
                  setHoveredFeature={setHoveredFeature}
                  openReservationPopup={openReservationPopup}
                />
              </Card>
            );
          }

          // On touch there is a button -> it is a Popup and not a Tooltip, so we can't use Tooltip component
          if (isTouchedEvt) {
            return (
              <div
                style={{ top, left }}
                className={className}
                data-test-id={dataTestId}
              >
                <OccupancyMapPopupBody
                  hoveredFeature={hoveredFeature}
                  isTouchedEvt={isTouchedEvt}
                  setHoveredFeature={setHoveredFeature}
                  openReservationPopup={openReservationPopup}
                />
              </div>
            );
          }

          return (
            <TooltipWithBounds
              top={top}
              left={left}
              data-test-id={dataTestId}
              className={`${className ?? ''} max-w-[500px]`}
            >
              <OccupancyMapPopupBody
                hoveredFeature={hoveredFeature}
                isTouchedEvt={isTouchedEvt}
                setHoveredFeature={setHoveredFeature}
                openReservationPopup={openReservationPopup}
              />
            </TooltipWithBounds>
          );
        }}
        className={className}
      />
      <PrivateWrapper roleRequired={HasuraPermissions.VIEW_RESERVATION}>
        <EmbeddedWrapper>
          <Transition show={availableDesks.length > 0}>
            <StyledButton
              data-test-id="quick-reserve-button"
              onClick={() => {
                if (availableDesks[0]) {
                  params.set('desk', availableDesks[0]?.name);
                  navigate({ search: params.toString() });
                  setReservationBannerOpen(true);
                }
              }}
            >
              <FormattedMessage id="Quick reserve (4h)" />
            </StyledButton>
          </Transition>
        </EmbeddedWrapper>
      </PrivateWrapper>
      {clickedFeature && (
        <PrivateWrapper roleRequired={HasuraPermissions.VIEW_RESERVATION}>
          <ReservationModal
            feature={clickedFeature}
            open={openReservationModal}
            setOpen={setOpenReservationModal}
            onClose={() => {
              setClickedFeature(null);
              setOpenReservationModal(false);
            }}
          />
        </PrivateWrapper>
      )}
      <Panel
        open={panelOpen}
        setOpen={setPanelOpen}
        title={<FormattedMessage id="Settings" />}
      >
        <div className="flex flex-col gap-2 text-sm md:text-base">
          <LayerToggler layers={togglerLayers} />
          <LayerInfoTogglers switches={switches} />
        </div>
      </Panel>
      {reservationBannerOpen && !!availableDesks?.[0] && (
        <ReservationBanner
          setReservationBannerOpen={setReservationBannerOpen}
          availableDesk={availableDesks[0]}
        />
      )}
    </div>
  );
}
