import { RAINBOW } from '@/constants';
import AnimatedLine from '@/pages/ReportingView/components/Reports/components/BarChart/components/AnimatedLine';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import { ParentSize } from '@visx/responsive';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { Line, Polygon } from '@visx/shape';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import { type MarginProps, Themes, isNonNullable } from 'common/types';
import AnimatedRect from 'generic/components/Chart/AnimatedRect';
import Axis from 'generic/components/Chart/Axis';
import useStore from 'model/store';
import { motion } from 'motion/react';
import { FormattedMessage, useIntl } from 'translations/Intl';
import getColor, { primaryColorToRGB } from 'utils/getColor';
import { REPORTING_RIGHT_MARGIN } from '../../Reports';

type TooltipData<T> = {
  data?: T;
  group?: string;
};

interface ResponsiveBarChartProps<T> {
  margin?: MarginProps;
  data: T[];
  colors?: string[];
  yScaleAccessor: (d: T) => number;
  yScaleLabel?: string;
  groupAccessor: (d: T) => string;
  y1Accessor?: (d: T) => number;
  y1Label?: string;
  y2Accessor?: (d: T) => number;
  y2Label?: string;
  averageValue?: number;
  maxHourlyValue?: number;
  maxDailyValue?: number;
  averageLabel?: string;
  xAxisLabel?: string;
  usePercentage?: boolean;
}

interface BarChartProps<T> extends ResponsiveBarChartProps<T> {
  height: number;
  width: number;
}

function BarChart<T>({
  height,
  width,
  margin = {
    top: 40,
    left: 70,
    right: REPORTING_RIGHT_MARGIN,
    bottom: 50,
  },
  data,
  colors,
  yScaleAccessor,
  yScaleLabel,
  groupAccessor,
  y1Accessor,
  y1Label,
  y2Accessor,
  y2Label,
  averageValue,
  averageLabel,
  maxDailyValue,
  maxHourlyValue,
  xAxisLabel,
  usePercentage = true,
}: BarChartProps<T>) {
  const intl = useIntl();
  const theme = useStore((state) => state.userSettings.theme);
  const groups = data.map(groupAccessor);
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = height - margin.top - margin.bottom;

  const colorScale = scaleOrdinal({
    domain: groups,
    range: colors ?? RAINBOW,
  });

  const xScale = scaleBand<string>({
    range: [0, xMax],
    domain: groups,
    paddingInner: 0.2,
  });

  const yScale = scaleLinear<number>({
    range: [yMax, 0],
    domain: [
      0,
      usePercentage
        ? Math.max(100, Math.max(...data.map(yScaleAccessor)))
        : Math.max(...data.map(yScaleAccessor)),
    ],
    nice: true,
  });

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData<T>>();

  const variants = {
    initial: {
      fill: '#fff',
      pathLength: 0,
    },
    animate: {
      transition: {
        ease: 'easeInOut',
        duration: 1,
      },
      pathLength: 1,
    },
  };

  const shapeScale = scaleOrdinal<string, React.FC | React.ReactNode>({
    domain: [
      y1Accessor
        ? (y1Label ?? intl.formatMessage({ id: 'Max Hourly Occupancy' }))
        : undefined,
      y2Accessor
        ? (y2Label ?? intl.formatMessage({ id: 'Max Daily Occupancy' }))
        : undefined,
      averageValue
        ? (averageLabel ??
          intl.formatMessage({ id: 'Average Daily Occupancy' }))
        : undefined,
    ].filter(isNonNullable),
    range: [
      y1Accessor && (
        <Polygon
          key={y1Label ?? intl.formatMessage({ id: 'Max Hourly Occupancy' })}
          sides={6}
          center={{ x: 6, y: 7 }}
          rotate={90}
          size={6}
          fill={primaryColorToRGB(500, 1)}
        />
      ),
      y2Accessor && (
        <Polygon
          key={y2Label ?? intl.formatMessage({ id: 'Max Daily Occupancy' })}
          sides={4}
          center={{ x: 6, y: 7 }}
          rotate={90}
          size={6}
          fill={primaryColorToRGB(500, 1)}
        />
      ),
      averageValue && (
        <Line
          key={
            averageLabel ??
            intl.formatMessage({ id: 'Average Daily Occupancy' })
          }
          from={{ x: 0, y: 8 }}
          to={{ x: 10, y: 8 }}
          strokeWidth={2}
          stroke={
            theme.color === Themes.LIGHT
              ? getColor('NEUTRAL600')
              : getColor('NEUTRAL300')
          }
        />
      ),
    ].filter(Boolean),
  });

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          <GridRows
            numTicks={10}
            scale={yScale}
            width={xMax}
            height={yMax}
            strokeDasharray="1,3"
            stroke={getColor('NEUTRAL600')}
            strokeOpacity={0.6}
          />
          <g>
            {data.map((d) => {
              const group = groupAccessor(d);
              const barWidth = xScale.bandwidth();
              const barHeight = yMax - (yScale(yScaleAccessor(d)) ?? 0);
              const barX = xScale(group) ?? 0;
              const barY = yMax - barHeight;
              return (
                <g key={`bar-${group}`}>
                  <AnimatedRect
                    data-test-id={`bar-${group}`}
                    bar={{
                      width: barWidth,
                      height: barHeight,
                      y: barY,
                      x: barX,
                      color: colorScale(group),
                    }}
                    onMouseMove={(event) => {
                      showTooltip({
                        tooltipData: {
                          data: d,
                          group,
                        },
                        tooltipTop: event?.y,
                        tooltipLeft: event?.x,
                      });
                    }}
                    onMouseOut={hideTooltip}
                  />
                  {y1Accessor && (
                    <Polygon
                      sides={6}
                      center={{
                        x: barX + barWidth / 2,
                        y: yScale(y1Accessor(d)),
                      }}
                      rotate={90}
                      size={6}
                    >
                      {({ points }) => (
                        <motion.polygon
                          initial="initial"
                          animate="animate"
                          variants={{
                            initial: variants.initial,
                            animate: {
                              fill: colorScale(group),
                              ...variants.animate,
                            },
                          }}
                          points={`${points}`}
                          fillOpacity={0.8}
                        />
                      )}
                    </Polygon>
                  )}
                  {y2Accessor && (
                    <Polygon
                      sides={4}
                      center={{
                        x: barX + barWidth / 2,
                        y: yScale(y2Accessor(d)),
                      }}
                      rotate={90}
                      size={6}
                    >
                      {({ points }) => (
                        <motion.polygon
                          initial="initial"
                          animate="animate"
                          variants={{
                            initial: variants.initial,
                            animate: {
                              fill: colorScale(group),
                              ...variants.animate,
                            },
                          }}
                          points={`${points}`}
                          fillOpacity={0.8}
                        />
                      )}
                    </Polygon>
                  )}
                </g>
              );
            })}
            {averageValue && (
              <AnimatedLine xMax={xMax} y={yScale(averageValue)} />
            )}
            {maxHourlyValue && (
              <AnimatedLine
                xMax={xMax}
                y={yScale(maxHourlyValue)}
                strokeDasharray="2,3"
              />
            )}
            {maxDailyValue && (
              <AnimatedLine
                xMax={xMax}
                y={yScale(maxDailyValue)}
                strokeDasharray="4,4"
              />
            )}
          </g>
          <Axis
            lowLevelChart
            orientation="left"
            scale={yScale}
            tickFormat={(y) => (usePercentage ? `${y}%` : y)}
            label={yScaleLabel ?? intl.formatMessage({ id: 'Occupancy' })}
          />
          {xAxisLabel && (
            <Axis
              lowLevelChart
              orientation="bottom"
              top={yMax}
              scale={xScale}
              label={xAxisLabel}
            />
          )}
        </Group>
      </svg>
      <div className="flex items-center justify-evenly absolute top-0 w-full space-y-2">
        <LegendOrdinal
          direction="row"
          labelMargin="0 15px 0 0"
          scale={shapeScale}
        >
          {(groups) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              {groups.map((label, i) => {
                const shape = shapeScale(label.datum);
                return (
                  <LegendItem margin="0 5px" key={`legend-quantile-${i}`}>
                    <svg width="16" height="16" viewBox="0 0 16 16">
                      {shape as React.ReactElement}
                    </svg>
                    <LegendLabel align="left" className="text-xs">
                      {label.text}
                    </LegendLabel>
                  </LegendItem>
                );
              })}
            </div>
          )}
        </LegendOrdinal>
      </div>
      {tooltipOpen && tooltipData?.data && tooltipData?.group && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            background:
              theme.color === Themes.LIGHT
                ? getColor('WHITE')
                : getColor('NEUTRAL900'),
          }}
        >
          <>
            {(averageValue || maxDailyValue || maxHourlyValue) && (
              <>
                <div className="text-primary-500">
                  <strong>
                    <FormattedMessage id="All" />
                  </strong>
                </div>
                <div
                  className="dark:text-neutral-200"
                  data-test-id="report-bar-tooltip-all"
                >
                  {averageValue && (
                    <div>
                      {averageLabel ?? (
                        <FormattedMessage id="Average Daily Occupancy" />
                      )}
                      :{' '}
                      {`${averageValue.toFixed(2)}${usePercentage ? '%' : ''}`}
                    </div>
                  )}
                  {maxHourlyValue && (
                    <div>
                      <FormattedMessage id="Max Hourly Occupancy" />:{' '}
                      {`${maxHourlyValue.toFixed(2)}${usePercentage ? '%' : ''}`}
                    </div>
                  )}
                  {maxDailyValue && (
                    <div>
                      <FormattedMessage id="Max Daily Occupancy" />:{' '}
                      {`${maxDailyValue.toFixed(2)}${usePercentage ? '%' : ''}`}
                    </div>
                  )}
                </div>
              </>
            )}
            <div style={{ color: colorScale(tooltipData.group) }}>
              <strong>{`${xAxisLabel} ${tooltipData.group}`}</strong>
            </div>
            <div
              className="dark:text-neutral-200"
              data-test-id="report-bar-tooltip"
            >
              <div>
                {yScaleLabel ?? <FormattedMessage id="Max Hourly Occupancy" />}:{' '}
                {`${yScaleAccessor(tooltipData.data).toFixed(2)}${usePercentage ? '%' : ''}`}
              </div>
              {y1Accessor && (
                <div>
                  {y1Label ?? <FormattedMessage id="Max Hourly Occupancy" />}:{' '}
                  {`${y1Accessor(tooltipData.data).toFixed(2)}${usePercentage ? '%' : ''}`}
                </div>
              )}
              {y2Accessor && (
                <div>
                  {y2Label ?? <FormattedMessage id="Max Daily Occupancy" />}:{' '}
                  {`${y2Accessor(tooltipData.data).toFixed(2)}${usePercentage ? '%' : ''}`}
                </div>
              )}
            </div>
          </>
        </TooltipWithBounds>
      )}
    </div>
  );
}

export default function ResponsiveBarChart<T>(
  props: ResponsiveBarChartProps<T>,
) {
  return (
    <ParentSize>
      {({ height, width }) => (
        <BarChart {...props} height={height} width={width} />
      )}
    </ParentSize>
  );
}
