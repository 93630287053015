import PopupBody from 'generic/components/layout/PopupBody';
import type { CleaningBeaconFeatureType } from 'generic/layers/CleaningBeaconLayer';
import type { CleaningFeatureType } from 'generic/layers/CleaningLayer';
import type TypedFeature from 'generic/layers/TypedFeature';
import type { Polygon } from 'ol/geom';
import { offlineIcon } from 'pages/FindMyPlaceView/components/OccupancyMap/components/OccupancyPopup/OccupancyPopup';
import { useState } from 'react';
import {
  LuBadgeCheck,
  LuCircleX,
  LuEye,
  LuEyeClosed,
  LuWandSparkles,
} from 'react-icons/lu';
import { FormattedMessage } from 'translations/Intl';
import { formattedDistance } from 'utils/date';
import { isBeingCleaned } from '../CleaningActionButton/CleaningActionButton';
import type { DeskSharingRoom } from '../CleaningModal/CleaningModal';

export type RoomCleaningFeatureType = TypedFeature<DeskSharingRoom, Polygon>;

export function isRoomCleaning(
  feature:
    | CleaningFeatureType
    | CleaningBeaconFeatureType
    | RoomCleaningFeatureType,
): feature is RoomCleaningFeatureType {
  if ('RoomSensors' in (feature as RoomCleaningFeatureType).getProperties()) {
    return true;
  }
  return false;
}

export function isCleaningBeacon(
  feature:
    | CleaningFeatureType
    | CleaningBeaconFeatureType
    | RoomCleaningFeatureType,
): feature is CleaningBeaconFeatureType {
  if ('Success' in (feature as CleaningBeaconFeatureType).getProperties()) {
    return true;
  }
  return false;
}

export function isCleaning(
  feature:
    | CleaningFeatureType
    | CleaningBeaconFeatureType
    | RoomCleaningFeatureType,
): feature is CleaningFeatureType {
  if ((feature as CleaningFeatureType).getProperties().LastHeartbeat) {
    return true;
  }
  return false;
}

function Icon({
  hoveredFeature,
  timeBeforeClean,
  timeBeforeCheck,
}: {
  hoveredFeature:
    | CleaningFeatureType
    | CleaningBeaconFeatureType
    | RoomCleaningFeatureType;
  timeBeforeClean: number;
  timeBeforeCheck: number;
}) {
  if (isCleaningBeacon(hoveredFeature)) {
    if (hoveredFeature.getProperties().Success) {
      return <LuBadgeCheck className="mr-2 text-green-700" />;
    }
    return <LuCircleX className="mr-2 text-red-700" />;
  }
  if (isRoomCleaning(hoveredFeature)) {
    return <LuWandSparkles className="mr-2 text-green-700" />;
  }
  if (hoveredFeature.getProperties().IsOffline) {
    return offlineIcon;
  }
  const usageTime = hoveredFeature.getProperties().HotMinutes;
  if (usageTime > timeBeforeClean) {
    return <LuWandSparkles className="mr-2 text-primary-700" />;
  }
  if (usageTime > timeBeforeCheck) {
    return <LuEye className="mr-2 text-primary-500" />;
  }
  return <LuEyeClosed className="mr-2 text-neutral-400" />;
}

function CleaningMessage({
  hoveredFeature,
  timeBeforeClean,
  timeBeforeCheck,
}: {
  hoveredFeature:
    | CleaningFeatureType
    | CleaningBeaconFeatureType
    | RoomCleaningFeatureType;
  timeBeforeClean: number;
  timeBeforeCheck: number;
}) {
  if (isCleaningBeacon(hoveredFeature)) {
    if (hoveredFeature.getProperties().Success) {
      return <FormattedMessage id="Cleaning in progress" />;
    }
    return <FormattedMessage id="Couldn't activate cleaning mode" />;
  }
  if (isRoomCleaning(hoveredFeature)) {
    if (isBeingCleaned(hoveredFeature.getProperties().CleaningDuration)) {
      return <FormattedMessage id="Cleaning in progress" />;
    }
    return <FormattedMessage id="Activate cleaning mode" />;
  }
  if (hoveredFeature.getProperties().IsOffline) {
    return (
      <div className="flex flex-col">
        <FormattedMessage id="The device may be offline" />
        {hoveredFeature.getProperties().LastHeartbeat && (
          <>
            <br />
            <div className="flex items-center">
              <FormattedMessage id="Last Activity" />:{' '}
              {formattedDistance(
                new Date(hoveredFeature.getProperties().LastHeartbeat),
              )}
            </div>
          </>
        )}
      </div>
    );
  }
  const usageTime = hoveredFeature.getProperties().HotMinutes;

  if (usageTime > timeBeforeClean) {
    return (
      <b>
        <FormattedMessage id="to clean" />
      </b>
    );
  }
  if (usageTime > timeBeforeCheck) {
    return (
      <b>
        <FormattedMessage id="check" />
      </b>
    );
  }
  return (
    <b>
      <FormattedMessage id="clean" />
    </b>
  );
}

export default function CleaningPopup({
  hoveredFeature,
  timeBeforeClean,
  timeBeforeCheck,
}: {
  hoveredFeature:
    | CleaningFeatureType
    | CleaningBeaconFeatureType
    | RoomCleaningFeatureType;
  timeBeforeClean: number;
  timeBeforeCheck: number;
}) {
  const [timeOffset] = useState(new Date().getTimezoneOffset() * 60 * 1000);

  const title = () => {
    if (isCleaning(hoveredFeature)) return hoveredFeature.getProperties().Desk;
    if (isRoomCleaning(hoveredFeature))
      return hoveredFeature.getProperties().Name;
    return hoveredFeature.getProperties().Name;
  };

  return (
    <PopupBody
      title={title()}
      renderIcon={() => (
        <Icon
          hoveredFeature={hoveredFeature}
          timeBeforeClean={timeBeforeClean}
          timeBeforeCheck={timeBeforeCheck}
        />
      )}
    >
      <CleaningMessage
        hoveredFeature={hoveredFeature}
        timeBeforeClean={timeBeforeClean}
        timeBeforeCheck={timeBeforeCheck}
      />
      {isCleaning(hoveredFeature) &&
        hoveredFeature.getProperties().LastCleaned && (
          <div className="flex items-center">
            <FormattedMessage id="Last time cleaned" />:{' '}
            {formattedDistance(
              new Date(
                new Date(hoveredFeature.getProperties().LastCleaned).valueOf() +
                  // 'LastCleaned' is saved with timezone offset in DB
                  // Timezone Offset is used because 'LastCleaned' is compared with
                  // History 'Duration' which uses timezone offset itself
                  timeOffset,
              ),
            )}
          </div>
        )}
    </PopupBody>
  );
}
