import { LuLoaderCircle } from 'react-icons/lu';

interface LoadingSpinnerProps {
  loading: boolean;
}

export default function LoadingSpinner({ loading }: LoadingSpinnerProps) {
  return loading ? (
    <div className="absolute top-0 right-0 left-0 bottom-0 z-20 flex items-center justify-center print:hidden">
      <div
        data-test-id="is-loading"
        className="absolute top-0 right-0 left-0 bottom-0 bg-neutral-400 opacity-30 rounded-2xl"
      />
      <div className="bg-white dark:bg-neutral-700 rounded-md p-2 flex justify-center items-center">
        <LuLoaderCircle className="size-8 animate-spin text-primary-500" />
      </div>
    </div>
  ) : null;
}
