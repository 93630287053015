import { RoomTypes } from '@/common/types';
import Button from '@/generic/components/Form/Button';
import Tooltip from '@/generic/components/Tooltip';
import type { FloorPartsFragment } from '@/graphql/types';
import useStore from '@/model/store';
import { isBeingCleaned } from '@/pages/CleaningView/components/BuildingsCleaningNeeds/components/CleaningMap/components/CleaningActionButton';
import { FormattedMessage } from '@/translations/Intl';
import { formattedDistance, lower, upper } from '@/utils/date';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { LuCircleAlert, LuMap, LuWandSparkles } from 'react-icons/lu';

const getCleaningDuration = (fl: BuildingFloors, fls: BuildingFloors[]) =>
  fls.find((rF) => rF.number === fl.number)?.cleaningDuration ||
  fl.cleaningDuration;

const renderCleaningTimes = (cleaningDuration?: string | null) => {
  if (!cleaningDuration) {
    return null;
  }
  const start = formattedDistance(lower(cleaningDuration));
  const end = formattedDistance(upper(cleaningDuration));
  return (
    <>
      <FormattedMessage
        id="Start: {start}"
        values={{
          start,
        }}
      />
      <br />
      <FormattedMessage
        id="End: {end}"
        values={{
          end,
        }}
      />
    </>
  );
};

export const getGridColsClass = (columnSize: number) => {
  switch (columnSize) {
    case 1:
      return 'grid-cols-1';
    case 2:
      return 'grid-cols-2';
    case 3:
      return 'grid-cols-3';
    case 4:
      return 'grid-cols-4';
    case 5:
      return 'grid-cols-5';
    case 6:
      return 'grid-cols-6';
    default:
      return 'grid-cols-1';
  }
};

export const meetingAndDesks = (roomTypes: RoomTypes[]) =>
  JSON.stringify(roomTypes.slice().sort((a, b) => a.localeCompare(b))) ===
  JSON.stringify(
    [RoomTypes.MEETING, RoomTypes.DESKS].sort((a, b) => a.localeCompare(b)),
  );

const renderOfflineCount = (offlineRooms: number, offlineDesks: number) => {
  const roomCount =
    offlineRooms > 1 ? (
      <FormattedMessage
        id="{rooms} rooms"
        values={{
          rooms: offlineRooms,
        }}
      />
    ) : (
      <FormattedMessage
        id="{room} room"
        values={{
          room: offlineRooms,
        }}
      />
    );
  const deskCount =
    offlineDesks > 1 ? (
      <FormattedMessage
        id="{desks} desks"
        values={{
          desks: offlineDesks,
        }}
      />
    ) : (
      <FormattedMessage
        id="{desk} desk"
        values={{
          desk: offlineDesks,
        }}
      />
    );
  return (
    <div className="flex flex-col">
      <div>
        <FormattedMessage id="offline" />:
      </div>
      <div>{offlineRooms > 0 && roomCount}</div>
      <div>{offlineDesks > 0 && deskCount}</div>
    </div>
  );
};

export enum Order {
  UP = 1,
  DOWN = -1,
}

export enum Types {
  TOCLEAN = 'to clean',
  CHECK = 'check',
  CLEAN = 'clean',
  OFFLINE = 'offline',
  TOTAL = 'total',
  RESERVED = 'reserved',
  UNUSED = 'unused',
  BUILDING = 'building',
}

export const typeColor = (type: Types): string => {
  if (type === Types.TOCLEAN) {
    return 'text-primary-700';
  }
  if (type === Types.CHECK) {
    return 'text-primary-500';
  }
  if (type === Types.CLEAN) {
    return 'text-neutral-400';
  }
  if (type === Types.UNUSED) {
    return 'text-green-500';
  }
  if (type === Types.RESERVED) {
    return 'text-red-500';
  }
  return '';
};

interface Needs {
  id: Types;
  value: number;
}

export interface BuildingFloors {
  id: number;
  number: number;
  total: Needs[];
  cleaningDuration?: string | null;
}

export interface BuildingNeeds {
  id: number;
  name: string;
  floors: BuildingFloors[];
  roomFloors: BuildingFloors[];
}

interface FloorRowProps {
  data: BuildingNeeds;
  order: Order;
  orderProperty: Types;
  isSelectedBuilding?: boolean;
  floor?: FloorPartsFragment;
  columns: Types[];
  map?: React.JSX.Element;
}

export default function FloorRow({
  data,
  order,
  orderProperty,
  isSelectedBuilding,
  floor,
  columns,
  map,
}: FloorRowProps) {
  const roomTypes = useStore((state) => state.userSettings.roomTypes);
  const setBuilding = useStore((state) => state.userApi.setBuilding);
  const setFloor = useStore((state) => state.userApi.setFloor);
  const [mapToggled, setMapToggled] = useState(false);

  const onFloorSelect = (
    b: BuildingNeeds,
    f: BuildingNeeds['floors'][number],
  ) => {
    setBuilding({ Id: b.id, Name: b.name });

    setFloor({ Id: f.id, Number: f.number });
    if (f.number === floor?.Number) {
      setMapToggled(!mapToggled);
    } else {
      setMapToggled(true);
    }
  };

  const findFloorValue = useCallback(
    (object: BuildingFloors) => {
      if (orderProperty === Types.BUILDING) {
        return object.number;
      }
      return object.total
        .filter((t) => t.id === orderProperty)
        .reduce((c, d) => c + d.value, 0);
    },
    [orderProperty],
  );

  const getRoom = useCallback(
    (id: Types, floorNumber: number) =>
      data.roomFloors
        .filter((rf) => rf.number === floorNumber)[0]
        ?.total.filter((t) => t.id === id)[0],
    [data.roomFloors],
  );

  const getDesk = useCallback(
    (id: Types, floorNumber: number) =>
      data.floors
        .filter((rf) => rf.number === floorNumber)[0]
        ?.total.filter((t) => t.id === id)[0],
    [data.floors],
  );

  const formattedData = useMemo(() => {
    if (
      roomTypes.includes(RoomTypes.DESKS) &&
      roomTypes.includes(RoomTypes.MEETING)
    ) {
      return data.floors.map((f) => ({
        id: f.id,
        number: f.number,
        total: f.total.map((t) => ({
          id: t.id,
          value: t.value + (getRoom(t.id, f.number)?.value ?? 0),
        })),
        cleaningDuration:
          getCleaningDuration(f, data.roomFloors) ??
          getCleaningDuration(f, data.floors),
      }));
    }
    // Only desk occupancy
    if (roomTypes.includes(RoomTypes.DESKS)) {
      return data.floors.map((f) => ({
        id: f.id,
        number: f.number,
        total: f.total,
        cleaningDuration: getCleaningDuration(f, data.floors),
      }));
    }
    // Only meeting room occupancy
    if (roomTypes.includes(RoomTypes.MEETING)) {
      return data.roomFloors.map((f) => ({
        id: f.id,
        number: f.number,
        total: f.total,
        cleaningDuration: getCleaningDuration(f, data.roomFloors),
      }));
    }

    return [];
  }, [data.floors, data.roomFloors, getRoom, roomTypes]);

  // Show the map if it is set in the store (used when pressing "show" on Favorite Room/Desk)
  useEffect(() => {
    if (floor) {
      setMapToggled(true);
    }
  }, [floor]);

  return (
    <>
      {formattedData
        .sort((a, b) => {
          if (order === Order.DOWN) {
            return findFloorValue(b) - findFloorValue(a);
          }
          return findFloorValue(a) - findFloorValue(b);
        })
        .map((fl) => {
          const offlineRooms = getRoom(Types.OFFLINE, fl.number)?.value ?? 0;
          const offlineDesks = getDesk(Types.OFFLINE, fl.number)?.value ?? 0;
          const showFloor =
            isSelectedBuilding &&
            floor &&
            fl.number === floor.Number &&
            mapToggled;
          return (
            <div key={fl.id}>
              <Button
                className={`relative grid grid-cols-12 group w-full gap-2 border-t border-neutral-300 dark:border-neutral-700 px-2 md:px-0 py-2 cursor-pointer ${
                  showFloor
                    ? 'bg-primary-100 dark:bg-primary-300 dark:text-neutral-100'
                    : ''
                }`}
                onClick={() => onFloorSelect(data, fl)}
              >
                <div className="md:col-span-10 md:col-start-3 col-span-12 items-center grid grid-cols-5">
                  <div
                    data-test-id="map-expander"
                    className="flex col-span-2 items-center"
                  >
                    <LuMap
                      className={`size-4 mx-4 text-primary-600 group-hover:opacity-100 ${
                        showFloor ? 'opacity-100' : 'opacity-0'
                      }`}
                    />
                    <span className="mr-2">
                      <FormattedMessage
                        id="{number} Floor"
                        values={{
                          number: fl.number,
                        }}
                      />
                    </span>
                    {(offlineRooms > 0 || offlineDesks > 0) && (
                      <Tooltip
                        content={
                          <p>
                            <LuCircleAlert
                              className={`${
                                !showFloor || fl.number !== floor.Number
                                  ? 'dark:text-white'
                                  : ''
                              } size-5 rounded-full text-neutral-700 transition-all`}
                            />
                          </p>
                        }
                      >
                        <span className="text-xs">
                          {renderOfflineCount(offlineRooms, offlineDesks)}
                        </span>
                      </Tooltip>
                    )}
                    {isBeingCleaned(fl.cleaningDuration) && (
                      <Tooltip
                        content={
                          <div className="flex items-center justify-center ml-2">
                            <LuWandSparkles
                              className={`size-5 text-neutral-700  ${
                                isSelectedBuilding ? 'dark:text-white' : ''
                              } animate-pulse-slow`}
                            />
                          </div>
                        }
                      >
                        <>
                          <FormattedMessage id="Cleaning in progress" />
                          <br />
                          {renderCleaningTimes(fl.cleaningDuration)}
                        </>
                      </Tooltip>
                    )}
                  </div>
                  <div
                    className={`col-span-3 grid ${getGridColsClass(
                      columns.length,
                    )} justify-items-center`}
                  >
                    {fl.total
                      .filter((f) => columns.includes(f.id))
                      .map((v) => (
                        <Fragment key={`${fl.id}-${v.id}`}>
                          {meetingAndDesks(roomTypes) ? (
                            <Tooltip
                              content={
                                <div
                                  className={`items-center col-span-1 ${typeColor(
                                    v.id,
                                  )}`}
                                >
                                  {v.value}
                                </div>
                              }
                              className="whitespace-pre w-min"
                            >
                              <FormattedMessage
                                id="Rooms/Desks"
                                values={{
                                  rooms: getRoom(v.id, fl.number)?.value,
                                  desks: getDesk(v.id, fl.number)?.value,
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <div
                              className={`items-center col-span-1 ${typeColor(
                                v.id,
                              )}`}
                            >
                              {v.value}
                            </div>
                          )}
                        </Fragment>
                      ))}
                  </div>
                </div>
              </Button>
              {showFloor && map && (
                <div className="grid grid-cols-12 bg-primary-100 dark:bg-primary-300">
                  <div className="col-span-12 md:col-span-10 md:col-start-3 p-4">
                    {map}
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </>
  );
}
