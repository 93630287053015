import Transition from '@/generic/components/Transition';
import {
  useFavoriteDeskAndRoomQuery,
  useInsertDeskReservationMutation,
  useInsertRoomReservationMutation,
} from '@/graphql/types';
import { lower, serializeRange, upper } from '@/utils/date';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useRoomDeskFilter from '@/utils/graphql/useRoomDeskFilter';
import useToast from '@/utils/graphql/useToast';
import { useIntl } from 'translations/Intl';
import Reserved from './Reserved';

export interface FavoriteReservationProps {
  reservationStartDate: Date;
  reservationEndDate: Date;
}

export default function FavoriteReservation({
  reservationStartDate,
  reservationEndDate,
}: FavoriteReservationProps) {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [, addDeskReservation] = useInsertDeskReservationMutation();
  const [, addRoomReservation] = useInsertRoomReservationMutation();
  const [{ data, fetching }] = useFavoriteDeskAndRoomQuery({
    variables: {
      ...useRoomDeskFilter(),
    },
  });

  const desks = data?.DeskReservations?.map((desk) => desk.Desk) ?? [];
  const rooms = data?.RoomReservations?.map((room) => room.Room) ?? [];

  const duration = serializeRange({
    start: {
      value: reservationStartDate,
      inclusive: true,
    },
    end: {
      value: reservationEndDate,
      inclusive: false,
    },
  });

  const favoriteDesk = desks
    .sort(
      (a, b) =>
        desks.filter((v) => v.Id === a.Id).length -
        desks.filter((v) => v.Id === b.Id).length,
    )
    .pop();

  const favoriteRoom = rooms
    .sort(
      (a, b) =>
        rooms.filter((v) => v.Id === a.Id).length -
        rooms.filter((v) => v.Id === b.Id).length,
    )
    .pop();

  const favoriteDeskCurrentReservations =
    data?.DeskReservations?.filter(
      (desk) =>
        desk.Desk.Id === favoriteDesk?.Id &&
        // Check if the overlap -> true if overlapping -> room is reserved
        lower(desk.Duration) <= reservationEndDate &&
        upper(desk.Duration) >= reservationStartDate,
    ) ?? [];

  const favoriteRoomCurrentReservations =
    data?.RoomReservations?.filter(
      (room) =>
        room.Room.Id === favoriteRoom?.Id &&
        // Check if the overlap -> true if overlapping -> room is reserved
        lower(room.Duration) <= reservationEndDate &&
        upper(room.Duration) >= reservationStartDate,
    ) ?? [];

  const favoriteDeskNumberOfReservations =
    data?.DeskReservations?.filter((desk) => desk.Desk.Id === favoriteDesk?.Id)
      .length ?? 0;

  const favoriteRoomNumberOfReservations =
    data?.RoomReservations?.filter((room) => room.Room.Id === favoriteRoom?.Id)
      .length ?? 0;

  const favoriteDeskIsReserved = favoriteDeskCurrentReservations.length > 0;
  const favoriteRoomIsReserved = favoriteRoomCurrentReservations.length > 0;

  return (
    <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
      <Transition show={!!favoriteDesk} className="flex">
        <Reserved
          fetching={fetching}
          type="Desk"
          name={favoriteDesk?.Name ?? ''}
          building={favoriteDesk?.Sensor?.RoomSensors[0]?.Room.Floor.Building}
          floor={favoriteDesk?.Sensor?.RoomSensors[0]?.Room.Floor}
          isReserved={favoriteDeskIsReserved}
          reservationsDuration={
            favoriteDeskCurrentReservations[0]
              ? favoriteDeskCurrentReservations[0]?.Duration
              : '[00:00:00, 01:00:00]'
          }
          numberOfTimesReserved={favoriteDeskNumberOfReservations}
          reserveAction={() =>
            addDeskReservation(
              {
                Desk: [
                  {
                    DeskId: favoriteDesk?.Id,
                    Duration: duration,
                  },
                ],
              },
              hasuraHeader(HasuraPermissions.READ, [
                'DeskReservations_aggregate',
              ]),
            ).then((message) => {
              toast(message, {
                message: {
                  type: 'success',
                  content: intl.formatMessage(
                    {
                      id: 'Added reservation for desk',
                    },
                    { desk: favoriteDesk?.Name },
                  ),
                },
              });
            })
          }
        />
      </Transition>
      <Transition show={!!favoriteRoom} className="flex">
        <Reserved
          fetching={fetching}
          type="Room"
          name={favoriteRoom?.Name ?? ''}
          building={favoriteRoom?.Floor.Building}
          floor={favoriteRoom?.Floor}
          isReserved={favoriteRoomIsReserved}
          reservationsDuration={
            favoriteRoomCurrentReservations[0]
              ? favoriteRoomCurrentReservations[0]?.Duration
              : '[00:00:00, 01:00:00]'
          }
          numberOfTimesReserved={favoriteRoomNumberOfReservations}
          reserveAction={() => {
            addRoomReservation(
              {
                Room: [
                  {
                    RoomId: favoriteRoom?.Id,
                    Duration: duration,
                  },
                ],
              },
              hasuraHeader(HasuraPermissions.READ, [
                'RoomReservations_aggregate',
              ]),
            ).then((message) => {
              toast(message, {
                message: {
                  type: 'success',
                  content: intl.formatMessage(
                    {
                      id: 'Added reservation for room',
                    },
                    { room: favoriteRoom?.Name },
                  ),
                },
              });
            });
          }}
        />
      </Transition>
    </div>
  );
}
