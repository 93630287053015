import Card from '@/generic/components/Card';
import Button from '@/generic/components/Form/Button';
import StyledButton from '@/generic/components/Form/Button/StyledButton';
import PrivateWrapper from '@/generic/components/PrivateWrapper';
import Loader from '@/generic/components/layout/BarLoader';
import NoContent from '@/generic/components/layout/NoContent';
import { type BuildingPartsFragment, useBuildingsQuery } from '@/graphql/types';
import { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';
import HelpTooltip from 'generic/components/HelpTooltip';
import useStore from 'model/store';
import AddBuildingModal from 'pages/AdminView/components/BuildingList/components/AddBuildingModal';
import { useMemo, useState } from 'react';
import { LuPlus } from 'react-icons/lu';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'translations/Intl';

import { LuBuilding2, LuTrash } from 'react-icons/lu';
import RemoveBuildingModal from './components/RemoveBuildingModal';

interface BuildingListProps {
  isAdding: boolean;
  setIsAdding: (val: boolean) => void;
}

export default function BuildingList({
  isAdding,
  setIsAdding,
}: BuildingListProps) {
  const intl = useIntl();
  const [buildingToRemove, setBuildingToRemove] = useState<
    BuildingPartsFragment | undefined
  >(undefined);
  const setInitialSetupSteps = useStore((state) => state.setInitialSetupSteps);

  const [{ data: buildings, fetching: loadingOrga }] = useBuildingsQuery({
    context: useMemo(
      () => ({
        additionalTypenames: ['Buildings'],
        // Won't trigger a fetch when a new organization was created and then inserting a building
        // Only works with the cache-and-network request policy
        requestPolicy: 'cache-and-network',
      }),
      [],
    ),
  });

  return (
    <>
      <Loader loading={loadingOrga} />
      <div className="flex flex-wrap gap-4" data-test-id="building-list">
        {buildings?.Buildings.map((building) => {
          const { Name, Floors } = building;
          return (
            <HelpTooltip
              key={Name}
              stepName="clickedBuilding"
              content={
                <Card
                  noPadding
                  className="sm:w-72 h-48 w-full hover:shadow-lg cursor-pointer relative"
                >
                  <NavLink
                    data-test-id={`building-${Name}`}
                    to={`../${encodeURIComponent(Name)}/${
                      Floors[0]?.Number ?? 1
                    }`}
                    onClick={() => {
                      setInitialSetupSteps('clickedBuilding');
                    }}
                    className="h-full w-full flex items-center justify-center"
                  >
                    <div className="flex flex-col items-center p-8">
                      <LuBuilding2 className="size-12" />
                      <b>{Name}</b>
                    </div>
                  </NavLink>
                  <PrivateWrapper
                    roleRequired={HasuraPermissions.DELETE_BUILDING}
                  >
                    <StyledButton
                      id={`remove-${Name}`}
                      data-test-id={`remove-${Name}`}
                      onClick={() => {
                        setBuildingToRemove(building);
                      }}
                      className="w-max absolute right-4 bottom-4"
                      title={intl.formatMessage({
                        id: 'Remove',
                      })}
                    >
                      <LuTrash className="size-5" />
                    </StyledButton>
                  </PrivateWrapper>
                </Card>
              }
            >
              <FormattedMessage id="Initial: Select building" />
            </HelpTooltip>
          );
        })}
        <PrivateWrapper roleRequired={HasuraPermissions.WRITE_BUILDING}>
          {(buildings?.Buildings ?? []).length > 0 ? (
            <Card
              noPadding
              className="sm:w-72 h-48 w-full hover:shadow-lg cursor-pointer border-dashed border-2 border-neutral-200 hover:border-transparent"
            >
              <Button
                onClick={() => {
                  setIsAdding(true);
                  setInitialSetupSteps('clickedBuilding');
                }}
                className="h-full w-full flex items-center justify-center"
                data-test-id="add-building"
              >
                <div className="flex flex-col items-center p-8 space-y-4 relative">
                  <div className="relative">
                    <LuBuilding2 className="size-12 text-neutral-500 dark:text-white" />
                    <LuPlus className="absolute right-0 top-8 rounded-xl size-5 text-neutral-500 dark:text-neutral-200 shadow-sm bg-white dark:bg-neutral-800" />
                  </div>
                  <div>
                    <FormattedMessage id="Add building" />
                  </div>
                </div>
              </Button>
            </Card>
          ) : (
            <NoContent
              loading={loadingOrga}
              clickAction={() => setIsAdding(true)}
              type="building"
            />
          )}
        </PrivateWrapper>
      </div>

      <RemoveBuildingModal
        buildingToRemove={buildingToRemove}
        setBuildingToRemove={setBuildingToRemove}
      />

      <AddBuildingModal isAdding={isAdding} setIsAdding={setIsAdding} />
    </>
  );
}
