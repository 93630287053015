import { ClimateType, ModuleType, OtherType } from '@/common/types';
import {
  LuDroplets,
  LuExpand,
  LuMaximize,
  LuSignal,
  LuSprayCan,
  LuSun,
  LuThermometer,
  LuVolume2,
  LuWaves,
} from 'react-icons/lu';

const renderSensorIcon = (
  type: string,
  iconClass = 'size-5 m-2 text-neutral-700 dark:text-neutral-200',
) => {
  if (type === ClimateType.HUMIDITY) {
    return (
      <LuDroplets className={iconClass} data-test-id="humidity-sensor-icon" />
    );
  }
  if (type === ClimateType.TEMPERATURE) {
    return (
      <LuThermometer
        className={iconClass}
        data-test-id="temperature-sensor-icon"
      />
    );
  }
  if (type === ClimateType.TVOC) {
    return <LuSprayCan className={iconClass} data-test-id="tvoc-sensor-icon" />;
  }
  if (type === ClimateType.CO2EQ) {
    return <LuWaves className={iconClass} data-test-id="co2eq-sensor-icon" />;
  }
  if (type === ClimateType.CO2) {
    return <LuWaves className={iconClass} data-test-id="co2-sensor-icon" />;
  }
  if (type === OtherType.BRIGHTNESS) {
    return (
      <LuSun className={iconClass} data-test-id="brightness-sensor-icon" />
    );
  }
  if (type === ClimateType.AUDIO) {
    return <LuVolume2 className={iconClass} data-test-id="audio-sensor-icon" />;
  }
  if (type === ModuleType.AREACOUNT) {
    return <LuExpand className={iconClass} data-test-id="area-sensor-icon" />;
  }
  if (
    ModuleType.LINECOUNT === type ||
    ModuleType.LINECOUNT_IN === type ||
    ModuleType.LINECOUNT_OUT === type
  ) {
    return <LuMaximize className={iconClass} data-test-id="line-sensor-icon" />;
  }
  return <LuSignal className={iconClass} data-test-id="other-sensor-icon" />;
};

export default renderSensorIcon;
