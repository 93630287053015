import { type FloorRoomMapFeatures, GeometryType } from 'common/types';
import { useUpdateGeometriesMutation } from 'graphql/types';
import { LuLoaderCircle, LuSave } from 'react-icons/lu';
import { FormattedMessage, useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import useToast from 'utils/graphql/useToast';
import { getBeacons, getDesks, getRooms } from '../../../../utils/helpers';
import CanvasButton from '../../../CanvasButton';

interface SetGeometryButtonProps {
  features: FloorRoomMapFeatures[];
  onGeometryClose: () => void;
  setIsSaving: (isSaving: boolean) => void;
}

export default function SetGeometryButton({
  features,
  onGeometryClose,
  setIsSaving,
}: SetGeometryButtonProps) {
  const [{ fetching }, updateGeometries] = useUpdateGeometriesMutation();
  const hasuraHeader = useHasuraHeader();
  const toast = useToast();
  const intl = useIntl();

  const beacons = getBeacons(features);
  const desks = getDesks(features);
  const rooms = getRooms(features);

  return (
    <CanvasButton
      tooltip={<FormattedMessage id="Save" />}
      data-test-id="save-geometry-button"
      onClick={() => {
        setIsSaving(true);
        updateGeometries(
          {
            MqttBeacons:
              beacons
                .map((b) => b.getProperties())
                .map((mb) => ({
                  where: { Id: { _eq: mb.Id } },
                  _set: {
                    Geometry: {
                      type: GeometryType.POINT,
                      coordinates: beacons
                        .find((f) => f.getProperties().Id === mb.Id)
                        ?.getGeometry()
                        ?.getCoordinates() ?? [0, 0],
                    },
                  },
                })) ?? [],
            Desks:
              desks
                .map((d) => d.getProperties())
                .map((d) => ({
                  where: { Id: { _eq: d.Id } },
                  _set: {
                    Geometry: {
                      type: GeometryType.POINT,
                      coordinates: desks
                        .find((f) => f.getProperties().Id === d.Id)
                        ?.getGeometry()
                        ?.getCoordinates() ?? [0, 0],
                    },
                  },
                })) ?? [],
            Rooms:
              rooms
                .map((r) => r.getProperties())
                .map((r) => ({
                  where: { Id: { _eq: r.Id } },
                  _set: {
                    Geometry: {
                      type: GeometryType.POLYGON,
                      coordinates: rooms
                        .find((f) => f.getProperties().Id === r.Id)
                        ?.getGeometry()
                        ?.getCoordinates() ?? [[[0, 0]]],
                    },
                  },
                })) ?? [],
          },
          hasuraHeader(HasuraPermissions.WRITE_DESK),
        )
          .then((data) => {
            toast(data, {
              message: {
                type: 'success',
                content: intl.formatMessage({
                  id: 'Updated geometries',
                }),
              },
            });
          })
          .finally(() => {
            setIsSaving(false);
            onGeometryClose();
          });
      }}
    >
      {fetching ? (
        <LuLoaderCircle className="animate-spin size-5 text-neutral-200" />
      ) : (
        <LuSave className="size-5" />
      )}
    </CanvasButton>
  );
}
