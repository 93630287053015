import PrivateWrapper from '@/generic/components/PrivateWrapper';
import Loader from '@/generic/components/layout/BarLoader';
import { useKeycloakUsersQuery } from '@/graphql/types';
import useStore from '@/model/store';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import type { ColumnDef, Row } from '@tanstack/react-table';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import Table, { ACTION_ID } from 'generic/components/Table/Table';
import Tooltip from 'generic/components/Tooltip';
import { useMemo, useState } from 'react';
import { LuCirclePlus, LuFile, LuTrash, LuUser, LuUsers } from 'react-icons/lu';
import { FormattedMessage, useIntl } from 'translations/Intl';
import { formattedDistance } from 'utils/date';
import AddUserModal, {
  type KeycloakTableUser,
} from './components/AddUserModal/AddUserModal';
import { ActionCell, NameCell } from './components/Cells';
import ConfirmationModal from './components/ConfirmationModal';
import RemoveUserModal from './components/RemoveUserModal';
import TermsModal from './components/TermsModal';
import UpdateUserGroupModal from './components/UpdateUserGroupModal';

export default function UsersTable() {
  const hasuraHeader = useHasuraHeader();
  const [disabled, setDisabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState<
    KeycloakTableUser | undefined
  >(undefined);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isSettingRequiredAction, setIsSettingRequiredAction] = useState(false);
  const [openUserGroupModal, setOpenUserGroupModal] = useState(false);
  const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const intl = useIntl();
  const user = useStore((state) => state.user);

  const [selectedUsers, setSelectedUsers] = useState<
    Row<KeycloakTableUser>[] | undefined
  >(undefined);

  const [{ data: users, fetching: loadingUsers }] = useKeycloakUsersQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_USERGROUP, ['GroupMembers']),
      [hasuraHeader],
    ),
  });

  const defaultColumns: ColumnDef<KeycloakTableUser>[] = useMemo(
    () => [
      {
        accessorKey: 'username',
        id: 'username',
        header: intl.formatMessage({ id: 'Email' }),
        cell: NameCell,
      },
      {
        accessorFn: (row) =>
          row.enabled
            ? intl.formatMessage({ id: 'Enabled' })
            : intl.formatMessage({ id: 'Disabled' }),
        id: 'enabled',
        header: intl.formatMessage({ id: 'Enabled' }),
      },
      {
        accessorKey: 'groups',
        accessorFn: (row) => row.groups?.map((g) => g?.name).toString(),
        id: 'groups',
        header: intl.formatMessage({ id: 'User groups' }),
      },
      {
        id: 'lastLoginTime',
        header: intl.formatMessage({ id: 'Last login' }),
        enableColumnFilter: false,
        accessorFn: (row) =>
          row.attributes?.lastLoginTime?.[0]
            ? new Date(
                Number.parseInt(row.attributes.lastLoginTime[0], 10) * 1000,
              )
            : undefined,
        cell: ({ row }) =>
          row.original.attributes?.lastLoginTime?.[0]
            ? formattedDistance(
                new Date(
                  Number.parseInt(
                    row.original.attributes.lastLoginTime[0],
                    10,
                  ) * 1000,
                ),
              )
            : undefined,
      },
      { accessorKey: 'id', id: 'id', header: intl.formatMessage({ id: 'id' }) },
      {
        id: 'createdAt',
        header: intl.formatMessage({ id: 'Created at' }),
        enableColumnFilter: false,
        accessorFn: (row) =>
          row.createdTimestamp ? new Date(row.createdTimestamp) : undefined,
        cell: ({ row }) =>
          row.original.createdTimestamp
            ? formattedDistance(new Date(row.original.createdTimestamp))
            : undefined,
      },
      {
        enableGrouping: false,
        id: ACTION_ID,
        cell: ({ row }) => (
          <ActionCell
            row={row}
            setDisabled={setDisabled}
            setSelectedUser={setSelectedUser}
            setSelectedUsers={setSelectedUsers}
            setOpenConfirmationModal={setOpenConfirmationModal}
            setOpenDeleteUserModal={setOpenDeleteUserModal}
          />
        ),
      },
    ],
    [intl.formatMessage],
  );

  const members = useMemo(
    () =>
      users?.GetUsers.groupMembers
        ? [...users.GetUsers.groupMembers]
            .sort((a, b) => a.username.localeCompare(b.username))
            .map((u) => ({ ...u, disabled: u.email === user?.email }))
        : [],
    [user?.email, users, users?.GetUsers.groupMembers],
  );

  return (
    <>
      <Loader loading={loadingUsers} />
      <Table<KeycloakTableUser>
        id="users"
        columns={defaultColumns}
        data={members}
        getRowId={(row) => row.id}
        loading={loadingUsers}
        enabledFeatures={{
          enableRowSelection: true,
        }}
        initialState={{
          columnVisibility: { enabled: false },
        }}
        renderSelectedAction={(selectedRows) => [
          {
            onClick: () => {
              setDisabled(true);
              setOpenConfirmationModal(true);
              setSelectedUsers(selectedRows);
            },
            text: intl.formatMessage({ id: 'Disable selected users' }),
            dataTestId: 'disable-user-action',
            icon: <LuUser className="size-5 text-neutral-400" />,
            permission: HasuraPermissions.VIEW_USERGROUP,
          },
          {
            onClick: () => {
              setDisabled(false);
              setOpenConfirmationModal(true);
              setSelectedUsers(selectedRows);
            },
            text: intl.formatMessage({ id: 'Enable selected users' }),
            dataTestId: 'enable-user-action',
            icon: <LuUser className="size-5" />,
            permission: HasuraPermissions.VIEW_USERGROUP,
          },
          {
            onClick: () => {
              setOpenDeleteUserModal(true);
              setSelectedUsers(selectedRows);
            },
            text: intl.formatMessage({ id: 'Delete selected users' }),
            dataTestId: 'delete-user-action',
            icon: <LuTrash className="size-5" />,
            permission: HasuraPermissions.VIEW_USERGROUP,
          },
          {
            onClick: () => {
              setSelectedUsers(selectedRows);
              setOpenUserGroupModal(true);
            },
            text: intl.formatMessage({
              id: 'Add/remove groups from selected users',
            }),
            dataTestId: 'addgroup-user-action',
            icon: <LuUsers className="size-5" />,
            permission: HasuraPermissions.VIEW_USERGROUP,
          },
        ]}
        renderAdditionalAction={
          <div className="flex space-x-2">
            <PrivateWrapper roleRequired={HasuraPermissions.READ_ALL}>
              <Tooltip
                content={
                  <StyledButton
                    onClick={() => setIsSettingRequiredAction(true)}
                  >
                    <LuFile className="size-5" />
                  </StyledButton>
                }
              >
                <FormattedMessage id="Update terms" />
              </Tooltip>
            </PrivateWrapper>
            <PrivateWrapper roleRequired={HasuraPermissions.VIEW_USERGROUP}>
              <Tooltip
                content={
                  <StyledButton onClick={() => setIsAddingUser(true)}>
                    <LuCirclePlus data-test-id="add-user" className="size-5" />
                  </StyledButton>
                }
              >
                <FormattedMessage id="Add user" />
              </Tooltip>
            </PrivateWrapper>
          </div>
        }
      />

      <PrivateWrapper roleRequired={HasuraPermissions.VIEW_USERGROUP}>
        <RemoveUserModal
          open={openDeleteUserModal}
          setOpen={setOpenDeleteUserModal}
          users={selectedUsers ?? []}
        />
      </PrivateWrapper>

      <PrivateWrapper roleRequired={HasuraPermissions.VIEW_USERGROUP}>
        <UpdateUserGroupModal
          open={openUserGroupModal}
          setOpen={setOpenUserGroupModal}
          users={selectedUsers ?? []}
        />
      </PrivateWrapper>

      <PrivateWrapper roleRequired={HasuraPermissions.VIEW_USERGROUP}>
        <ConfirmationModal
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          disabled={disabled}
          users={selectedUsers ?? []}
        />
      </PrivateWrapper>

      <AddUserModal
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        isAddingUser={isAddingUser}
        setIsAddingUser={setIsAddingUser}
      />
      <TermsModal
        open={isSettingRequiredAction}
        setOpen={setIsSettingRequiredAction}
      />
    </>
  );
}
