import { motion } from 'motion/react';
import BackgroundAnimation from '../../BackgroundAnimation';
import Logo from '../Logo';

export default function LoadingScreen(): React.JSX.Element {
  return (
    <div className="flex flex-col space-y-4 h-screen min-h-screen w-screen justify-center items-center bg-white dark:bg-neutral-800 text-neutral-700 dark:text-white relative">
      <BackgroundAnimation />
      <Logo />
      <div className="h-1 w-1/6 rounded-sm bg-neutral-300">
        <motion.div
          initial={{ width: 0 }}
          animate={{
            width: '100%',
            transition: {
              duration: 2,
              repeat: Number.POSITIVE_INFINITY,
              repeatType: 'loop',
              repeatDelay: 1,
            },
          }}
          className="rounded-sm bg-linear-to-r from-primary-500 to-primary-300 h-full"
        />
      </div>
    </div>
  );
}
