import useStore from 'model/store';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';
import ReportingPage from '../ReportingPage';

function GlossaryItem({
  title,
  description,
  values,
}: {
  title: IntlMessageKeys;
  description: IntlMessageKeys;
  values?: Record<string, React.ReactNode>;
}) {
  return (
    <div className="grid grid-cols-8 py-2 gap-x-2">
      <dt className="col-span-2 font-semibold">
        <FormattedMessage id={title} />
      </dt>
      <dd className="col-span-6">
        <FormattedMessage id={description} values={values} />
      </dd>
    </div>
  );
}

export default function ReportDescription() {
  const organizationName = useStore(
    (state) => state.organizationSettings.organizationName,
  );

  return (
    <ReportingPage key={organizationName} reportTitle="Glossary">
      <dl className="divide-y divide-neutral-200 dark:text-white dark:divide-neutral-700">
        <GlossaryItem title="Mode" description="mode-description" />
        <GlossaryItem
          title="Occupancy"
          description="occupancy-daily-description"
          values={{
            here: <FormattedMessage id="in the organization settings" />,
          }}
        />
        <GlossaryItem title="Usage" description="usage-description" />
        <GlossaryItem
          title="Usage vs. Occupancy"
          description="usage-occupancy-description"
        />
        <GlossaryItem title="hot" description="hot-description" />
        <GlossaryItem
          title="warm"
          description="warm-description"
          values={{
            here: <FormattedMessage id="in the organization settings" />,
          }}
        />
        <GlossaryItem title="Flex desks" description="flex-desks-description" />
        <GlossaryItem title="Fix desks" description="fix-desks-description" />
        <GlossaryItem
          title="Max Hourly Occupancy"
          description="max-hourly-description"
        />
        <GlossaryItem
          title="Max Daily Occupancy"
          description="max-daily-description"
        />
      </dl>
    </ReportingPage>
  );
}
