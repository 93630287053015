import luLockKeyhole from '@/img/lu_lock_keyhole.svg';
import luShieldUser from '@/img/lu_shield_user.svg';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import { getCenter } from 'ol/extent';
import type Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import type RenderFeature from 'ol/render/Feature';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';

const getScale = (isPersonal: boolean, map?: OLMap) => {
  const scale = map ? Math.abs(map.getView().getZoom() ?? 1) : 1;
  return scale * (isPersonal ? 0.35 : 0.4);
};

const renderReservedIcon = (
  feature: Feature<Geometry> | RenderFeature,
  isReserved: boolean,
  map?: OLMap,
  isPersonal = false,
): Style => {
  let extraStyle = new Style();
  if (isReserved) {
    const extent = feature.getGeometry()?.getExtent();
    if (extent) {
      const position = new Point(getCenter(extent));
      extraStyle = new Style({
        geometry: position,
        image: new Icon({
          src: isPersonal ? luShieldUser : luLockKeyhole,
          scale: getScale(isPersonal, map),
          anchor: [0.5, 12.5],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
        }),
      });
    }
  }
  return extraStyle;
};

export default renderReservedIcon;
