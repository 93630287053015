import {
  INTERSECTED_PARAM,
  LS_DESK_RADIUS,
  LS_DESK_ROTATION,
} from '@/constants';
import convex from '@turf/convex';
import {
  featureCollection,
  point as turfPoint,
  polygon as turfPolygon,
} from '@turf/helpers';
import intersect from '@turf/intersect';
import {
  type BeaconFeatures,
  Colors,
  type DeskFeatures,
  type DrawingDesk,
  type DrawingDeskFeatures,
  type FloorRoomMapFeatures,
  type FloorStoredOffsets,
  GeometryType,
  ModuleType,
  type NumberArray2,
  OtherType,
  type RoomFeatures,
  RoomTypes,
  type Sensor,
  type SmartModuleFeatures,
  type SnapFeatures,
  type SnapType,
  isNonNullable,
} from 'common/types';
import { isAfter, subMinutes } from 'date-fns';
import type { DeskInUseFeatureType } from 'generic/layers/DeskInUseLayer';
import { isPoint, isPolygon } from 'generic/layers/GeometryLayer';
import TypedFeature from 'generic/layers/TypedFeature';
import type VectorLayer from 'generic/layers/VectorLayer';
import type { FloorMapFeaturesQuery } from 'graphql/types';
import { easeOut } from 'motion/react';
import Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import type { Color } from 'ol/color';
import type { Coordinate } from 'ol/coordinate';
import GeoJSON from 'ol/format/GeoJSON';
import { type Geometry, LineString, Polygon } from 'ol/geom';
import Point from 'ol/geom/Point';
import { defaults } from 'ol/interaction/defaults';
import type OLVectorLayer from 'ol/layer/Vector';
import { getVectorContext } from 'ol/render';
import type RenderEvent from 'ol/render/Event';
import type VectorSource from 'ol/source/Vector';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import getColor, { convertColorToRGB } from 'utils/getColor';
import {
  getDeskAtIndex,
  getDeskFeatureIndex,
  getDeskFeatureRotation,
  getDestination,
  getDistance,
  getMiddlePoint,
  isDrawingDeskFeature,
} from '../interactions/moveDesk';
import {
  ROOM_OUTLINE_FEATURE_NAME,
  beaconLayerName,
  beaconModuleLayerName,
  deskOutlineLayer,
  deskOutlineSource,
  desksLayer,
  dragBox,
  drawingBeaconLayerName,
  drawingDeskLayerName,
  drawingDesksLayer,
  roomLayer,
  roomOutlineLayer,
  roomOutlineSource,
  rotateAnchorLayer,
  rotateAnchorLayerName,
  rotateAnchorSource,
} from '../mapElements';
import getDeskGeom from './getDeskGeom';

// http://paulbourke.net/geometry/pointlineplane/javascript.txt
export const getIntersectPt = (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number,
) => {
  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return false;
  }

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  // Lines are parallel
  if (denominator === 0) {
    return false;
  }

  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  const ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false;
  }

  // Return a object with the x and y coordinates of the intersection
  const x = x1 + ua * (x2 - x1);
  const y = y1 + ua * (y2 - y1);

  return [x, y];
};

export type Line = [NumberArray2, NumberArray2];
export const findSnapLines = (
  lines: [Line, Line, Line, Line],
  beaconTransVertRotationX: number,
  beaconTransVertRotationY: number,
  beaconTransVertOppositeX: number,
  beaconTransVertOppositeY: number,
) =>
  lines
    .map((line) =>
      getIntersectPt(
        line[0][0],
        line[0][1],
        line[1][0],
        line[1][1],
        beaconTransVertRotationX,
        beaconTransVertRotationY,
        beaconTransVertOppositeX,
        beaconTransVertOppositeY,
      ),
    )
    .filter((l) => l !== false) as number[][];

export const coordinatesEquals = (a: number[], b: number[]): boolean =>
  Array.isArray(a) &&
  Array.isArray(b) &&
  a.length === b.length &&
  a.every((val, index) => val === b[index]);

export const getInitialDesksFeats = (
  sensors: FloorMapFeaturesQuery['MqttBeacons'][number]['Sensors'] | Sensor[],
  length: number,
  floorId: number,
  isNew: (
    sensor:
      | FloorMapFeaturesQuery['MqttBeacons'][number]['Sensors'][number]
      | Sensor,
  ) => boolean,
  fallbackRadius = 10,
  fallbackOffsets?: FloorStoredOffsets,
  newGeom?: Point,
  forceRotationReset = false,
  isVisible?: (sensorId: number) => boolean,
) => {
  const localstorageRadius = localStorage.getItem(
    `${LS_DESK_RADIUS}-${floorId}`,
  );
  return newGeom
    ? sensors.map(
        (s) =>
          new TypedFeature<DrawingDesk, Point>({
            geometry:
              getDeskGeom(
                s.Index,
                length,
                newGeom,
                floorId,
                fallbackOffsets,
                forceRotationReset,
              ) ?? new Point([0, 0]),
            DeskId: s.Desk?.Id ?? 0,
            Id: s.Id,
            Index: s.Index,
            visible: isVisible ? isVisible(s.Id) : true,
            Radius:
              typeof localstorageRadius === 'number'
                ? Number.parseFloat(localstorageRadius)
                : fallbackRadius,
            IsNew: isNew(s),
            __typename: 'DrawingDesks',
          }),
      )
    : [];
};

export interface FallbackOffsets {
  0?: Coordinate[];
  1?: Coordinate[];
  2?: Coordinate[];
  3?: Coordinate[];
  4?: Coordinate[];
}

export const coordinatesMode = (array: Coordinate[]) => {
  if (array.length === 0) return undefined;
  const modeMap = {} as {
    [key: string]: number;
  };
  let maxEl = array[0];
  let maxCount = 1;
  for (let i = 0; i < array.length; i += 1) {
    const el = (array[i] || []).join('');
    if (!modeMap[el]) {
      modeMap[el] = 1;
    } else {
      modeMap[el] += 1;
    }
    if ((modeMap[el] ?? 0) > maxCount) {
      maxEl = array[i];
      if (typeof modeMap[el] === 'number') {
        maxCount = modeMap[el] as number;
      }
    }
  }
  return maxEl;
};

export function isDesk(feature: FloorRoomMapFeatures): feature is DeskFeatures {
  return (feature as DeskFeatures).getProperties().__typename === 'Desks';
}

export function isRoom(feature: FloorRoomMapFeatures): feature is RoomFeatures {
  return (feature as RoomFeatures).getProperties().__typename === 'Rooms';
}

export function isSnap(feature: FloorRoomMapFeatures): feature is SnapFeatures {
  return !!(feature as SnapFeatures).getProperties().snapIdentifier;
}

export function isBeacon(
  feature: FloorRoomMapFeatures,
): feature is BeaconFeatures {
  return (
    (feature as BeaconFeatures).getProperties().__typename === 'MqttBeacons'
  );
}

export function getBeacons(features: FloorRoomMapFeatures[]) {
  return features.filter(isBeacon);
}

export function getDesks(features: FloorRoomMapFeatures[]) {
  return features.filter(isDesk);
}

export function getRooms(features: FloorRoomMapFeatures[]) {
  return features.filter(isRoom);
}

export const hasModuleSensors = (
  beaconFeat: BeaconFeatures | SmartModuleFeatures | null | undefined,
) =>
  beaconFeat
    ?.getProperties()
    .Sensors.find((s) =>
      (Object.values(ModuleType) as string[]).includes(s.SensorType.Name),
    );

export const hasDeskInUseSensors = (
  beaconFeat: BeaconFeatures | SmartModuleFeatures | null | undefined,
) =>
  beaconFeat
    ?.getProperties()
    .Sensors.find((s) => s.SensorType.Name === OtherType.DESKINUSE);

export function isDeskBeacon(
  feature?: BeaconFeatures | SmartModuleFeatures,
): feature is BeaconFeatures {
  return !!(!hasModuleSensors(feature) && hasDeskInUseSensors(feature));
}

export function isModuleBeacon(
  feature?: BeaconFeatures | SmartModuleFeatures,
): feature is SmartModuleFeatures {
  return !!(hasModuleSensors(feature) && !hasDeskInUseSensors(feature));
}

export function isSmartModuleBeacon(
  feature?: FloorRoomMapFeatures,
): feature is SmartModuleFeatures {
  return !!(
    feature &&
    isBeacon(feature) &&
    'RoomSensors' in (feature.getProperties().Sensors[0] || {})
  );
}

export const getCursor = (layers: VectorLayer<Geometry>[]) => {
  const layerNames = layers.map((l) => l.name);
  // grab icon for Lighticon, selected beacon or rotate icon
  if (
    layerNames.includes(rotateAnchorLayerName) ||
    layerNames.includes(drawingBeaconLayerName) ||
    layerNames.includes(drawingDeskLayerName) ||
    ((layerNames.includes(beaconLayerName) ||
      layerNames.includes(beaconModuleLayerName)) &&
      layers.find(
        (l) =>
          l.name && [beaconModuleLayerName, beaconLayerName].includes(l.name),
      )?.selected)
  ) {
    return 'grab';
  }
  return 'pointer';
};

export const resetMapInteractions = (map: OLMap) => {
  const defaultInteractions = [...defaults().getArray(), dragBox];
  map.getInteractions().clear();
  for (const interaction of defaultInteractions) {
    map.addInteraction(interaction);
  }
};

export const resetFeatureGeometry = (feature: Feature<Geometry>) => {
  const geometry = feature.getGeometry();
  if (geometry) {
    if (isPolygon(geometry) || isPoint(geometry)) {
      geometry.setCoordinates(
        // Get coordinate from object as these are the ones before the geometry has been transformed
        feature.getProperties().Geometry.coordinates as Coordinate[][],
      );
    }
  }
};

const pointToPolygon = (pointGeometry: Point) => {
  const [x = 0, y = 0] = pointGeometry.getCoordinates();
  const buff = 0.5;
  const bufferedPoly = [
    [
      [x - buff, y + buff],
      [x + buff, y + buff],
      [x + buff, y - buff],
      [x - buff, y - buff],
      [x - buff, y + buff],
    ],
  ];

  return new Polygon(bufferedPoly);
};

export const getIntersectedElements = <T extends Feature<Geometry>>(
  intersectedElementGeometry: Geometry,
  features: Feature<Geometry>[],
): T[] => {
  const format = new GeoJSON();

  return features.filter((f) => {
    const geometry = f.getGeometry();

    if (!geometry) {
      return false;
    }

    if (isPoint(geometry)) {
      return intersect(
        featureCollection([
          format.writeFeatureObject(
            new Feature({
              geometry: isPoint(intersectedElementGeometry)
                ? pointToPolygon(intersectedElementGeometry)
                : intersectedElementGeometry,
            }),
          ),
          format.writeFeatureObject(
            new Feature({
              geometry: pointToPolygon(geometry),
            }),
          ),
        ]),
      );
    }

    return intersect(
      featureCollection([
        format.writeFeatureObject(
          new Feature({
            geometry: isPoint(intersectedElementGeometry)
              ? pointToPolygon(intersectedElementGeometry)
              : intersectedElementGeometry,
          }),
        ),
        format.writeFeatureObject(f),
      ]),
    );
  }) as T[];
};

export const getInitialStoredRotation = (floorId: number) => {
  const localStorageValue = localStorage.getItem(
    `${LS_DESK_ROTATION}-${floorId}`,
  );
  if (
    localStorageValue &&
    localStorageValue !== 'undefined' &&
    !Number.isNaN(Number.parseFloat(localStorageValue))
  ) {
    return Number.parseFloat(localStorageValue);
  }
  return 0;
};

export const getIntersectedRooms = (
  geometry: Point,
  feature: Feature<Point>,
) => {
  const features = (
    ((roomLayer.olLayer.getSource() as VectorSource)?.getFeatures() ??
      []) as RoomFeatures[]
  ).filter((room) =>
    // Filter room type based on beacon type
    [
      hasDeskInUseSensors(feature as BeaconFeatures)
        ? RoomTypes.DESKS
        : RoomTypes.MEETING,
    ].includes(room.getProperties().RoomType.Name as RoomTypes),
  );

  return getIntersectedElements<RoomFeatures>(geometry, features);
};

export const move = (point: number[], rotation: number, buffer: number) => {
  const x = point[0] ?? 0;
  const y = point[1] ?? 0;
  const rad = (rotation * Math.PI) / 180;
  const newX = x + buffer * Math.sin(rad);
  const newY = y + buffer * Math.cos(rad);
  return [newX, newY];
};

export const getDeskOutline = (
  beaconCoords: Coordinate,
  deskFeatures: (DrawingDeskFeatures | DeskFeatures)[],
  buffer = 5,
): Coordinate[] => {
  const outlinePoints: Coordinate[] = [];
  const desksCoords = deskFeatures
    .sort(
      (a, b) =>
        (isDrawingDeskFeature(b) ? (b.getProperties().Index ?? 0) : 0) -
        (isDrawingDeskFeature(a) ? (a.getProperties().Index ?? 0) : 0),
    )
    .map((d) => d.getGeometry()?.clone()?.getCoordinates())
    .filter((c) => c);
  const [beaconX, beaconY] = beaconCoords || [];
  if (
    beaconCoords &&
    desksCoords.length &&
    typeof beaconX === 'number' &&
    typeof beaconY === 'number' &&
    typeof beaconX === 'number' &&
    typeof beaconY === 'number' &&
    deskFeatures[0]
  ) {
    const radiusDistance = deskFeatures[0].getProperties().Radius || buffer;
    const rotation =
      90 - (getDeskFeatureRotation(beaconCoords, deskFeatures) ?? 0);
    if (typeof rotation === 'number') {
      if (deskFeatures.length === 1) {
        const [deskCoords] = desksCoords;
        const [deskCoordX, deskCoordY] = deskCoords || [];
        if (
          deskCoords &&
          typeof deskCoordX === 'number' &&
          typeof deskCoordY === 'number'
        ) {
          const deskDistance = getDistance(
            [beaconX, beaconY],
            [deskCoordX, deskCoordY],
          );
          // When radius is too big, we should take into account for desk idx 3 & 4
          const distance =
            deskDistance +
            radiusDistance +
            buffer +
            (radiusDistance > deskDistance
              ? radiusDistance - deskDistance + buffer
              : 0);
          // First translate the desk position to set the outer limit outside of the desk radius
          const translatedDeskCoords = move(
            deskCoords,
            rotation,
            radiusDistance + buffer,
          );

          // Create two first outline points on the desk side
          const outlinePt1 = move(
            translatedDeskCoords,
            rotation - 90,
            radiusDistance + buffer,
          );
          outlinePoints.push(outlinePt1);

          const outlinePt2 = move(
            translatedDeskCoords,
            rotation + 90,
            radiusDistance + buffer,
          );
          outlinePoints.push(outlinePt2);

          // Mirror the two outline points on the beacon direction to have a rectangle
          const outlinePt3 = move(outlinePt1, rotation - 180, distance);
          outlinePoints.push(outlinePt3);

          const outlinePt4 = move(outlinePt2, rotation - 180, distance);
          outlinePoints.push(outlinePt4);
        }
      } else {
        const middlePt = getMiddlePoint(
          getDeskAtIndex(1, deskFeatures),
          getDeskAtIndex(2, deskFeatures),
        );
        const [middlePtX, middlePtY] = middlePt || [];
        if (typeof middlePtX === 'number' && typeof middlePtY === 'number') {
          for (const idx of [1, 2]) {
            const featCoordsIdx = deskFeatures
              .find((d) => getDeskFeatureIndex(d) === idx)
              ?.getGeometry()
              ?.getCoordinates() as Coordinate;
            const outlinePoint = move(
              featCoordsIdx,
              rotation,
              radiusDistance + buffer,
            );

            const outlinePointFinal = move(
              outlinePoint,
              idx === 1 ? rotation + 90 : rotation - 90,
              radiusDistance + buffer,
            );
            outlinePoints.push(outlinePointFinal);
          }
          const middlePtDistance = getDistance(
            [beaconX, beaconY],
            [middlePtX, middlePtY],
          );
          if (deskFeatures.length === 2) {
            const distance =
              middlePtDistance +
              radiusDistance +
              buffer +
              (radiusDistance > middlePtDistance
                ? radiusDistance - middlePtDistance + buffer
                : 0);
            for (const pt of [...outlinePoints]) {
              const outlinePt = move(pt, rotation - 180, distance);
              outlinePoints.push(outlinePt);
            }
          }
          if (deskFeatures.length === 3) {
            const thirdIndexCoords = deskFeatures
              .find((d) => getDeskFeatureIndex(d) === 3)
              ?.getGeometry()
              ?.getCoordinates();
            const [thirdIndexCoordsX, thirdIndexCoordsY] =
              thirdIndexCoords || [];
            if (
              thirdIndexCoords &&
              typeof thirdIndexCoordsX === 'number' &&
              typeof thirdIndexCoordsY === 'number'
            ) {
              const thirdIndexDistance =
                middlePtDistance +
                2 * buffer +
                2 * radiusDistance +
                getDistance(
                  [beaconX, beaconY],
                  [thirdIndexCoordsX, thirdIndexCoordsY],
                );
              for (const pt of [...outlinePoints]) {
                const newOutlinePt = move(
                  pt,
                  rotation - 180,
                  thirdIndexDistance,
                );
                outlinePoints.push(newOutlinePt);
              }
            }
          }
          if (deskFeatures.length === 4) {
            for (const pt of [...outlinePoints]) {
              // Add the same points on the other side
              const newOutlinePt = move(
                pt,
                rotation - 180,
                2 * (middlePtDistance + radiusDistance + buffer),
              );
              outlinePoints.push(newOutlinePt);
            }
          }
        }
      }
    }
  }

  return outlinePoints;
};

export const createRoomOutlineFeats = (roomFeats: Feature<Polygon>[]) => {
  roomOutlineSource.clear();

  const convexHull = convex(
    featureCollection(
      roomFeats.map((f) =>
        turfPolygon(f.getGeometry()?.getCoordinates() ?? []),
      ),
    ),
  );

  const outlineFeature = new Feature({
    geometry: convexHull
      ? new Polygon(convexHull.geometry.coordinates)
      : undefined,
    name: ROOM_OUTLINE_FEATURE_NAME,
  });
  roomOutlineSource.addFeatures([outlineFeature]);
  roomOutlineLayer.olLayer.changed();
};

export const getCoordinates = (feature: Feature<Point>) =>
  feature.getGeometry()?.clone().getCoordinates();

export const getOutlinedBeacons = (
  beacons: FloorMapFeaturesQuery['MqttBeacons'],
  desks: DeskFeatures[],
) => {
  return beacons
    .map((b) => {
      const deskFeats = desks.filter(
        (d) => d.getProperties().Sensor.MqttBeacon.Id === b.Id,
      );

      const polyPoints = getDeskOutline(
        b.Geometry.coordinates as Coordinate,
        deskFeats,
        10,
      );

      const convexHull = convex(
        featureCollection(polyPoints.map((c) => turfPoint(c))),
      );

      if (!convexHull) {
        console.error("Couldn't create convex hull");
        return;
      }

      return {
        Geometry: {
          type: GeometryType.POLYGON,
          coordinates: new Polygon(
            convexHull.geometry.coordinates,
          ).getCoordinates(),
        },
      };
    })
    .filter(isNonNullable);
};

export const createDeskOutlineFeats = (
  beaconFeat: Feature<Point>,
  deskFeats: (DrawingDeskFeatures | DeskFeatures)[],
) => {
  rotateAnchorSource.clear();
  deskOutlineSource.clear();
  // Create a feature that contains all desks/beacon selected.
  // Use this layer as source for rotation interaction.
  const polyPoints = getDeskOutline(
    beaconFeat.getGeometry()?.clone()?.getCoordinates() ?? [0, 0],
    deskFeats,
  );

  const convexHull = convex(
    featureCollection(polyPoints.map((c) => turfPoint(c))),
  );

  if (!convexHull) {
    console.error("Couldn't create convex hull");
    return;
  }

  const outlineFeature = new Feature({
    geometry: new Polygon(convexHull.geometry.coordinates),
    relatedDesks: deskFeats.map(getDeskFeatureIndex),
    relatedBeacon: beaconFeat,
  });
  deskOutlineSource.addFeatures([outlineFeature]);

  const outlineGeom = outlineFeature.getGeometry();
  const beaconCoords = beaconFeat.getGeometry()?.getCoordinates();
  if (outlineGeom && beaconCoords && deskFeats[0]) {
    const buffer = 15;
    // Find the geometry for the rotation anchor point
    const rotation =
      90 - (getDeskFeatureRotation(beaconCoords, deskFeats) ?? 0);
    const radius = deskFeats[0].getProperties().Radius || buffer;
    if (deskFeats.length === 1) {
      const deskCloneCoords = deskFeats[0]
        .getGeometry()
        ?.clone()
        .getCoordinates();
      if (deskCloneCoords && typeof rotation === 'number') {
        const newRotateAnchorCoords = move(
          deskCloneCoords,
          rotation,
          radius + buffer,
        );
        rotateAnchorSource.addFeatures([
          new Feature({
            geometry: new Point(newRotateAnchorCoords),
            outlineFeature,
          }),
        ]);
      }
    } else {
      const middlePoint = getMiddlePoint(
        getDeskAtIndex(1, deskFeats),
        getDeskAtIndex(2, deskFeats),
      );
      if (middlePoint && typeof rotation === 'number') {
        const newRotateAnchorCoords = move(
          middlePoint,
          rotation,
          radius + buffer,
        );
        rotateAnchorSource.addFeatures([
          new Feature({
            geometry: new Point(newRotateAnchorCoords),
            outlineFeature,
          }),
        ]);
      }
    }
  }

  rotateAnchorLayer.olLayer.changed();
  deskOutlineLayer.olLayer.changed();
};

export const reRenderDeskOutlineFeatures = (feature?: Feature<Point>) => {
  if (feature) {
    const drawingDeskFeats =
      (
        drawingDesksLayer.olLayer as OLVectorLayer<
          VectorSource<DrawingDeskFeatures>
        >
      )
        .getSource()
        ?.getFeatures() ?? [];
    createDeskOutlineFeats(feature, drawingDeskFeats);
  }
};

export const resetIntersectedRooms = () => {
  (roomLayer.olLayer as OLVectorLayer<VectorSource<Feature<Polygon>>>)
    .getSource()
    ?.getFeatures()
    .filter((f: Feature<Polygon>) => f.get(INTERSECTED_PARAM))
    .map((f: Feature<Polygon>) => f.unset(INTERSECTED_PARAM));
};

export const detectBeaconLeavesPrivateRoom = (
  evtFeature: FloorRoomMapFeatures,
  newIntersectedRooms: RoomFeatures[] | null,
  setOpenPrivateBeaconWarning: (open: boolean) => void,
) => {
  if (isBeacon(evtFeature)) {
    const isPrivate = evtFeature
      .getProperties()
      .Sensors.some((s) => s.IsPrivate);
    if (isPrivate) {
      // If not intersecting with a private room, show warning about sensor history to be removed
      const hasIntersectedPrivateRoom = newIntersectedRooms?.some((r) =>
        r.getProperties().RoomSensors.some((rs) => rs.Sensor.IsPrivate),
      );
      if (!hasIntersectedPrivateRoom) {
        setOpenPrivateBeaconWarning(true);
      }
    }
  }
};

export const getFilteredSnapLines = (
  floorExtent: number[],
  deskData: FloorMapFeaturesQuery | undefined,
  selected?: BeaconFeatures | SmartModuleFeatures,
) => {
  if (deskData) {
    const floorMaxX = floorExtent[2];
    const floorMaxY = floorExtent[3];
    if (typeof floorMaxX === 'number' && typeof floorMaxY === 'number') {
      const linesToSnap = [...deskData.MqttBeacons, ...deskData.smartModules]
        .map((beacon) => {
          if (beacon.Id === selected?.getProperties().Id) {
            return [];
          }
          const beaconXPosition = beacon.Geometry.coordinates[0] as number;
          const beaconYPosition = beacon.Geometry.coordinates[1] as number;

          const deskFeats = (
            (
              desksLayer.olLayer.getSource() as VectorSource<Feature<Point>>
            ).getFeatures() as DeskFeatures[]
          ).filter(
            (d) => d.getProperties().Sensor?.MqttBeacon.Id === beacon.Id,
          );
          const rotation = getDeskFeatureRotation(
            [beaconXPosition, beaconYPosition],
            deskFeats,
          );

          const [beaconTransVertRotationX, beaconTransVertRotationY] =
            getDestination(
              [beaconXPosition, beaconYPosition],
              4 * floorMaxY,
              rotation ?? 0,
            );
          const [beaconTransVertOppositeX, beaconTransVertOppositeY] =
            getDestination(
              [beaconXPosition, beaconYPosition],
              4 * floorMaxY,
              (rotation ?? 0) - 180,
            );
          const [beaconTransHorizRotationX, beaconTransHorizRotationY] =
            getDestination(
              [beaconXPosition, beaconYPosition],
              4 * floorMaxY,
              (rotation ?? 0) + 90,
            );
          const [beaconTransHorizOppositeX, beaconTransHorizOppositeY] =
            getDestination(
              [beaconXPosition, beaconYPosition],
              4 * floorMaxY,
              (rotation ?? 0) - 90,
            );

          if (
            typeof beaconTransVertRotationX !== 'number' ||
            typeof beaconTransVertRotationY !== 'number' ||
            typeof beaconTransVertOppositeX !== 'number' ||
            typeof beaconTransVertOppositeY !== 'number' ||
            typeof beaconTransHorizRotationX !== 'number' ||
            typeof beaconTransHorizRotationY !== 'number' ||
            typeof beaconTransHorizOppositeX !== 'number' ||
            typeof beaconTransHorizOppositeY !== 'number'
          ) {
            return [];
          }
          const verticalSnapCoords = findSnapLines(
            [
              [
                [0, floorMaxY],
                [floorMaxX, floorMaxY],
              ],
              [
                [0, 0],
                [floorMaxX, 0],
              ],
              [
                [0, 0],
                [0, floorMaxY],
              ],
              [
                [floorMaxX, 0],
                [floorMaxX, floorMaxY],
              ],
            ],
            beaconTransVertRotationX,
            beaconTransVertRotationY,
            beaconTransVertOppositeX,
            beaconTransVertOppositeY,
          );

          const horizontalSnapCoords = findSnapLines(
            [
              [
                [0, floorMaxY],
                [floorMaxX, floorMaxY],
              ],
              [
                [0, 0],
                [floorMaxX, 0],
              ],
              [
                [0, 0],
                [0, floorMaxY],
              ],
              [
                [floorMaxX, 0],
                [floorMaxX, floorMaxY],
              ],
            ],
            beaconTransHorizRotationX,
            beaconTransHorizRotationY,
            beaconTransHorizOppositeX,
            beaconTransHorizOppositeY,
          );

          return verticalSnapCoords[0] &&
            verticalSnapCoords[1] &&
            horizontalSnapCoords[0] &&
            horizontalSnapCoords[1]
            ? [
                new TypedFeature<SnapType, LineString>({
                  beaconId: beacon.Id,
                  snapIdentifier: `${beacon.Name}-horizontal`,
                  geometry: new LineString(horizontalSnapCoords),
                }),
                new TypedFeature<SnapType, LineString>({
                  beaconId: beacon.Id,
                  snapIdentifier: `${beacon.Name}-vertical`,
                  geometry: new LineString(verticalSnapCoords),
                }),
              ]
            : undefined;
        })
        .filter((element): element is SnapFeatures[] => element !== undefined)
        .flat();
      // Filter out the duplicates to avoid opacity to stack
      return linesToSnap.filter(
        (feature, index, self) =>
          index ===
          self.findIndex((selfFeature) => {
            const featureCoords = feature.getGeometry()?.getCoordinates();
            const selfFeatureCoords = selfFeature
              .getGeometry()
              ?.getCoordinates();

            return (
              selfFeatureCoords?.[0] &&
              selfFeatureCoords[1] &&
              typeof selfFeatureCoords[0][0] === 'number' &&
              typeof selfFeatureCoords[0][0] === 'number' &&
              typeof selfFeatureCoords[1][0] === 'number' &&
              typeof selfFeatureCoords[1][0] === 'number' &&
              featureCoords?.[0] &&
              featureCoords[1] &&
              typeof featureCoords[0][0] === 'number' &&
              typeof featureCoords[0][1] === 'number' &&
              typeof featureCoords[1][0] === 'number' &&
              typeof featureCoords[1][1] === 'number' &&
              coordinatesEquals(selfFeatureCoords[0], featureCoords[0]) &&
              coordinatesEquals(selfFeatureCoords[1], featureCoords[1])
            );
          }),
      );
    }
  }
  return [];
};

export const beaconStyle = () =>
  new Style({
    image: new Circle({
      radius: 7,
      fill: new Fill({
        color: getColor('PRIMARY400', '.9'),
      }),
      stroke: new Stroke({
        color: 'white',
        width: 1,
      }),
    }),
  });

export const animateBeacon = (
  event: RenderEvent,
  map: OLMap,
  geometry: Point,
  start: number,
  animationDuration: number,
  highlightColor: Color,
  maxRadius = 100,
) => {
  const { frameState } = event;

  if (frameState) {
    const elapsed = frameState.time - start;
    const vectorContext = getVectorContext(event);
    const elapsedRatio = elapsed / animationDuration;
    const radius =
      easeOut(elapsedRatio) *
      (maxRadius / (map.getView().getResolution() || 1)) *
      2.5;
    const opacity = easeOut(1 - elapsedRatio) / 3;

    // Need to make a copy otherwise the original value is being modified
    const color = highlightColor.slice().splice(0, 3).concat(opacity);

    const style = new Style({
      image: new CircleStyle({
        radius,
        fill: new Fill({
          color,
        }),
        stroke: new Stroke({
          color,
          width: 3,
        }),
      }),
    });

    vectorContext.setStyle(style);
    vectorContext.drawGeometry(geometry);
  }
  // Tell OpenLayers to continue postrender animation
  map.render();
};

export const getBeaconColor = (
  feature: DeskInUseFeatureType,
  warmMinutesPolicy: number,
): Color => {
  const deskSensor = feature.getProperties().Sensor;
  const offline = !!feature.getProperties().Sensor?.MqttBeacon.IsOffline;

  if (
    deskSensor?.Value === 0 &&
    isAfter(
      new Date(deskSensor.UpdatedAt),
      subMinutes(new Date(), warmMinutesPolicy),
    )
  ) {
    return convertColorToRGB(Colors.YELLOW);
  }
  if (deskSensor?.Value === 1) {
    return convertColorToRGB(Colors.RED);
  }
  if (offline) {
    return convertColorToRGB(Colors.NEUTRAL400);
  }

  return convertColorToRGB(Colors.GREEN);
};
