import type { ClientRoleInfos } from 'graphql/types';
import { useMemo } from 'react';
import { LuClipboardCheck } from 'react-icons/lu';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import { structureRoles } from 'utils/graphql/useHasuraHeaders';
import RoleGroupCheckbox from './components/RoleGroupCheckbox';

type UserRole = ClientRoleInfos;

interface PermissionCheckboxProps {
  roles: UserRole[];
  deletedRoles: UserRole[];
  setDeletedRoles: (roles: UserRole[]) => void;
  checkedRoles: UserRole[];
  setCheckedRoles: (roles: UserRole[]) => void;
}

export default function PermissionCheck({
  roles,
  deletedRoles,
  setDeletedRoles,
  checkedRoles,
  setCheckedRoles,
}: PermissionCheckboxProps) {
  const intl = useIntl();

  const handleOnChange = (newRoles: UserRole[]) => {
    const names = newRoles.map((r) => r.name);
    const present = checkedRoles.filter((r) => names.includes(r.name));

    if (newRoles.length) {
      if (present.length) {
        // Remove the element from the list as it was already added
        const newDeleteRoles = [
          ...deletedRoles,
          ...checkedRoles.filter((r) => names.includes(r.name)),
        ];
        setDeletedRoles(newDeleteRoles);

        setCheckedRoles(checkedRoles.filter((r) => !names.includes(r.name)));
      } else {
        setDeletedRoles(deletedRoles.filter((r) => !names.includes(r.name)));
        setCheckedRoles(
          [...checkedRoles, ...newRoles]
            .filter((r) => r.name && r.id)
            .map((newRole) => ({
              id: newRole.id,
              name: newRole.name,
            })),
        );
      }
    }
  };

  const otherRoles = useMemo(
    () =>
      roles.filter(
        (r) => r.name && !(structureRoles as string[]).includes(r.name),
      ),
    [roles],
  );

  const structureRoleList = useMemo(
    () =>
      roles.filter(
        (r) => r.name && (structureRoles as string[]).includes(r.name),
      ),
    [roles],
  );

  return (
    <div className="flex flex-col gap-2 border border-neutral-300 dark:border-neutral-700 p-2 rounded-md">
      <div className="flex flex-col gap-2">
        <div className="flex align-items gap-2">
          <LuClipboardCheck className="text-xs size-5 dark:text-neutral-200" />
          <div className="font-bold">
            <FormattedMessage id="Permissions" />
          </div>
        </div>
        <RoleGroupCheckbox
          name={intl.formatMessage({
            id: 'General',
          })}
          expanded
          roles={otherRoles.sort((a, b) =>
            intl
              .formatMessage({ id: (a.name || '') as IntlMessageKeys })
              .localeCompare(
                intl.formatMessage({ id: (b.name || '') as IntlMessageKeys }),
              ),
          )}
          groupRoles={checkedRoles}
          handleOnChange={handleOnChange}
        />
        <RoleGroupCheckbox
          name={intl.formatMessage({
            id: 'Structure',
          })}
          roles={structureRoleList}
          groupRoles={checkedRoles}
          handleOnChange={handleOnChange}
        />
      </div>
    </div>
  );
}
