import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import router from 'router/router';
import initializeFaro from 'utils/faro/initialize';
import { registerSW } from 'virtual:pwa-register';
import './css/index.css';

initializeFaro();

registerSW({ immediate: true });

const element = document.getElementById('root');

element &&
  createRoot(element).render(
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>,
  );
