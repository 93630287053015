import Banner from 'generic/components/Banner/Banner';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import { useInsertQuickReservationMutation } from 'graphql/types';
import { FormattedMessage, useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import useToast from 'utils/graphql/useToast';

import { serializeRange } from '@/utils/date';
import format from '@/utils/format';
import { addHours } from 'date-fns';
import { useMemo } from 'react';
import { LuChevronRight } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

interface ReservationBannerProps {
  setReservationBannerOpen: (open: boolean) => void;
  availableDesk: {
    id: number;
    name: string;
  };
}

export default function ReservationBanner({
  setReservationBannerOpen,
  availableDesk,
}: ReservationBannerProps) {
  const hasuraHeader = useHasuraHeader();
  const toast = useToast();
  const intl = useIntl();
  const params = useMemo(() => new URLSearchParams(), []);
  const navigate = useNavigate();
  const [, addQuickDeskReservation] = useInsertQuickReservationMutation();

  return (
    <div className="py-2">
      <Banner className="rounded-lg" setOpen={setReservationBannerOpen}>
        <div className="flex">
          <StyledButton
            data-test-id="confirm-reservation"
            onClick={() => {
              addQuickDeskReservation(
                {
                  DeskId: availableDesk.id,
                  Duration: serializeRange({
                    start: {
                      value: new Date(),
                      inclusive: true,
                    },
                    end: {
                      value: addHours(new Date(), 4),
                      inclusive: false,
                    },
                  }),
                },
                hasuraHeader(HasuraPermissions.READ, [
                  'DeskReservations_aggregate',
                ]),
              ).then((v) => {
                toast(v, {
                  message: {
                    type: 'success',
                    content: intl.formatMessage(
                      {
                        id: 'Added reservation for desk',
                      },
                      {
                        desk: v.data?.insert_DeskReservations?.returning[0]
                          ?.Desk.Name,
                      },
                    ),
                  },
                });
                if (!v.error) {
                  params.set('desk', availableDesk.name);
                  navigate({ search: params.toString() });
                  setReservationBannerOpen(false);
                }
              });
            }}
          >
            <div className="flex items-center">
              <LuChevronRight className="size-5" />{' '}
              <FormattedMessage
                id="Confirm reservation"
                values={{
                  from: format(new Date(), 'p'),
                  to: format(addHours(new Date(), 4), 'p'),
                  name: availableDesk.name,
                }}
              />
            </div>
          </StyledButton>
        </div>
      </Banner>
    </div>
  );
}
