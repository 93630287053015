import type React from 'react';
import { LuChevronRight, LuHouse } from 'react-icons/lu';
import { NavLink } from 'react-router-dom';

interface BreadCrumbItem {
  text: string;
  component?: React.ReactNode;
  to?: string;
}

interface BreadCrumbsProps {
  items: BreadCrumbItem[];
}

export default function BreadCrumbs({
  items = [],
}: BreadCrumbsProps): React.JSX.Element {
  return (
    <div className="flex items-center dark:text-neutral-200">
      <NavLink to="/">
        <LuHouse className="size-5" />
      </NavLink>
      {items.map((item) => (
        <div className="flex flex-row items-center" key={item.text}>
          <LuChevronRight className="size-5 mx-2" />
          {item.to ? (
            <NavLink
              to={`../${item.to}`}
              className={({ isActive }) =>
                `transition-all hover:text-primary-700${
                  isActive ? ' text-primary-500 dark:text-neutral-300' : ''
                }`
              }
            >
              <div className="flex space-x-2 items-center">
                <div>{item.text}</div>
                <div>{item.component}</div>
              </div>
            </NavLink>
          ) : (
            <div className="flex space-x-2 items-center">
              <div>{item.text}</div>
              <div>{item.component}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
