import Tooltip from 'generic/components/Tooltip';
import { FormattedMessage, type IntlMessageKeys } from 'translations/Intl';
import useDeviceDetect from 'utils/useDeviceDetect';

import { ModuleType, type NewSensor } from '@/common/types';
import Accordion from '@/generic/components/Accordion';
import Transition from '@/generic/components/Transition';
import renderSensorIcon from '@/utils/renderSensorIcon';
import type Point from 'ol/geom/Point';
import { useMemo } from 'react';

interface OtherSensorsListProps {
  sensors: NewSensor[] | undefined | null;
  beaconGeom: Point | null | undefined;
}

interface TypeObj {
  type: string;
}

interface CountObj extends TypeObj {
  count: number;
}

interface Wrapper {
  [key: string]: CountObj;
}

const countTypes = (arr: TypeObj[]) => {
  const res = {} as Wrapper;
  for (const obj of arr) {
    const key = obj.type;
    const countObj = res[key];
    if (!countObj) {
      (res as Wrapper)[key] = { ...obj, count: 1 };
    } else {
      countObj.count += 1;
    }
  }
  return Object.values<CountObj>(res);
};

const renderNumber = (
  type: string,
  counts: CountObj[],
): React.JSX.Element | null => {
  const c = counts.find((entry) => entry.type === type);
  if (c && c.count > 1) {
    return (
      <div className="absolute -top-2 right-0 text-xs text-white rounded-full bg-primary-500 dark:bg-primary-700 flex items-center justify-center size-4 shadow-md">
        {c.count}
      </div>
    );
  }
  return null;
};

function OtherSensorsList({
  sensors,
  beaconGeom,
}: OtherSensorsListProps): React.JSX.Element | null {
  const { isMobile } = useDeviceDetect();
  const counts = useMemo(() => {
    if (sensors && sensors.length > 0) {
      return countTypes(
        sensors.map((e) => ({
          type: e.type,
        })),
      );
    }
    return [];
  }, [sensors]);

  const types = useMemo(
    () => (sensors ? sensors.map((s) => s.type) : []),
    [sensors],
  );

  return (
    <Transition show={!!beaconGeom && !!sensors && sensors.length > 0}>
      <Accordion
        title={<FormattedMessage id="Further Sensors" />}
        initialStateOpen={!isMobile}
      >
        <div className="flex">
          {sensors
            ?.filter(
              (sensor, index) =>
                !types.includes(sensor.type, index + 1) &&
                sensor.type !== ModuleType.LINECOUNT,
            )
            .map((sensor: NewSensor) => (
              <Tooltip
                key={sensor.id}
                content={
                  <div className="relative">
                    {renderSensorIcon(sensor.type)}
                    {renderNumber(sensor.type, counts)}
                  </div>
                }
              >
                <span key={sensor.id}>
                  <FormattedMessage id={sensor.type as IntlMessageKeys} />
                </span>
              </Tooltip>
            ))}
        </div>
      </Accordion>
    </Transition>
  );
}

export default OtherSensorsList;
