import { isNonNullable } from '@/common/types';
import { ExtendedMap } from '@/generic/components/BasicMap';
import Map from '@/generic/components/Map';
import type { HighlightedFeatures } from '@/generic/components/Map/Map';
import Loader from '@/generic/components/layout/BarLoader';
import BaseLayer from '@/generic/layers/BaseLayer';
import type Layer from '@/generic/layers/Layer';
import type TypedFeature from '@/generic/layers/TypedFeature';
import { type LiveBeaconsQuery, useFloorImageQuery } from '@/graphql/types';
import useStore from '@/model/store';
import Accordion from 'generic/components/Accordion';
import GeometryLayer from 'generic/layers/GeometryLayer';
import type Point from 'ol/geom/Point';
import type VectorSource from 'ol/source/Vector';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'translations/Intl';
import getColor, {
  primaryColorToRGB,
  primaryColorToRGBArray,
} from 'utils/getColor';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import useDecodedLocation from 'utils/useDecodedLocation';

interface BeaconMapProps {
  data: NonNullable<LiveBeaconsQuery['MqttBeacons']>[number];
  floorNumber: number;
  buildingName: string;
}

export default function BeaconMap({
  data,
  floorNumber,
  buildingName,
}: BeaconMapProps) {
  const hasuraHeader = useHasuraHeader();
  const userRoles = useStore((state) => state.user)?.roles;
  const beacon = useDecodedLocation('beacon');
  const [baseLayer] = useState(new BaseLayer());
  const [beaconLayer] = useState(
    new GeometryLayer<typeof data>({
      style: (feat) => {
        const getBeaconColor = (isFill: boolean, offline: boolean) => {
          if (offline) {
            return isFill
              ? getColor('NEUTRAL400', '.9')
              : getColor('NEUTRAL600', '.8');
          }
          return isFill ? primaryColorToRGB(300, 1) : primaryColorToRGB(500, 1);
        };

        return [
          new Style({
            image: new Circle({
              radius: 5,
              fill: new Fill({
                color: getBeaconColor(true, !!feat.getProperties().IsOffline),
              }),
              stroke: new Stroke({
                color: getBeaconColor(false, !!feat.getProperties().IsOffline),
                width: 2,
              }),
            }),
          }),
        ];
      },
    }),
  );
  const [layers] = useState<Layer[]>([baseLayer, beaconLayer]);
  const [highlightedPoints, setHighlightedPoints] = useState<
    TypedFeature<typeof data, Point>[]
  >([]);
  const [map] = useState(new ExtendedMap());

  const [{ data: floorImageData, fetching: loadingFloor }] = useFloorImageQuery(
    {
      variables: { FloorNumber: floorNumber, BuildingName: buildingName },
      context: useMemo(
        () =>
          hasuraHeader(
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? HasuraPermissions.READ_ALL
              : HasuraPermissions.READ,
          ),
        [hasuraHeader, userRoles],
      ),
    },
  );

  useEffect(() => {
    beaconLayer.setFeatures([data]);

    setHighlightedPoints(
      (
        beaconLayer.olLayer.getSource() as VectorSource<
          TypedFeature<typeof data, Point>
        >
      ).getFeatures(),
    );
  }, [data, beaconLayer.setFeatures, beaconLayer.olLayer.getSource]);

  useEffect(() => {
    const image = floorImageData?.Floors[0]?.Image;

    if (image) {
      baseLayer.setImage(image);
    }
  }, [baseLayer, floorImageData?.Floors[0]?.Image]);

  const highlightedFeatures = useMemo(
    () => ({
      features: highlightedPoints
        .map((f) => {
          const geometry = f.getGeometry();

          if (!geometry) {
            console.error('Missing geometry');
          }

          return {
            geometry,
            color: primaryColorToRGBArray(500),
          };
        })
        .filter(
          (
            f,
          ): f is Exclude<HighlightedFeatures['features'], undefined>[number] =>
            isNonNullable(f.geometry),
        ),
      layer: beaconLayer,
    }),
    [highlightedPoints, beaconLayer],
  );

  return (
    <Accordion
      initialStateOpen={!!beacon}
      title={
        <>
          <FormattedMessage id="Map" />:{' '}
          <FormattedMessage
            id="Building Floor"
            values={{ building: buildingName, number: floorNumber }}
          />
        </>
      }
    >
      <div className="relative h-full whitespace-normal">
        <Loader loading={loadingFloor} />
        <Map
          className="h-96"
          map={map}
          layers={layers}
          isLoadingFeatures={loadingFloor}
          highlightedFeatures={highlightedFeatures}
        />
      </div>
    </Accordion>
  );
}
