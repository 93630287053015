import Overview, {
  desksPerBuilding,
  findValue,
} from '@/generic/components/Overview';
import {
  Order,
  Types,
} from '@/generic/components/Overview/components/OverviewRow/components/FloorRow';
import {
  type CleaningOccupancyQuery,
  useCleaningOccupancyQuery,
  useOrganizationTopologyQuery,
} from '@/graphql/types';
import useStore from '@/model/store';
import getColor, { primaryColorToRGB } from '@/utils/getColor';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import usePolling from '@/utils/graphql/usePolling';
import useRoomDeskFilter from '@/utils/graphql/useRoomDeskFilter';
import { useMemo, useState } from 'react';
import groupBy from 'utils/groupBy';
import CleaningMap from './components/CleaningMap';

export default function BuildingsCleaningNeeds() {
  const hasuraHeader = useHasuraHeader();
  const [orderProperty] = useState<Types>(Types.TOCLEAN);
  const cleaningTimes = useStore(
    (state) => state.organizationSettings.cleaningTimes,
  );
  const { check: timeBeforeCheck, clean: timeBeforeClean } = cleaningTimes;
  const [order] = useState<Order>(Order.UP);
  const [{ data: buildingsTopology, fetching: loadingTopology }] =
    useOrganizationTopologyQuery({
      variables: { ...useRoomDeskFilter() },
    });
  const [
    { data: cleaningData, fetching: loadingCleaningData },
    reexecuteQuery,
  ] = useCleaningOccupancyQuery({
    variables: {
      ...useRoomDeskFilter(),
    },
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_CLEANING),
      [hasuraHeader],
    ),
  });

  usePolling(loadingCleaningData, reexecuteQuery, 1000 * 15);

  const formattedData = useMemo(() => {
    if (
      cleaningData?.f_live_desks_cleaning ||
      cleaningData?.f_live_rooms_cleaning
    ) {
      // Aggregate the cleaning needs per building and floor
      // Format the data for pie chart
      return desksPerBuilding(buildingsTopology)
        .map((b) => {
          const features =
            cleaningData.f_live_desks_cleaning?.filter(
              (f) => f.Building === b.name,
            ) ?? [];
          const featuresPerFloors = groupBy(features, (i) => i.Floor);
          const cleaningNeedsPerFloors = b.floors.map((fl) => ({
            number: fl.number,
            needs: (featuresPerFloors[fl.number] ??
              []) as CleaningOccupancyQuery['f_live_desks_cleaning'],
          }));

          const roomSensorFeatures =
            cleaningData.f_live_rooms_cleaning?.filter(
              (f) => f.Building === b.name,
            ) ?? [];

          // Aggregate all sensors that are related to the same room
          const roomFeatures = Object.values(
            groupBy(roomSensorFeatures, (i) => i.Desk),
          ).map((arr) =>
            arr?.reduce((acc, curr) => ({
              // biome-ignore lint/performance/noAccumulatingSpread: noAccumulatingSpread
              ...acc,
              HotMinutes: acc.HotMinutes + curr.HotMinutes,
            })),
          );
          const roomFeaturesPerFloors = groupBy(
            roomFeatures,
            (i) => i?.Floor ?? 0,
          );

          const roomCleaningNeedsPerFloors = b.floors.map((fl) => ({
            number: fl.number,
            needs: (roomFeaturesPerFloors[fl.number] ??
              []) as CleaningOccupancyQuery['f_live_rooms_cleaning'],
          }));

          const floors = cleaningNeedsPerFloors.map((cleaningNeedsPerFloor) => {
            const { number, needs } = cleaningNeedsPerFloor;
            const floorDesks = b.floors.find(
              (floorInfo) => floorInfo.number === number,
            );

            const offline = floorDesks?.offline ?? 0;
            const toCheck =
              needs?.filter(
                (f) =>
                  f.HotMinutes >= timeBeforeCheck &&
                  f.HotMinutes < timeBeforeClean,
              ).length ?? 0;
            const toClean =
              needs?.filter((f) => f.HotMinutes >= timeBeforeClean).length ?? 0;

            // If the room is toCheck/toClean but offline it will be counted
            // twice -> clean could be negative
            const clean = Math.max(
              (floorDesks?.places ?? 0) - (toClean + toCheck + offline),
              0,
            );
            return {
              id: floorDesks?.id ?? 0,
              number,
              total: [
                {
                  id: Types.TOCLEAN,
                  value: toClean,
                },
                { id: Types.CHECK, value: toCheck },
                {
                  id: Types.CLEAN,
                  value: clean,
                },
                {
                  id: Types.OFFLINE,
                  value: offline,
                },
                {
                  id: Types.TOTAL,
                  value: (floorDesks?.places ?? 0) - offline,
                },
              ],
              cleaningDuration: floorDesks?.cleaningDuration,
            };
          });

          const roomFloors = roomCleaningNeedsPerFloors.map(
            (roomCleaningNeedsPerFloor) => {
              const { number, needs } = roomCleaningNeedsPerFloor;
              const floorRooms = b.floors.find(
                (floorInfo) => floorInfo.number === number,
              );
              const offlineMeeting = floorRooms?.offlineMeeting ?? 0;

              const toCheck =
                needs?.filter(
                  (f) =>
                    f.HotMinutes >= timeBeforeCheck &&
                    f.HotMinutes < timeBeforeClean,
                ).length ?? 0;

              const toClean =
                needs?.filter((f) => f.HotMinutes >= timeBeforeClean).length ??
                0;

              // If the room is toCheck/toClean but offline it will be counted
              // twice -> clean could be negative
              const clean = Math.max(
                (floorRooms?.placesMeeting ?? 0) -
                  (toClean + toCheck + offlineMeeting),
                0,
              );

              return {
                id: floorRooms?.id ?? 0,
                number,
                total: [
                  {
                    id: Types.TOCLEAN,
                    value: toClean,
                  },
                  { id: Types.CHECK, value: toCheck },
                  {
                    id: Types.CLEAN,
                    value: clean,
                  },
                  {
                    id: Types.OFFLINE,
                    value: offlineMeeting,
                  },
                  {
                    id: Types.TOTAL,
                    value: (floorRooms?.placesMeeting ?? 0) - offlineMeeting,
                  },
                ],
                cleaningDuration: floorRooms?.cleaningDuration,
              };
            },
          );

          return {
            id: b.id,
            name: b.name,
            floors,
            roomFloors,
          };
        })
        .sort((a, b) => {
          if (order === Order.DOWN) {
            return findValue(a, orderProperty) - findValue(b, orderProperty);
          }
          return findValue(b, orderProperty) - findValue(a, orderProperty);
        });
    }
    return [];
  }, [
    cleaningData,
    cleaningData?.f_live_desks_cleaning,
    cleaningData?.f_live_rooms_cleaning,
    buildingsTopology,
    timeBeforeCheck,
    timeBeforeClean,
    order,
    orderProperty,
  ]);

  return (
    <Overview
      title="Cleaning needs"
      data={formattedData}
      columns={[Types.TOCLEAN, Types.CHECK, Types.CLEAN, Types.TOTAL]}
      pieChartColors={[
        primaryColorToRGB(600),
        primaryColorToRGB(400),
        getColor('NEUTRAL200'),
        getColor('NEUTRAL300'),
      ]}
      defaultSorting={Types.TOCLEAN}
      dataLoading={loadingCleaningData || loadingTopology}
      map={<CleaningMap />}
    />
  );
}
