import getClimateStatus, { ClimateStatus } from '@/utils/getClimateStatus';
import type { ClimateType } from 'common/types';
import type { ClimateBeaconFeatureType } from 'generic/layers/ClimateBeaconLayer';
import type { ClimateRoomFeatureType } from 'generic/layers/ClimateRoomLayer';
import { LuCircleAlert, LuCodepen, LuSignal } from 'react-icons/lu';
import { FormattedMessage } from 'translations/Intl';
import { formattedDistance } from 'utils/date';
import { lower, upper } from 'utils/numberrange';
import SensorContent from './components/SensorContent';

export type ClimateFeatures = ClimateRoomFeatureType | ClimateBeaconFeatureType;

export function isClimateRoomFeature(
  feature: ClimateFeatures,
): feature is ClimateRoomFeatureType {
  return !!(feature as ClimateRoomFeatureType).getProperties().Room;
}

export function isClimateBeaconFeature(
  feature: ClimateFeatures,
): feature is ClimateBeaconFeatureType {
  return !!(feature as ClimateBeaconFeatureType).getProperties().Sensors;
}

interface ClimatePopupProps {
  hoveredFeature: ClimateFeatures | null;
}

const formatClimateLimits = (
  good?: string,
  acceptable?: string,
  poor?: string,
) => {
  return {
    good: {
      start: lower(good) ?? undefined,
      end: upper(good) ?? undefined,
    },
    acceptable: {
      start: lower(acceptable) ?? undefined,
      end: upper(acceptable) ?? undefined,
    },
    poor: {
      start: lower(poor) ?? undefined,
      end: upper(poor) ?? undefined,
    },
  };
};

export default function ClimatePopup({ hoveredFeature }: ClimatePopupProps) {
  const getValueRating = (
    value: number,
    good?: string,
    acceptable?: string,
    poor?: string,
  ) => {
    const climateStatus = getClimateStatus(
      value,
      formatClimateLimits(good, acceptable, poor),
    );
    if (climateStatus === ClimateStatus.GOOD) {
      return 'GREEN';
    }
    if (climateStatus === ClimateStatus.ACCEPTABLE) {
      return 'YELLOW';
    }
    return 'RED';
  };

  if (!hoveredFeature) {
    return null;
  }

  if (isClimateBeaconFeature(hoveredFeature)) {
    const { Name, Sensors } = hoveredFeature.getProperties();
    const lastHeartbeat = Sensors.map((s) => s.MqttBeacon.LastHeartbeat).sort(
      (first, second) =>
        // order Date to get the oldest one
        Date.parse(first) - Date.parse(second),
    )[0];
    return (
      <div data-test-id="climate-beacon-popup">
        <div className="flex items-center border-b border-neutral-300 dark:border-neutral-700">
          <LuSignal className="size-6 m-2" />
          <b>{Name}</b>
        </div>
        {Sensors.map((s) => s.MqttBeacon.IsOffline).every(
          (s): s is boolean => !!s,
        ) ? (
          <div className="flex py-1 items-center">
            <div>
              <LuCircleAlert className="size-6 ml-2 mr-3 text-neutral-700" />
            </div>
            <div className="flex flex-col text-sm">
              <FormattedMessage id="The device may be offline" />
              <br />
              <div className="flex items-center">
                <FormattedMessage id="Last Activity" />:{' '}
                {formattedDistance(new Date(lastHeartbeat))}
              </div>
            </div>
          </div>
        ) : (
          Sensors.sort((a, b) =>
            a.SensorType.Name.localeCompare(b.SensorType.Name),
          ).map((sensor) => (
            <SensorContent
              key={sensor.SensorType.Name}
              name={sensor.SensorType.Name as ClimateType}
              value={sensor.Value ?? 0}
              valueRating={getValueRating(
                sensor.Value ?? 0,
                sensor.SensorType.ClimateSensorLimits[0]?.GoodValue,
                sensor.SensorType.ClimateSensorLimits[0]?.AcceptableValue,
                sensor.SensorType.ClimateSensorLimits[0]?.PoorValue,
              )}
              unit={sensor.SensorType.Unit ?? ''}
              updatedAt={sensor.UpdatedAt}
            />
          ))
        )}
      </div>
    );
  }

  // Room feature
  const {
    SensorValues,
    Offline: offline,
    Room: roomName,
  } = hoveredFeature.getProperties();
  const sensors = SensorValues.flat();

  return (
    <div>
      {roomName && (
        <div className="flex items-center border-b border-neutral-300 dark:border-neutral-700">
          <LuCodepen className="size-6 m-2" />
          <b>{roomName}</b>
        </div>
      )}
      {offline ? (
        <div className="flex py-1 items-center">
          <div>
            <LuCircleAlert className="size-6 ml-2 mr-3 text-neutral-700" />
          </div>
          <div className="flex flex-col text-sm">
            <FormattedMessage id="The room devices may be offline" />
          </div>
        </div>
      ) : (
        sensors.map((sensor) => (
          <SensorContent
            key={sensor.SensorType}
            value={sensor.Value}
            unit={sensor.Unit}
            name={sensor.SensorType as ClimateType}
            valueRating={getValueRating(
              sensor.Value,
              sensor.GoodValue,
              sensor.AcceptableValue,
              sensor.PoorValue,
            )}
          />
        ))
      )}
    </div>
  );
}
