import useStore from '@/model/store';
import { Navigate } from 'react-router-dom';
import { SERVICES_ROUTES } from 'router/routes';

export default function Home() {
  const userRoles = useStore((state) => state.user)?.roles;

  return (
    <Navigate
      to={`/${
        SERVICES_ROUTES.find((service) =>
          Array.isArray(service.userRole)
            ? service.userRole.every((role) => userRoles?.includes(role))
            : userRoles?.includes(service.userRole),
        )?.path ?? 'settings'
      }`}
      replace
    />
  );
}
