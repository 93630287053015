import type { ColumnDef, Row } from '@tanstack/react-table';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import Table from 'generic/components/Table';
import { ACTION_ID } from 'generic/components/Table/Table';
import Tooltip from 'generic/components/Tooltip';
import BarLoader from 'generic/components/layout/BarLoader';
import {
  useAddMqttMessageMappingMutation,
  useMqttMessageMappingsQuery,
} from 'graphql/types';
import { useMemo, useState } from 'react';
import { LuCirclePlus, LuCopy, LuPencil, LuTrash } from 'react-icons/lu';
import { FormattedMessage, useIntl } from 'translations/Intl';
import { formattedDistance } from 'utils/date';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import usePolling from 'utils/graphql/usePolling';
import AddMappingModal from './components/AddMappingModal';
import RemoveMappingModal from './components/RemoveMappingModal';
import type { MqttMessageMapping } from './components/RemoveMappingModal/RemoveMappingModal';

function ActionCell({
  row,
  setSelectedDeviceMapping,
  setOpenAddModal,
  setOpenRemoveModal,
}: {
  row: Row<MqttMessageMapping>;
  setSelectedDeviceMapping: (firmware: MqttMessageMapping | undefined) => void;
  setOpenAddModal: (open: boolean) => void;
  setOpenRemoveModal: (open: boolean) => void;
}) {
  const hasuraHeader = useHasuraHeader();
  const [, duplicateMapping] = useAddMqttMessageMappingMutation();

  return (
    <div className="flex space-x-2 space-x-reverse flex-row-reverse items-center">
      <Tooltip
        content={
          <p>
            <LuTrash
              data-test-id="delete-mapping-button"
              onClick={() => {
                setSelectedDeviceMapping(row.original);
                setOpenRemoveModal(true);
              }}
              className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
            />
          </p>
        }
      >
        <FormattedMessage id="Delete" />
      </Tooltip>
      <Tooltip
        content={
          <p>
            <LuPencil
              data-test-id="edit-mapping-button"
              className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
              onClick={() => {
                setSelectedDeviceMapping(row.original);
                setOpenAddModal(true);
              }}
            />
          </p>
        }
      >
        <FormattedMessage id="Edit" />
      </Tooltip>
      <Tooltip
        content={
          <p>
            <LuCopy
              data-test-id="duplicate-mapping-button"
              onClick={() =>
                duplicateMapping(
                  {
                    MqttMessageMappingFieldsToDelete: [],
                    MqttMessageMappingFieldsToAdd:
                      row.original.MqttMessageMappingFields.map((mapping) => ({
                        MqttMessageMapping: {
                          data: {
                            Name: `${row.original.Name}_copy`,
                          },
                        },
                        SensorTypeId: mapping.SensorTypeId,
                        Value: mapping.Value,
                        DeviceId: mapping.DeviceId,
                        Timestamp: mapping.Timestamp,
                        SensorTypeCondition: mapping.SensorTypeCondition,
                      })),
                    AddUser: false,
                    // Key needs to exist for the mutation, but is not needed for the execution
                    User: {},
                  },
                  hasuraHeader(HasuraPermissions.WRITE_MQTTMAPPING),
                )
              }
              className="size-5 cursor-pointer text-primary-500 hover:text-primary-700"
            />
          </p>
        }
      >
        <FormattedMessage id="Duplicate" />
      </Tooltip>
    </div>
  );
}

export default function DeviceMappingView() {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [selectedDeviceMapping, setSelectedDeviceMapping] = useState<
    MqttMessageMapping | undefined
  >();

  const [{ data, fetching }, reexecuteQuery] = useMqttMessageMappingsQuery({
    context: useMemo(
      () =>
        hasuraHeader(HasuraPermissions.WRITE_MQTTMAPPING, [
          'MqttMessageMappings',
        ]),
      [hasuraHeader],
    ),
  });

  const mappings = useMemo(() => data?.MqttMessageMappings ?? [], [data]);

  const defaultColumns: ColumnDef<MqttMessageMapping>[] = useMemo(
    () => [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'Name' }),
        accessorKey: 'Name',
      },
      {
        id: 'hits',
        header: intl.formatMessage({ id: 'Hits' }),
        accessorKey: 'Hits',
      },
      {
        header: intl.formatMessage({ id: 'Created at' }),
        id: 'createdAt',
        accessorFn: (row) => new Date(row.CreatedAt),
        cell: ({ row }) => formattedDistance(new Date(row.original.CreatedAt)),
        enableColumnFilter: false,
      },
      {
        header: intl.formatMessage({ id: 'Last updated' }),
        id: 'updatedAt',
        accessorFn: (row) => new Date(row.UpdatedAt),
        cell: ({ row }) => formattedDistance(new Date(row.original.UpdatedAt)),
        enableColumnFilter: false,
      },
      {
        enableGrouping: false,
        id: ACTION_ID,
        cell: ({ row }) => (
          <ActionCell
            row={row}
            setSelectedDeviceMapping={setSelectedDeviceMapping}
            setOpenAddModal={setOpenAddModal}
            setOpenRemoveModal={setOpenRemoveModal}
          />
        ),
      },
    ],
    [intl.formatMessage],
  );

  usePolling(fetching, reexecuteQuery, 30 * 1000);

  return (
    <>
      <BarLoader loading={fetching} />
      <Table<MqttMessageMapping>
        id="devicemapping"
        columns={defaultColumns}
        data={mappings}
        getRowId={(row) => row.Id}
        loading={fetching}
        initialState={{ sorting: [{ id: 'name', desc: false }] }}
        renderAdditionalAction={
          <Tooltip
            content={
              <StyledButton
                onClick={() => setOpenAddModal(true)}
                data-test-id="add-mapping-button"
              >
                <LuCirclePlus className="size-5" />
              </StyledButton>
            }
          >
            <FormattedMessage id="Add message mapping" />
          </Tooltip>
        }
      />

      <RemoveMappingModal
        mappingToRemove={selectedDeviceMapping}
        setMappingToRemove={setSelectedDeviceMapping}
        open={openRemoveModal}
        setOpen={setOpenRemoveModal}
      />

      <AddMappingModal
        selectedMapping={selectedDeviceMapping}
        setSelectedMapping={setSelectedDeviceMapping}
        open={openAddModal}
        setOpen={setOpenAddModal}
      />
    </>
  );
}
