import Pill from '@/generic/components/Pill';
import { FormattedMessage } from '@/translations/Intl';

interface OnlineCellProps {
  isOffline: boolean;
  className?: string;
}

export default function OnlineCell({ isOffline, className }: OnlineCellProps) {
  return (
    <Pill
      className={`${
        isOffline
          ? 'bg-red-500! text-red-100!'
          : 'bg-green-500! text-green-100!'
      } ${className ?? ''}`}
    >
      <FormattedMessage id={isOffline ? 'Offline' : 'Online'} />
    </Pill>
  );
}
