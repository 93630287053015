import {
  addHours,
  addMinutes,
  differenceInMilliseconds,
  endOfToday,
  format,
  isAfter,
  isBefore,
  set,
  startOfToday,
} from 'date-fns';
import { Handles, Rail, Slider, Ticks, Tracks } from 'react-compound-slider';

import type { DateArray2, NumberArray2 } from '@/common/types';
import { scaleTime } from '@visx/scale';
import Handle from './components/Handle';
import SliderRail from './components/SliderRail';
import Tick from './components/Tick';
import Track from './components/Track';

const getTimelineConfig =
  (timelineStart: Date, timelineLength: number) => (date: Date) => {
    const percent =
      (differenceInMilliseconds(date, timelineStart) / timelineLength) * 100;
    const value = Number(format(date, 'T'));
    return { percent, value };
  };

const getFormattedBlockedIntervals = (
  [startTime, endTime]: DateArray2,
  blockedDates: Interval[] = [],
) => {
  if (!blockedDates.length) return null;

  const timelineLength = differenceInMilliseconds(endTime, startTime);
  const getConfig = getTimelineConfig(startTime, timelineLength);

  const formattedBlockedDates = blockedDates.map((interval, index) => {
    let { start, end } = interval;

    if (isBefore(start, startTime)) start = startTime;
    if (isAfter(end, endTime)) end = endTime;

    const source = getConfig(start);
    const target = getConfig(end);

    return { id: `blocked-track-${index}`, source, target };
  });

  return formattedBlockedDates;
};

const getNowConfig = ([startTime, endTime]: DateArray2) => {
  const timelineLength = differenceInMilliseconds(endTime, startTime);
  const getConfig = getTimelineConfig(startTime, timelineLength);

  const source = getConfig(new Date());
  const target = getConfig(addMinutes(new Date(), 1));

  return { id: 'now-track', source, target };
};

type Interval = { start: Date; end: Date };

interface TimeRangeProps {
  ticksNumber?: number;
  selectedInterval?: Date[];
  onUpdateCallback: ({ error, time }: { error: boolean; time: Date[] }) => void;
  onChangeCallback: (val: Date[]) => void;
  disabledIntervals?: Interval[];
  timelineInterval?: DateArray2;
  step?: number;
  formatTick?: (format: number) => string;
  error?: boolean;
  showNow?: boolean;
  trackClassName?: string;
  sliderClassName?: string;
}

export default function TimeRange({
  ticksNumber = 48,
  selectedInterval = [
    set(new Date(), { minutes: 0, seconds: 0, milliseconds: 0 }),
    set(addHours(new Date(), 1), { minutes: 0, seconds: 0, milliseconds: 0 }),
  ],
  timelineInterval = [startOfToday(), endOfToday()],
  disabledIntervals = [],
  step = 1000 * 60 * 30,
  formatTick = (ms) => format(new Date(ms), 'HH:mm'),
  onChangeCallback,
  onUpdateCallback,
  error = false,
  showNow = false,
  trackClassName,
  sliderClassName,
}: TimeRangeProps) {
  const blockedIntervals = getFormattedBlockedIntervals(
    timelineInterval,
    disabledIntervals,
  );
  const now = getNowConfig(timelineInterval);

  const onChange = (newTime: readonly number[]) =>
    onChangeCallback(newTime.map((t) => new Date(t)));

  const checkIsSelectedIntervalNotValid = (
    [start, end]: NumberArray2,
    source: { value: number },
    target: { value: number },
  ) => {
    const { value: startInterval } = source;
    const { value: endInterval } = target;

    if (
      (startInterval > start && endInterval <= end) ||
      (startInterval >= start && endInterval < end)
    )
      return true;
    if (start >= startInterval && end <= endInterval) return true;

    const isStartInBlockedInterval =
      start > startInterval && start < endInterval && end >= endInterval;
    const isEndInBlockedInterval =
      end < endInterval && end > startInterval && start <= startInterval;

    return isStartInBlockedInterval || isEndInBlockedInterval;
  };

  const onUpdate = (newTime: readonly number[]) => {
    if (blockedIntervals?.length) {
      const start = newTime[0];
      const end = newTime[1];

      if (!start || !end) {
        return;
      }

      const isValuesNotValid = blockedIntervals.some(({ source, target }) =>
        checkIsSelectedIntervalNotValid([start, end], source, target),
      );
      const formattedNewTime = newTime.map((t) => new Date(t));
      onUpdateCallback({ error: isValuesNotValid, time: formattedNewTime });
      return;
    }

    const formattedNewTime = newTime.map((t) => new Date(t));
    onUpdateCallback({ error: false, time: formattedNewTime });
  };

  const getDateTicks = () =>
    scaleTime()
      .domain(timelineInterval)
      .ticks(ticksNumber)
      .map((t) => +t);

  const domain = timelineInterval.map((t) => Number(t));

  return (
    <div className="react_time_range__time_range_container px-4 md:px-0">
      <Slider
        mode={3}
        step={step}
        domain={domain}
        onUpdate={onUpdate}
        onChange={onChange}
        values={selectedInterval.map((t) => +t)}
        rootStyle={{
          position: 'relative',
          width: '100%',
        }}
      >
        <Rail>
          {({ getRailProps }) => (
            <SliderRail
              className={sliderClassName}
              getRailProps={getRailProps}
            />
          )}
        </Rail>

        <Handles>
          {({ handles, getHandleProps }) => (
            <>
              {handles.map((handle) => (
                <Handle
                  error={error}
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </>
          )}
        </Handles>

        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div>
              {tracks.map(({ id, source, target }) => (
                <Track
                  className={trackClassName}
                  error={error}
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>

        {blockedIntervals?.length && (
          <Tracks left={false} right={false}>
            {({ getTrackProps }) => (
              <div>
                {blockedIntervals.map(({ id, source, target }) => (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                    disabled
                  />
                ))}
              </div>
            )}
          </Tracks>
        )}

        {showNow && (
          <Tracks left={false} right={false}>
            {({ getTrackProps }) => (
              <Track
                key={now.id}
                source={now.source}
                target={now.target}
                getTrackProps={getTrackProps}
              />
            )}
          </Tracks>
        )}

        <Ticks values={getDateTicks()}>
          {({ ticks }) => (
            <>
              {ticks.map((tick) => (
                <Tick
                  key={tick.id}
                  tick={tick}
                  count={ticks.length}
                  format={formatTick}
                />
              ))}
            </>
          )}
        </Ticks>
      </Slider>
    </div>
  );
}
