import StyledButton from '@/generic/components/Form/Button/StyledButton';
import { hexToBase64Image } from '@/utils/hexToBase64';
import { useEffect, useRef, useState } from 'react';
import { LuCircleAlert, LuX } from 'react-icons/lu';
import { FormattedMessage } from 'translations/Intl';
import hexDump from 'utils/hexDump';

type ImageInputInterface = {
  label?: string;
  setImage: (arg: string) => void;
  imageValidated: boolean;
  setImageValidated: (arg: boolean) => void;
  previewImage?: string;
  dark?: boolean;
  required?: boolean;
};

const KILO_BYTES_PER_BYTE = 1000;
const convertBytesToKB = (bytes: number) =>
  Math.round(bytes / KILO_BYTES_PER_BYTE);

export default function ImageInput({
  label,
  setImage,
  imageValidated,
  setImageValidated,
  previewImage,
  dark = false, // using this for showing a dark background to see how it would look like in dark mode
  required = false,
}: ImageInputInterface): React.JSX.Element {
  const [maxSize] = useState(2);
  const [validated, setValidated] = useState(false);
  const [fileFormats] = useState(['png', 'jpg', 'jpeg', 'gif', 'svg']);
  const [internalImage, setInternalImage] = useState<Blob | undefined>();
  const fileInputField = useRef<HTMLInputElement>(null);
  const [base64Image, setBase64Image] = useState<string | undefined>();

  const DEFAULT_MAX_FILE_SIZE_IN_KILO_BYTES = maxSize * 1024; // maxSize * 1 MB

  // Convert image to base64 for displaying image
  useEffect(() => {
    if (internalImage) {
      const readerBase64 = new FileReader();

      readerBase64.readAsDataURL(internalImage);

      readerBase64.onload = () => {
        if (typeof readerBase64.result === 'string') {
          setBase64Image(readerBase64.result);
        }

        if (
          internalImage.type.split('/')[0] === 'image' &&
          convertBytesToKB(internalImage.size) <
            DEFAULT_MAX_FILE_SIZE_IN_KILO_BYTES
        ) {
          setValidated(true);
        }
      };
    }
  }, [DEFAULT_MAX_FILE_SIZE_IN_KILO_BYTES, internalImage]);

  // Convert image to hex for uploading image to server
  useEffect(() => {
    if (internalImage) {
      hexDump(internalImage).then((img) => {
        setImage(`\\x${img}`);

        if (validated) {
          // Check if base64 image was validated and only after that set everything as validated
          setImageValidated(true);
        }
      });
    }
  }, [internalImage, setImage, setImageValidated, validated]);

  useEffect(() => {
    if (previewImage) {
      setBase64Image(
        previewImage.startsWith('\\x')
          ? hexToBase64Image(previewImage)
          : previewImage,
      );

      // As the image is the one from the database it is valid as it was
      // checked when it was added
      setImageValidated(true);
    }
  }, [previewImage, setImageValidated]);

  const formattedFileFormats = fileFormats
    .map((ff) => ff.toUpperCase())
    .join(', ');

  const allowedFileFormats = fileFormats.map((ff) => `.${ff}`).join(',');

  return (
    <div>
      <label
        htmlFor={label}
        className="block text-base md:text-sm text-left text-neutral-700 dark:text-neutral-200"
      >
        {label}
        {label && required && <span className="text-red-700"> *</span>}
      </label>

      <div
        className={`${
          dark ? 'bg-neutral-800 border-neutral-700' : 'bg-white'
        } relative mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-700 border-dashed rounded-md`}
      >
        {base64Image ? (
          <>
            {imageValidated ? (
              <img
                src={base64Image}
                alt="preview"
                height="200px"
                className="w-full"
              />
            ) : (
              <div className="flex items-center p-2">
                <div className="bg-red-100 mx-auto shrink-0 flex items-center justify-center size-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                  <LuCircleAlert className="mx-auto size-6 text-red-500" />
                </div>

                <p className="p-4">
                  <FormattedMessage id="Invalid filetype" />
                </p>
              </div>
            )}

            <StyledButton
              id="remove-preview"
              onClick={() => {
                setInternalImage(undefined);
                setBase64Image(undefined);
                setImageValidated(false);
              }}
              className="w-full absolute top-0 bottom-0 right-0 opacity-0 hover:opacity-50 py-0 px-0"
            >
              <div className="flex justify-center">
                <LuX className="size-8 flex justify-center" />
              </div>
            </StyledButton>
          </>
        ) : (
          <>
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              className="block absolute top-0 bottom-0 left-0 opacity-0 w-full"
              accept={allowedFileFormats}
              ref={fileInputField}
              onChange={(img) =>
                setInternalImage(img.target.files?.[0] ?? undefined)
              }
              required={required}
            />

            <div className="space-y-1 text-center">
              <svg
                className="mx-auto size-12 text-neutral-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div
                className={`${
                  dark ? 'text-neutral-200' : ''
                } flex text-sm text-neutral-600 dark:text-neutral-200`}
              >
                <label
                  htmlFor="file-upload"
                  className={`${
                    dark ? 'bg-neutral-800' : ''
                  } transition-all relative cursor-pointer bg-white dark:bg-neutral-800 rounded-md text-primary-500 hover:text-primary-400 focus-within:outline-hidden focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500`}
                >
                  <button
                    type="button"
                    onClick={() => fileInputField.current?.click()}
                  >
                    <FormattedMessage id="Upload a file" />
                  </button>
                </label>

                <p
                  className={`${
                    dark ? 'text-neutral-200' : ''
                  } pl-1 dark:text-neutral-200`}
                >
                  <FormattedMessage id="or drag and drop" />
                </p>
              </div>

              <p
                className={`${
                  dark ? 'text-neutral-400' : ''
                } text-xs text-neutral-500 dark:text-neutral-400`}
              >
                {formattedFileFormats} {}
                <FormattedMessage
                  id="up to {size}MB"
                  values={{
                    size: maxSize,
                  }}
                />
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
