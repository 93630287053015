import Tab from 'generic/components/Tab';
import Title from 'generic/components/Title';
import { LuChartArea, LuListOrdered, LuRefreshCcw } from 'react-icons/lu';
import { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';

export default function ReportingView() {
  return (
    <div>
      <div className="space-y-2 print:hidden">
        <div>
          <Title value="Reporting" />
        </div>
      </div>
      <Tab
        tabs={[
          {
            path: '',
            end: true, // avoid to be always active for all routes
            userRole: HasuraPermissions.VIEW_ADMIN,
            name: 'Overview',
            renderIcon: ({ className }) => (
              <LuListOrdered className={className} />
            ),
          },
          {
            path: 'defective',
            userRole: HasuraPermissions.VIEW_ADMIN,
            name: 'Defective beacons',
            renderIcon: ({ className }) => (
              <LuRefreshCcw className={className} />
            ),
          },
          {
            path: 'reports',
            name: 'Reports',
            userRole: HasuraPermissions.VIEW_REPORTS,
            renderIcon: ({ className }) => (
              <LuChartArea className={className} />
            ),
          },
        ]}
      />
    </div>
  );
}
