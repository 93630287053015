import Transition from '@/generic/components/Transition';
import { useState } from 'react';
import { LuChevronRight } from 'react-icons/lu';

import Button from '../Form/Button';

interface AccordionProps {
  initialStateOpen?: boolean;
  title: string | React.JSX.Element;
  children: React.JSX.Element | React.JSX.Element[];
  sticky?: boolean;
  chevronClassName?: string;
}

export default function Accordion({
  initialStateOpen = false,
  title,
  children,
  sticky,
  chevronClassName,
  ...rest
}: AccordionProps) {
  const [open, setOpen] = useState(initialStateOpen);

  return (
    <div>
      <Button
        onClick={() => setOpen(!open)}
        className={`flex align-middle items-center print:hidden z-0 ${sticky ? 'sticky top-0 mix-blend-difference' : ''}`}
        data-test-id="accordion-button"
        {...rest}
      >
        <div className="track-wider font-bold text-lg text-left dark:text-white text-neutral-800">
          {title}
        </div>
        <LuChevronRight
          className={`size-6 ${chevronClassName ?? ''} transition-all ${open ? 'rotate-90' : ''}`}
        />
      </Button>
      <Transition show={open} appear>
        {children}
      </Transition>
    </div>
  );
}
