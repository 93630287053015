import type { FloorRoomMapFeatures, RoomFeatures } from 'common/types';
import { isPoint } from 'generic/layers/GeometryLayer';
import { Collection, type Feature } from 'ol';
import type OLMap from 'ol/Map';
import type { Coordinate } from 'ol/coordinate';
import type { EventsKey } from 'ol/events';
import { never } from 'ol/events/condition';
import type { LineString, Point, Polygon } from 'ol/geom';
import { Modify, Translate } from 'ol/interaction';
import { LuMove } from 'react-icons/lu';
import { FormattedMessage } from 'translations/Intl';
import {
  beaconsLayer,
  beaconsModuleLayer,
  calculateCenter,
  desksLayer,
  roomLayer,
  roomOutlineSource,
} from '../../mapElements';
import { getIntersectedElements } from '../../utils/helpers';
import CanvasButton from '../CanvasButton';
import ResetGeometryButton from './components/ResetGeometryButton';
import SetGeometryButton from './components/SetGeometryButton';

interface MapActionButtonsProps {
  onGeometryClose: () => void;
  setIsSaving: (isSaving: boolean) => void;
  selectedRoomsAndFeatures: FloorRoomMapFeatures[];
  moveAndScaleButtonActive: boolean;
  setMoveAndScaleButtonActive: (active: boolean) => void;
  map: OLMap;
  setSelectedRoomsAndFeatures: (
    selectedRoomsAndFeatures: FloorRoomMapFeatures[],
  ) => void;
  olListenersKeys: EventsKey[];
  selectedRooms?: Collection<Feature<Polygon>>;
}

export default function MapActionButtons({
  onGeometryClose,
  setIsSaving,
  selectedRoomsAndFeatures,
  setMoveAndScaleButtonActive,
  moveAndScaleButtonActive,
  map,
  setSelectedRoomsAndFeatures,
  selectedRooms,
  olListenersKeys,
}: MapActionButtonsProps) {
  return (
    <div className="flex flex-col space-y-2">
      <CanvasButton
        tooltip={<FormattedMessage id="Move and scale" />}
        data-test-id="move-and-scale-button"
        active={moveAndScaleButtonActive}
        onClick={() => {
          // At this point all layers should have features and can be set
          const features = [
            ...(roomLayer.features ?? []),
            ...(beaconsLayer.features ?? []),
            ...(beaconsModuleLayer.features ?? []),
            ...(desksLayer.features ?? []),
          ];

          const intersectedBeaconsAndDesks = (selectedRooms
            ?.getArray()
            .flatMap((f) => {
              const geometry = f.getGeometry();

              if (!geometry) {
                return [];
              }

              return getIntersectedElements(
                geometry,
                // Do not get the intersected rooms as they are added separately in "mapFeatures" so they won't appear twice
                features.filter((f) => {
                  const geometry = f.getGeometry();
                  return geometry && isPoint(geometry);
                }),
              );
            }) ?? []) as FloorRoomMapFeatures[];

          const mapFeatures = [
            ...(selectedRooms?.getArray() ?? []),
            ...intersectedBeaconsAndDesks,
            ...roomOutlineSource.getFeatures(),
          ];

          const selectedRoomsAndFeatures = [
            ...intersectedBeaconsAndDesks,
            ...((selectedRooms?.getArray() ?? []) as RoomFeatures[]),
          ];

          setSelectedRoomsAndFeatures(selectedRoomsAndFeatures);

          const translate = new Translate({
            features: new Collection(mapFeatures),
          });

          const roomScaleInteraction = new Modify({
            source: roomOutlineSource,
            deleteCondition: never,
            insertVertexCondition: never,
            style: (feature) => {
              for (const modifyFeature of mapFeatures) {
                const modifyGeometry:
                  | {
                      geometry: Polygon | LineString | Point;
                      point?: Coordinate;
                      geometry0: Polygon | LineString | Point;
                      center: Coordinate;
                      minRadius: number;
                    }
                  | undefined = modifyFeature.get('modifyGeometry');
                if (modifyGeometry) {
                  const point = (
                    feature.getGeometry() as Point | undefined
                  )?.getCoordinates();

                  let modifyPoint = modifyGeometry.point;
                  if (!modifyPoint) {
                    // Save the initial geometry and vertex position
                    modifyPoint = point;
                    modifyGeometry.point = modifyPoint;
                    modifyGeometry.geometry0 = modifyGeometry.geometry;

                    const outlineGeometry = roomOutlineSource
                      .getFeatures()[0]
                      ?.getGeometry();

                    if (outlineGeometry) {
                      // Get anchor and minimum radius of vertices to be used
                      // Only use the room outline for all features as they should be scaled/rotated together
                      const { minRadius, center } =
                        calculateCenter(outlineGeometry);
                      modifyGeometry.center = center;
                      modifyGeometry.minRadius = minRadius;
                    }
                  }
                  const { center, minRadius } = modifyGeometry;

                  if (
                    modifyPoint?.[0] &&
                    modifyPoint[1] &&
                    center?.[0] &&
                    center[1] &&
                    point?.[0] &&
                    point[1]
                  ) {
                    let dx = modifyPoint[0] - center[0];
                    let dy = modifyPoint[1] - center[1];
                    const initialRadius = Math.sqrt(dx * dx + dy * dy);
                    if (initialRadius > minRadius) {
                      const initialAngle = Math.atan2(dy, dx);
                      dx = point[0] - center[0];
                      dy = point[1] - center[1];
                      const currentRadius = Math.sqrt(dx * dx + dy * dy);
                      if (currentRadius > 0) {
                        const currentAngle = Math.atan2(dy, dx);
                        const geometry = modifyGeometry.geometry0.clone();
                        geometry.scale(
                          currentRadius / initialRadius,
                          undefined,
                          center,
                        );
                        geometry.rotate(currentAngle - initialAngle, center);
                        modifyGeometry.geometry = geometry;
                      }
                    }
                  } else {
                    console.error('No modify point');
                  }
                }
              }
            },
          });

          olListenersKeys.push(
            roomScaleInteraction.on('modifystart', () => {
              for (const feature of mapFeatures) {
                feature.set(
                  'modifyGeometry',
                  { geometry: feature.getGeometry()?.clone() },
                  true,
                );
              }
            }),
          );

          // Modify interaction seems to only move the outline -> move all features within the outline
          olListenersKeys.push(
            roomOutlineSource.on('changefeature', () => {
              for (const feature of selectedRoomsAndFeatures) {
                const modifyGeometry = feature.get('modifyGeometry');
                if (modifyGeometry) {
                  feature.setGeometry(modifyGeometry.geometry);
                }
              }
            }),
          );

          olListenersKeys.push(
            roomScaleInteraction.on('modifyend', () => {
              for (const feature of mapFeatures) {
                const modifyGeometry = feature.get('modifyGeometry');
                if (modifyGeometry) {
                  feature.setGeometry(modifyGeometry.geometry);
                  feature.unset('modifyGeometry', true);
                }
              }
            }),
          );

          map.addInteraction(translate);
          map.addInteraction(roomScaleInteraction);
          setMoveAndScaleButtonActive(true);
        }}
      >
        <LuMove className="size-5" />
      </CanvasButton>
      <ResetGeometryButton onGeometryClose={onGeometryClose} />
      <SetGeometryButton
        onGeometryClose={onGeometryClose}
        features={selectedRoomsAndFeatures}
        setIsSaving={setIsSaving}
      />
    </div>
  );
}
