import { RoomTypes } from '@/common/types';
import PieChart from '@/generic/components/PieChart';
import useStore from 'model/store';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { LuChevronRight } from 'react-icons/lu';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import Button from '../../../Form/Button';
import Tooltip from '../../../Tooltip';
import FloorRow, {
  type BuildingFloors,
  type BuildingNeeds,
  getGridColsClass,
  meetingAndDesks,
  type Order,
  typeColor,
  Types,
} from './components/FloorRow';

interface OverviewRowProps {
  data: BuildingNeeds;
  order: Order;
  orderProperty: Types;
  columns: Types[];
  pieChartColors: string[];
  map?: React.JSX.Element;
  noCard?: boolean;
}

export default function OverviewRow({
  data,
  order,
  orderProperty,
  columns,
  pieChartColors,
  map,
  noCard,
}: OverviewRowProps): React.JSX.Element {
  const intl = useIntl();
  const roomTypes = useStore((state) => state.userSettings.roomTypes);
  const building = useStore((state) => state.userSettings.building);
  const floor = useStore((state) => state.userSettings.floor);
  const [toggledBuildings, setToggledBuildings] = useState<number[]>([]);
  const isBuildingToggled = toggledBuildings.includes(data.id);
  const isSelectedBuilding = building && data.id === building.Id;

  const onBuildingToggle = (buildingId: number) => {
    if (toggledBuildings.includes(buildingId)) {
      const newToggledBuildings = toggledBuildings.filter(
        (id) => id !== buildingId,
      );
      setToggledBuildings(newToggledBuildings);
    } else {
      const newToggledBuildings = [...toggledBuildings, buildingId];
      setToggledBuildings(newToggledBuildings);
    }
  };

  const extractValue = useCallback(
    (d: BuildingFloors[], type: Types) =>
      d.reduce(
        (a, b) => a + (b.total.filter((t) => t.id === type)[0]?.value ?? 0),
        0,
      ),
    [],
  );

  const formattedTotal = useMemo(() => {
    if (
      roomTypes.includes(RoomTypes.DESKS) &&
      roomTypes.includes(RoomTypes.MEETING)
    ) {
      return columns.map((type) => ({
        id: type,
        value:
          extractValue(data.floors, type) + extractValue(data.roomFloors, type),
      }));
    }

    // Only desk occupancy
    if (roomTypes.includes(RoomTypes.DESKS)) {
      return columns.map((type) => ({
        id: type,
        value: extractValue(data.floors, type),
      }));
    }
    // Only meeting room occupancy
    if (roomTypes.includes(RoomTypes.MEETING)) {
      return columns.map((type) => ({
        id: type,
        value: extractValue(data.roomFloors, type),
      }));
    }
    return columns.map((type) => ({
      id: type,
      value: 0,
    }));
  }, [columns, data, data.floors, data.roomFloors, roomTypes, extractValue]);

  // Select the building if it is set in the store (used when pressing "show" on Favorite Room/Desk)
  useEffect(() => {
    if (building) {
      setToggledBuildings([building.Id]);
    }
  }, [building]);

  return (
    <>
      <Button
        className={`w-full grid grid-cols-12 gap-2 items-center border-t border-neutral-300 dark:border-neutral-700 py-4 cursor-pointer ${
          isSelectedBuilding
            ? 'bg-primary-100 dark:bg-primary-300 dark:text-neutral-200'
            : ''
        }`}
        onClick={() => onBuildingToggle(data.id)}
        key={data.id}
      >
        <div
          className={`md:flex hidden ${
            noCard ? 'col-span-1 pl-6' : 'col-span-2'
          } h-16`}
          key={data.id}
        >
          <PieChart
            tooltipEnabled
            colors={pieChartColors}
            data={formattedTotal
              .filter((v) => v.id !== Types.TOTAL)
              .map((v) => ({
                id: intl.formatMessage({ id: v.id as IntlMessageKeys }),
                value: v.value,
              }))}
          />
        </div>
        <div
          className={`col-span-12 ${
            noCard ? 'md:col-start-3' : ''
          } md:col-span-10 items-center grid grid-cols-5 px-2 md:px-0`}
        >
          <div
            data-test-id="row-expander"
            className="flex col-span-2 items-center"
          >
            <LuChevronRight
              className={`size-6 transition-transform ${
                isBuildingToggled ? 'rotate-90' : ''
              }`}
            />
            {data.name}
          </div>
          <div
            className={`col-span-3 grid ${getGridColsClass(
              columns.length,
            )} justify-items-center`}
          >
            {formattedTotal
              .filter((f) => columns.includes(f.id))
              .map((v) => (
                <Fragment key={`${data.id}-${v.id}`}>
                  {meetingAndDesks(roomTypes) ? (
                    <Tooltip
                      content={
                        <div
                          data-test-id={`overview-${data.name}-${v.id}`}
                          className={`items-center col-span-1 ${typeColor(
                            v.id,
                          )}`}
                        >
                          {v.value}
                        </div>
                      }
                      className="whitespace-pre w-min"
                    >
                      <FormattedMessage
                        id="Rooms/Desks"
                        values={{
                          rooms: extractValue(data.roomFloors, v.id),
                          desks: extractValue(data.floors, v.id),
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <div
                      data-test-id={`overview-${data.name}-${v.id}`}
                      className={`items-center col-span-1 ${typeColor(v.id)}`}
                    >
                      {v.value}
                    </div>
                  )}
                </Fragment>
              ))}
          </div>
        </div>
      </Button>
      {isBuildingToggled && (
        <FloorRow
          data={data}
          order={order}
          orderProperty={orderProperty}
          isSelectedBuilding={isSelectedBuilding}
          floor={floor}
          columns={columns}
          map={map}
        />
      )}
    </>
  );
}
