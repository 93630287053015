import Card from 'generic/components/Card';
import { useRoomsUsageQuery } from 'graphql/types';
import { useMemo, useState } from 'react';
import { useIntl } from 'translations/Intl';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import { uuidv4 } from 'utils/uuid';
import BarOccupancy from '../BarOccupancy';
import BrushChart from '../BrushCart/BrushChart';
import ChartComparer from '../ChartComparer';
import type { ChartVariables } from '../ChartComparer/components/LineChart/LineChart';
import HeatmapOccupancy from '../HeatmapOccupancy';
import UtlizationCard from '../UtilizationCard';
import RoomSensorsGraphics from './components/RoomSensorsGraphics';

export default function RoomsAnalyticsCharts() {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [chartVariables, setChartVariables] = useState<ChartVariables[]>([
    {
      Room: null,
      Building: null,
      Floor: null,
      Id: uuidv4(),
    },
  ]);
  const [{ data: roomData, fetching }] = useRoomsUsageQuery({
    variables: useAnalyticsFilter(),
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
  });

  return (
    <div className="grid grid-cols-12 gap-2">
      <div className="col-span-12 lg:col-span-2 flex space-x-2 lg:flex-col lg:space-y-2 lg:space-x-0">
        <UtlizationCard
          data-test-id="rooms-avg-occupancy-card"
          title={intl.formatMessage({ id: 'Average Daily Occupancy' })}
          value={
            roomData?.f_history_rooms_occupancy_custom[0]
              ?.AvgPercentageUsedMeetingRooms ?? 0
          }
          loading={fetching}
        />
        <UtlizationCard
          data-test-id="rooms-highest-hourly-occupancy-card"
          title={intl.formatMessage({ id: 'Max Hourly Occupancy' })}
          value={
            roomData?.f_history_rooms_occupancy_custom[0]
              ?.MaxPercentageUsedMeetingRooms ?? 0
          }
          loading={fetching}
        />
      </div>
      <Card
        noPadding
        className="h-96 relative col-span-12 lg:col-span-10"
        title={intl.formatMessage({ id: '[Rooms] Daily Occupancy' })}
      >
        <BrushChart meetingRoomOccupancy />
      </Card>
      <Card
        noPadding
        className="h-96 relative col-span-12 lg:col-span-6"
        title={intl.formatMessage({ id: '[Rooms] Hourly Occupancy' })}
      >
        <BarOccupancy meetingRoomOccupancy />
      </Card>
      <Card
        noPadding
        className="h-96 relative col-span-12 lg:col-span-6"
        title={intl.formatMessage({ id: '[Rooms] Hourly Occupancy' })}
      >
        <HeatmapOccupancy meetingRoomOccupancy />
      </Card>
      <Card
        noPadding
        className="relative col-span-12"
        title={intl.formatMessage({ id: '[Rooms] Daily Occupancy Comparison' })}
      >
        <ChartComparer
          chartVariables={chartVariables}
          setChartVariables={setChartVariables}
          meetingRoomOccupancy
        />
      </Card>
      <RoomSensorsGraphics />
    </div>
  );
}
