import StyledButton from 'generic/components/Form/Button/StyledButton';
import Table, { ACTION_ID } from 'generic/components/Table/Table';
import Tooltip from 'generic/components/Tooltip';
import { FormattedMessage, useIntl } from 'translations/Intl';
import { formattedDistance } from 'utils/date';

import Loader from '@/generic/components/layout/BarLoader';
import { useOrganizationFirmwaresQuery } from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import type { ColumnDef } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { LuCirclePlus, LuCloudDownload } from 'react-icons/lu';

import AddFirmwaresModal, {
  type Firmwares,
} from './components/AddFirmwaresModal/AddFirmwaresModal';
import {
  ActionCell,
  ArchivedCell,
  ModulesCell,
  OrganizationsCell,
} from './components/Cells';
import DownloadFirmwareModal from './components/DownloadFirmwareModal/DownloadFirmwareModal';

export default function FirmwaresView() {
  const [openAddFirmwareModal, setOpenAddFirmwareModal] = useState(false);
  const [openDownloadFirmwareModal, setOpenDownloadFirmwareModal] =
    useState(false);
  const [selectedFirmwarePackage, setSelectedFirmwarePackage] = useState<
    Firmwares | undefined
  >(undefined);

  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();

  const [{ data: firmwares, fetching: loadingFirmwares }] =
    useOrganizationFirmwaresQuery({
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.READ_ALL, ['FirmwarePackages']),
        [hasuraHeader],
      ),
    });

  const defaultColumns: ColumnDef<Firmwares>[] = useMemo(
    () => [
      {
        accessorKey: 'Version',
        id: 'version',
        header: intl.formatMessage({ id: 'Version' }),
      },
      {
        accessorKey: 'IsArchived',
        id: 'archived',
        header: intl.formatMessage({ id: 'Status' }),
        accessorFn: (row) =>
          intl.formatMessage({ id: row.IsArchived ? 'Archived' : 'Active' }),
        cell: ArchivedCell,
      },
      {
        id: 'organizations',
        header: intl.formatMessage({ id: 'Organizations' }),
        cell: OrganizationsCell,
      },
      {
        id: 'modules',
        header: intl.formatMessage({ id: 'Modules' }),
        cell: ModulesCell,
      },
      {
        id: 'beaconCount',
        header: intl.formatMessage({ id: 'Number of beacons' }),
        cell: ({ row }) =>
          row.original.MqttBeacons_aggregate.aggregate?.count ?? 0,
      },
      {
        header: intl.formatMessage({ id: 'Created at' }),
        id: 'createdAt',
        accessorFn: (row) => new Date(row.CreatedAt),
        cell: ({ row }) => formattedDistance(new Date(row.original.CreatedAt)),
        enableColumnFilter: false,
      },
      {
        header: intl.formatMessage({ id: 'Last updated' }),
        id: 'updatedAt',
        accessorFn: (row) => new Date(row.UpdatedAt),
        cell: ({ row }) => formattedDistance(new Date(row.original.UpdatedAt)),
        enableColumnFilter: false,
      },
      {
        enableGrouping: false,
        id: ACTION_ID,
        cell: ({ row }) => (
          <ActionCell
            row={row}
            setSelectedFirmwarePackage={setSelectedFirmwarePackage}
            setOpenAddFirmwareModal={setOpenAddFirmwareModal}
          />
        ),
      },
    ],
    [intl.formatMessage],
  );

  return (
    <>
      <Loader loading={loadingFirmwares} />
      <Table<Firmwares>
        id="firmwarepackages"
        columns={defaultColumns}
        data={firmwares?.FirmwarePackages ?? []}
        getRowId={(row) => row.Id}
        loading={loadingFirmwares}
        initialState={{
          columnFilters: [
            {
              id: 'archived',
              value: intl.formatMessage({
                id: 'Active',
              }),
            },
          ],
        }}
        renderAdditionalAction={
          <div className="flex space-x-2">
            <Tooltip
              content={
                <StyledButton
                  onClick={() => setOpenDownloadFirmwareModal(true)}
                >
                  <LuCloudDownload className="size-5" />
                </StyledButton>
              }
            >
              <FormattedMessage id="Download module firmware" />
            </Tooltip>
            <Tooltip
              content={
                <StyledButton onClick={() => setOpenAddFirmwareModal(true)}>
                  <LuCirclePlus className="size-5" />
                </StyledButton>
              }
            >
              <FormattedMessage id="Add firmware package" />
            </Tooltip>
          </div>
        }
      />

      <AddFirmwaresModal
        selectedFirmwarePackage={selectedFirmwarePackage}
        setSelectedFirmwarePackage={setSelectedFirmwarePackage}
        open={openAddFirmwareModal}
        setOpen={setOpenAddFirmwareModal}
      />

      <DownloadFirmwareModal
        open={openDownloadFirmwareModal}
        setOpen={setOpenDownloadFirmwareModal}
      />
    </>
  );
}
