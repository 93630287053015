import useStore from 'model/store';
import { useIntl } from 'translations/Intl';

import { ClimateType } from '@/common/types';
import Select from '@/generic/components/Form/Select';
import { useClimateSensorsNameQuery } from '@/graphql/types';
import { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';

export default function ClimateSensorSelection() {
  const intl = useIntl();
  const userRoles = useStore((state) => state.user?.roles);
  const [{ data }] = useClimateSensorsNameQuery();
  const selectedClimateTypes = useStore(
    (state) => state.userSettings.climateTypes,
  );
  const setClimateTypes = useStore((state) => state.userApi.setClimateTypes);

  return (
    <Select
      value={selectedClimateTypes}
      onChangeSelected={(selected) => setClimateTypes(selected)}
      options={
        data?.SensorTypes.map((s) => s.Name)
          .filter((s): s is ClimateType =>
            Object.values(ClimateType).includes(s as ClimateType),
          )
          .filter(
            (s) =>
              // TODO: Remove again when stable
              ![ClimateType.HUMIDITY].includes(s) ||
              userRoles?.includes(HasuraPermissions.READ_ALL),
          )
          .sort((a, b) =>
            intl
              .formatMessage({ id: a })
              .localeCompare(intl.formatMessage({ id: b })),
          ) ?? []
      }
      renderValue={(t) => (t ? intl.formatMessage({ id: t }) : '')}
    />
  );
}
