import Input from 'generic/components/Form/Input';
import Transition from 'generic/components/Transition';
import type { ReportMetricsQuery } from 'graphql/types';
import { useEffect, useState } from 'react';
import { LuScale } from 'react-icons/lu';
import { FormattedMessage, useIntl } from 'translations/Intl';

interface WeightInputProps {
  data?: ReportMetricsQuery['ReportPages'][number]['ReportPageMetrics'][number]['MetricLimits'][number];
  valid: boolean;
  callback: (weight: number) => void;
}

export default function WeightInput({
  callback,
  data,
  valid,
  ...rest
}: WeightInputProps) {
  const intl = useIntl();
  const [weight, setWeight] = useState(data?.Weight ?? 100);

  // Set the initial values when showing the input
  // biome-ignore lint/correctness/useExhaustiveDependencies: Only run once in order to set initial values
  useEffect(() => {
    callback(weight);
  }, []);

  return (
    <>
      <Input
        renderIcon={({ className }) => <LuScale className={className} />}
        type="number"
        value={weight}
        min={0}
        max={100}
        label={intl.formatMessage({
          id: 'Weight (%)',
        })}
        tooltipText={intl.formatMessage({
          id: 'weight-description',
        })}
        onChangeValue={(v) => {
          const weightValue = Number.parseInt(v, 10);
          setWeight(weightValue);
          callback(weightValue);
        }}
        required
        {...rest}
      />
      <Transition show={!valid} className="flex justify-end">
        <p className="text-xs text-red-500" data-test-id="weight-error">
          <FormattedMessage id="Total weight needs to be 100%" />
        </p>
      </Transition>
    </>
  );
}
