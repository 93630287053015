import { Themes } from '@/common/types';
import LogoLight from '@/img/logo.svg';
import LogoDark from '@/img/logo_dark.svg';
import useStore from '@/model/store';
import type { ImgHTMLAttributes } from 'react';

export default function CustomerLogo({
  ...rest
}: ImgHTMLAttributes<HTMLImageElement>) {
  const theme = useStore((state) => state.userSettings.theme);
  const { light, dark } = useStore(
    (state) => state.organizationSettings.organizationLogo,
  );

  return (
    <img
      className="h-8"
      src={theme.color === Themes.DARK ? dark : light}
      {...rest}
      alt="organization logo"
      onError={(e) => {
        console.error('Failed to load custom organization logo');
        e.currentTarget.src =
          theme.color === Themes.DARK ? LogoDark : LogoLight;
      }}
    />
  );
}
