import { type MarginProps, Themes } from '@/common/types';
import LoadingSpinner from '@/generic/components/LoadingSpinner';
import Tooltip from '@/generic/components/Tooltip';
import Transition from '@/generic/components/Transition';
import {
  type SensorHistoryQuery,
  useSensorHistoryQuery,
} from '@/graphql/types';
import useStore from '@/model/store';
import { FormattedMessage } from '@/translations/Intl';
import format from '@/utils/format';
import getColor, { primaryColorToRGB } from '@/utils/getColor';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import { curveMonotoneX } from '@visx/curve';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import { GridColumns, GridRows } from '@visx/grid';
import { PatternLines } from '@visx/pattern';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleTime } from '@visx/scale';
import { AreaClosed, Bar, Line } from '@visx/shape';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import { bisector, extent, max } from 'd3-array';
import { addMinutes, endOfDay, subWeeks } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LuUndo } from 'react-icons/lu';
import { dateAtUTC, lower, upper } from 'utils/date';

const patternId = 'brush_pattern';

// Util
const formatDate = (date: Date) => format(date, 'eeeeee do LLL, p');

// Accessors
const getDate = (d: TooltipData) => new Date(d.date);
const getValue = (d: TooltipData) => d.value;
const bisectDate = bisector<TooltipData, Date>(getDate).left;

interface ResponsiveSensorChartProps {
  margin?: MarginProps;
  sensorId: number;
}

interface SensorChartProps extends ResponsiveSensorChartProps {
  height: number;
  width: number;
}

export type ChartData = SensorHistoryQuery['SensorHistories'][number];
type TooltipData = { value: number; unit: string; date: Date };

function SensorChart({
  height,
  width,
  margin = { top: 20, left: 1, right: 1, bottom: 2 },
  sensorId,
}: SensorChartProps) {
  const accentColor = primaryColorToRGB(400);
  const [startPosition, setStartPosition] = useState(0);
  const [endPosition, setEndPosition] = useState(0);
  const [filteredData, setFilteredData] = useState<TooltipData[]>([]);
  const { tooltipData, tooltipLeft, tooltipTop, showTooltip, hideTooltip } =
    useTooltip<TooltipData>();
  const hasuraHeader = useHasuraHeader();
  const theme = useStore((state) => state.userSettings.theme);
  const userRoles = useStore((state) => state.user)?.roles;
  const [dateFrom, setDateFrom] = useState(subWeeks(new Date(), 2));
  const [dateTo, setDateTo] = useState(new Date());
  const [dateChanged, setDateChanged] = useState(false);

  // Bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = Math.max(height - margin.top - margin.bottom, 0);

  const [{ data: sensorData, fetching: loadingSensor }] = useSensorHistoryQuery(
    {
      variables: useMemo(
        () => ({
          Start: subWeeks(new Date(), 2),
          SensorId: sensorId,
        }),
        [sensorId],
      ),
      context: useMemo(
        () =>
          hasuraHeader(
            userRoles?.includes(HasuraPermissions.READ_ALL)
              ? HasuraPermissions.READ_ALL
              : HasuraPermissions.VIEW_STATUS,
          ),
        [hasuraHeader, userRoles],
      ),
    },
  );

  const data = useMemo(() => {
    const tempData: TooltipData[] = [];
    for (const d of sensorData?.SensorHistories ?? []) {
      for (
        let i = lower(d.Duration);
        i <= upper(d.Duration);
        i = addMinutes(i, 15)
      ) {
        tempData.push({
          value: d.Value,
          unit: d.Sensor.SensorType.Unit ?? '',
          // SensorHistories "Duration" is saved in local time zone, convert it back to UTC
          // as the browser will then display it in local time again
          date: dateAtUTC(i),
        });
      }
    }
    return tempData.length === 0
      ? [{ value: 0, unit: '', date: new Date() }]
      : tempData;
  }, [sensorData?.SensorHistories]);

  // Scales
  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [margin.left, xMax + margin.left],
        domain: extent(filteredData, getDate) as [Date, Date],
      }),
    [xMax, filteredData, margin.left],
  );
  const valueScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax + margin.top, margin.top],
        domain: [0, max(filteredData, getValue) || 0],
        nice: true,
      }),
    [yMax, filteredData, margin.top],
  );

  const getDataForMousePosition = (
    event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>,
  ) => {
    const { x } = localPoint(event) || { x: 0 };
    const x0 = dateScale.invert(x);
    const index = bisectDate(data, x0, 1);
    return data[index];
  };

  const resetPosition = useCallback(() => {
    setStartPosition(0);
    setEndPosition(0);
  }, []);

  const onMouseUp = useCallback(() => {
    if (startPosition && endPosition) {
      const start = endPosition > startPosition ? startPosition : endPosition;
      const end = endPosition > startPosition ? endPosition : startPosition;
      // Set new position in order to filter data
      setDateFrom(dateScale.invert(start));
      setDateTo(dateScale.invert(end));
      setDateChanged(true);
    }
    resetPosition();
  }, [resetPosition, startPosition, endPosition, dateScale]);

  // Tooltip handler
  const handleTooltip = useCallback(
    (
      event:
        | React.TouchEvent<SVGRectElement>
        | React.MouseEvent<SVGRectElement>,
    ) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = dateScale.invert(x);
      const index = bisectDate(data, x0, 1);
      const d0 = data[index - 1];
      const d1 = data[index];
      let d = d0;
      if (d1 && getDate(d1) && d0) {
        d =
          x0.valueOf() - getDate(d0).valueOf() >
          getDate(d1).valueOf() - x0.valueOf()
            ? d1
            : d0;
      }

      if (d0 && startPosition) {
        setEndPosition(dateScale(getDate(d0)));
      }

      if (d) {
        showTooltip({
          tooltipData: d,
          tooltipLeft: x,
          tooltipTop: valueScale(getValue(d)),
        });
      }
    },
    [showTooltip, valueScale, dateScale, data, startPosition],
  );

  const resetDates = useCallback(() => {
    resetPosition();
    setDateFrom(subWeeks(new Date(), 2));
    setDateTo(new Date());
    setDateChanged(false);
  }, [resetPosition]);

  useEffect(() => {
    const filteredEntries = data.filter(
      (d) => d.date >= dateFrom && d.date <= dateTo,
    );

    if (filteredEntries.length === 1 && filteredEntries[0]) {
      // Must add a second entry, because AreaChart needs 2 entries to show some data
      setFilteredData([
        filteredEntries[0],
        {
          ...filteredEntries[0],
          date: endOfDay(filteredEntries[0].date),
        },
      ]);
    } else {
      setFilteredData(filteredEntries);
    }
  }, [data, dateFrom, dateTo]);

  return (
    <>
      <div className="flex items-center space-x-1">
        <div>
          <FormattedMessage id="Dates" />: {formatDate(dateFrom)} -{' '}
          {formatDate(dateTo)}
        </div>
        <Transition show={dateChanged}>
          <Tooltip
            content={
              <p>
                <LuUndo
                  className="size-3 cursor-pointer text-primary-500 hover:text-primary-600 transition-colors"
                  onClick={resetDates}
                />
              </p>
            }
          >
            <FormattedMessage id="Reset" />
          </Tooltip>
        </Transition>
      </div>
      <div className="relative">
        <LoadingSpinner loading={loadingSensor} />
        <svg width={width} height={height}>
          <LinearGradient
            id="area-gradient"
            from={accentColor}
            to={accentColor}
            toOpacity={0.3}
          />
          <PatternLines
            id={patternId}
            height={8}
            width={8}
            stroke={accentColor}
            strokeWidth={1}
            orientation={['diagonal']}
          />
          <GridRows
            left={margin.left}
            scale={valueScale}
            width={innerWidth}
            strokeDasharray="1,3"
            stroke={accentColor}
            strokeOpacity={0}
            pointerEvents="none"
          />
          <GridColumns
            top={margin.top}
            scale={dateScale}
            height={innerHeight}
            strokeDasharray="1,3"
            stroke={accentColor}
            strokeOpacity={0.2}
            pointerEvents="none"
          />
          <AreaClosed<TooltipData>
            data={filteredData}
            x={(d) => dateScale(getDate(d)) ?? 0}
            y={(d) => valueScale(getValue(d)) ?? 0}
            yScale={valueScale}
            strokeWidth={1}
            stroke="url(#area-gradient)"
            fill="url(#area-gradient)"
            curve={curveMonotoneX}
          />
          <Bar
            x={margin.left}
            y={margin.top}
            width={xMax}
            height={yMax}
            fill="transparent"
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
            onMouseDown={(evt) => {
              const data = getDataForMousePosition(evt);

              if (data) {
                setStartPosition(dateScale(getDate(data)));
              }
            }}
            onMouseUp={onMouseUp}
          />
          {/* Bar for allowing drilling of data */}
          <Bar
            x={endPosition > startPosition ? startPosition : endPosition}
            y={margin.top}
            width={
              endPosition
                ? endPosition > startPosition
                  ? endPosition - startPosition
                  : startPosition - endPosition
                : 0
            }
            height={innerHeight}
            fill={
              theme.color === Themes.LIGHT
                ? getColor('NEUTRAL800')
                : getColor('NEUTRAL200')
            }
            opacity={0.1}
            // This is triggered when moving the cursor backwards
            onMouseUp={onMouseUp}
            onMouseMove={handleTooltip}
          />
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                stroke={accentColor}
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop ?? 0 + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={accentColor}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
        </svg>
        {tooltipData && (
          <div>
            <TooltipWithBounds
              top={tooltipTop ?? 0 - 12}
              left={tooltipLeft ?? 0 + 12}
              style={{
                ...defaultStyles,
                background:
                  theme.color === Themes.LIGHT
                    ? getColor('WHITE')
                    : getColor('NEUTRAL900'),
              }}
            >
              <p className="dark:text-neutral-200">{`${getValue(tooltipData)} ${tooltipData.unit}`}</p>
            </TooltipWithBounds>
            <TooltipWithBounds
              top={yMax + 30}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                backgroundColor:
                  theme.color === Themes.LIGHT
                    ? getColor('WHITE')
                    : getColor('NEUTRAL900'),
                minWidth: 72,
                textAlign: 'center',
              }}
            >
              <p className="dark:text-neutral-200">
                {formatDate(getDate(tooltipData))}
              </p>
            </TooltipWithBounds>
          </div>
        )}
      </div>
    </>
  );
}

export default function ResponsiveSensorChart(
  props: ResponsiveSensorChartProps,
) {
  const { sensorId } = props;
  return (
    <ParentSize>
      {({ height, width }) => (
        <SensorChart
          {...props}
          height={height}
          width={width}
          sensorId={sensorId}
        />
      )}
    </ParentSize>
  );
}
