import { FormattedMessage } from 'translations/Intl';

import { Action } from '@/common/types';
import Button from '@/generic/components/Form/Button';
import { useCallback, useEffect } from 'react';
import { LuLoaderCircle } from 'react-icons/lu';

interface DataTestId {
  proceed: string;
  cancel: string;
}
interface ModalFooterProps {
  onProceed: () => void;
  onCancel: () => void;
  disabled?: boolean;
  action: Action;
  cancel?: string | React.JSX.Element;
  proceed?: string | React.JSX.Element;
  loading?: string | React.JSX.Element;
  customButton?: null | React.JSX.Element;
  isLoading?: boolean;
  dataTestId?: DataTestId;
}

export default function ModalFooter({
  cancel = <FormattedMessage id="Cancel" />,
  proceed = <FormattedMessage id="Save" />,
  loading = <FormattedMessage id="Loading" />,
  customButton,
  onProceed,
  onCancel,
  disabled = false,
  action,
  isLoading = false,
  dataTestId = {
    proceed: 'save',
    cancel: 'cancel',
  },
}: ModalFooterProps): React.JSX.Element {
  const colorClass = `
  ${
    action === Action.REMOVE
      ? 'bg-red-600 hover:bg-red-700 disabled:hover:bg-red-600 focus:ring-red-500'
      : ''
  } 
  ${
    action === Action.UPDATE
      ? 'bg-blue-600 hover:bg-blue-700 disabled:hover:bg-blue-600 focus:ring-blue-500'
      : ''
  } 
  ${
    action === Action.ADD
      ? 'bg-green-600 hover:bg-green-700 disabled:hover:bg-green-600 focus:ring-green-500'
      : ''
  }`;

  // Add functionality to run the onProceed function when pressing "Enter"
  // Still possible to not send it with enter, when also pressing shift in order to allow new lines in textarea
  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.code === 'Enter' && !disabled && !e.shiftKey) {
        onProceed();
      }
    },
    [disabled, onProceed],
  );

  useEffect(() => {
    if (!disabled) {
      document.addEventListener('keydown', keyPress);
    }

    return () => document.removeEventListener('keydown', keyPress);
  }, [disabled, keyPress]);

  return (
    <div
      className="flex w-full space-y-2 md:space-y-0 md:space-x-2 flex-col justify-end md:flex-row flex-wrap"
      data-test-id="modal-footer"
    >
      {!isLoading && customButton}
      {!isLoading && (
        <Button
          tabIndex={0}
          id="save"
          onClick={onProceed}
          disabled={disabled}
          data-test-id={dataTestId.proceed}
          className={`
            ${colorClass} 
            mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 items-center  text-white focus:outline-hidden focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:w-auto text-base md:text-sm`}
        >
          {proceed}
        </Button>
      )}
      {isLoading && (
        <Button
          id="loading"
          disabled
          className={`
            ${colorClass} 
              mt-3 w-full transition-all inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 items-center  text-white focus:outline-hidden focus:ring-2 focus:ring-offset-2 sm:mt-0 sm:w-auto text-base md:text-sm`}
        >
          <LuLoaderCircle
            className={`animate-spin size-5 text-neutral-200 ${
              action === Action.REMOVE ? 'text-red-400' : ''
            } 
            ${action === Action.UPDATE ? 'text-blue-400' : ''} 
            ${action === Action.ADD ? 'text-green-400' : ''}`}
          />
          <span className="ml-2">{loading}</span>
        </Button>
      )}
      <Button
        id="cancel"
        onClick={onCancel}
        data-test-id={dataTestId.cancel}
        className="mt-3 w-full inline-flex justify-center rounded-md border border-neutral-300 dark:border-neutral-700 shadow-xs px-4 py-2 items-center bg-white dark:bg-neutral-700  text-neutral-700 dark:text-neutral-200 hover:bg-neutral-50 dark:hover:bg-neutral-800 focus:outline-hidden focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto text-base md:text-sm"
      >
        {cancel}
      </Button>
    </div>
  );
}
