import type { MarginProps } from '@/common/types';
import { REPORTING_RIGHT_MARGIN } from '@/pages/ReportingView/components/Reports/Reports';
import getColor from '@/utils/getColor';
import { Annotation, Connector, Label, LineSubject } from '@visx/annotation';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleOrdinal } from '@visx/scale';
import AnimatedRect from 'generic/components/Chart/AnimatedRect';
import { useReportComplianceQuery } from 'graphql/types';
import { motion } from 'motion/react';
import { useMemo } from 'react';
import { useIntl } from 'translations/Intl';
import useReportFilter from 'utils/graphql/useReportFilter';

interface ResponsiveComplianceChart {
  margin?: MarginProps;
  reportPageId: string;
}

interface ComplianceChart extends ResponsiveComplianceChart {
  height: number;
  width: number;
}

function ComplianceChart({
  height,
  width,
  margin = {
    top: 30,
    left: 0,
    right: REPORTING_RIGHT_MARGIN,
    bottom: 60,
  },
  reportPageId,
}: ComplianceChart) {
  const intl = useIntl();

  const reportFilter = useReportFilter();
  const [{ data }] = useReportComplianceQuery({
    ...reportFilter,
    variables: { ...reportFilter.variables, ReportPageId: reportPageId },
  });

  const value =
    data?.ReportPageMetric[0]?.ReportReportPageMetrics[0]?.Value ?? 0;

  const limit = data?.ReportPageMetric[0]?.MetricLimits[0];

  const reportValue = useMemo(
    () =>
      limit?.DivideByTargetValue
        ? (value * 100) / limit.TargetValue
        : ((limit?.TargetValue ?? 1) * 100) / value,
    [limit?.TargetValue, limit?.DivideByTargetValue, value],
  );

  const keys = ['poor', 'acceptable', 'good'];

  // Bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);

  // Scales
  const xScale = scaleLinear<number>({
    range: [0, xMax],
    domain: [0, 100],
    nice: true,
  });

  const colorScale = scaleOrdinal({
    domain: keys,
    range: [getColor('RED'), getColor('YELLOW'), getColor('GREEN')],
  });

  const x = useMemo(() => xScale(reportValue), [reportValue, xScale]);

  const internalHeight = height - margin.top - margin.bottom;

  const getColorForValue = (value: number) => {
    if (value < 80) return colorScale('poor');
    if (value < 90) return colorScale('acceptable');
    return colorScale('good');
  };

  return (
    <div className="relative">
      <svg width={width} height={height}>
        <g>
          <AnimatedRect
            horizontal
            bar={{
              width: xScale(80),
              height: internalHeight,
              y: 0,
              x: 0,
              color: colorScale('poor'),
            }}
          />
          <AnimatedRect
            horizontal
            bar={{
              width: xScale(90) - xScale(80),
              height: internalHeight,
              y: 0,
              x: xScale(80),
              color: colorScale('acceptable'),
            }}
          />
          <AnimatedRect
            horizontal
            bar={{
              width: xScale(100) - xScale(90),
              height: internalHeight,
              y: 0,
              x: xScale(90),
              color: colorScale('good'),
            }}
          />
          <motion.g
            key={x}
            initial={{ opacity: 0, x: 0 }}
            animate={{
              opacity: 1,
              x,
            }}
            transition={{ ease: 'easeInOut', duration: 1 }}
          >
            <Annotation
              dx={reportValue > 50 ? -20 : 20}
              dy={20}
              y={internalHeight / 2}
            >
              <Connector stroke={getColorForValue(reportValue)} type="line" />
              <Label
                backgroundFill="white"
                showAnchorLine={false}
                anchorLineStroke={getColorForValue(reportValue)}
                backgroundProps={{ stroke: getColorForValue(reportValue) }}
                fontColor={getColorForValue(reportValue)}
                subtitle={`${reportValue?.toFixed(2)}%`}
                title={intl.formatMessage({ id: 'Compliance' })}
                width={100}
              />
              <LineSubject
                orientation="vertical"
                stroke={getColorForValue(reportValue)}
                min={0}
                max={internalHeight}
              />
            </Annotation>
          </motion.g>
        </g>
      </svg>
    </div>
  );
}

export default function ResponsiveComplianceChart(
  props: ResponsiveComplianceChart,
) {
  return (
    <div className="h-24">
      <ParentSize>
        {({ height, width }) => (
          <ComplianceChart {...props} width={width} height={height} />
        )}
      </ParentSize>
    </div>
  );
}
