import { Action } from '@/common/types';
import Input from '@/generic/components/Form/Input';
import Modal from '@/generic/components/Modal';
import ModalFooter from '@/generic/components/ModalFooter';
import Subtitle from '@/generic/components/Subtitle';
import {
  type SensorPoliciesQuery,
  useDeleteSensorPolicyMutation,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import useToast from '@/utils/graphql/useToast';
import { useState } from 'react';
import { LuTrash } from 'react-icons/lu';
import { FormattedMessage, useIntl } from 'translations/Intl';

interface RemoveSensorPolicyModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  policyToRemove?: SensorPolicy;
}

export type SensorPolicy = SensorPoliciesQuery['SensorPolicies'][number];

export default function RemoveSensorPolicyModal({
  open,
  setOpen,
  policyToRemove,
}: RemoveSensorPolicyModalProps): React.JSX.Element {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [confirmDelete, setConfirmDelete] = useState('');
  const [{ fetching }, deletePolicy] = useDeleteSensorPolicyMutation();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'Confirm action',
      })}
      action={Action.REMOVE}
      open={open}
      setShowModal={setOpen}
      footer={
        <ModalFooter
          disabled={confirmDelete !== policyToRemove?.Name}
          proceed={intl.formatMessage({
            id: 'Confirm',
          })}
          isLoading={fetching}
          action={Action.REMOVE}
          onProceed={() => {
            if (confirmDelete === policyToRemove?.Name) {
              deletePolicy(
                {
                  Id: policyToRemove.Id,
                },
                hasuraHeader(HasuraPermissions.WRITE_ORGANIZATION),
              ).then((data) => {
                toast(data, {
                  message: {
                    type: 'success',
                    content: intl.formatMessage(
                      { id: 'Deleted sensor policy' },
                      { name: policyToRemove.Name },
                    ),
                  },
                });
                setOpen(false);
                setConfirmDelete('');
              });
            }
          }}
          onCancel={() => {
            setOpen(false);
            setConfirmDelete('');
          }}
        />
      }
    >
      <Subtitle
        value={intl.formatMessage({
          id: 'Are you sure to remove this sensor policy?',
        })}
        className="text-base pb-2"
      />
      <p className="pb-2">
        <FormattedMessage
          id="Enter the sensor policy {name} in order to delete it"
          values={{
            name: <b>{policyToRemove?.Name}</b>,
          }}
        />
      </p>

      <Input
        type="text"
        value={confirmDelete}
        data-test-id="confirm-delete-sensor-policy-input"
        renderIcon={({ className }) => <LuTrash className={className} />}
        onChangeValue={(input: string) => setConfirmDelete(input)}
        placeholder={policyToRemove?.Name ?? ''}
        required
      />
    </Modal>
  );
}
