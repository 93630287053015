import Card from 'generic/components/Card';
import Transition from 'generic/components/Transition/Transition';
import {
  useLocationIndependentSensorsQuery,
  useRoomOccupancySensorCountQuery,
} from 'graphql/types';
import useStore from 'model/store';
import { useIntl } from 'translations/Intl';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import RoomOccupancyDistribution from '../RoomOccupancyDistribution';
import RoomOccupancySensorCount from '../RoomOccupancySensorCount';

import { differenceInDays } from 'date-fns';
import { useMemo } from 'react';
import { LuCircleAlert } from 'react-icons/lu';

export default function RoomsAnalyticsCharts() {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const room = useStore((state) => state.userSettings.room);
  const dateRange = useStore((state) => state.userSettings.dateRange);
  const dateFrom = useMemo(() => new Date(dateRange.start), [dateRange.start]);
  const dateTo = useMemo(
    () => (dateRange.end ? new Date(dateRange.end) : null),
    [dateRange.end],
  );
  const [{ data: sensorData }] = useLocationIndependentSensorsQuery({
    variables: {
      // Use 0 as default value as this will return an empty set
      // if there is no room. Otherwise it will throw a graphql error
      // Can't use pause: room?.Id as it should hide the card if there is no room
      RoomId: room?.Id ?? 0,
    },
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
  });

  const [
    {
      data: roomOccupancySensorCount,
      fetching: loadingRoomOccupancySensorCount,
    },
  ] = useRoomOccupancySensorCountQuery({
    variables: {
      RoomId: room?.Id ?? 0,
      Start: dateFrom,
      End: dateTo,
    },
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
      [hasuraHeader],
    ),
    pause: !room?.Id,
  });

  const timePeriodUnderOneMonth =
    dateTo && differenceInDays(dateTo, dateFrom) <= 31;

  return (
    <>
      <Transition
        className=" relative col-span-8"
        show={(sensorData?.RoomSensor_aggregate.aggregate?.count ?? 0) > 0}
      >
        {timePeriodUnderOneMonth ? (
          <Card
            className="h-96"
            noPadding
            title={intl.formatMessage(
              {
                id: 'Room occupancy history',
              },
              { room: room?.Name },
            )}
          >
            <RoomOccupancySensorCount
              roomOccupancySensorCount={
                roomOccupancySensorCount?.f_history_room_occupancy_15m
              }
              loadingRoomOccupancySensorCount={loadingRoomOccupancySensorCount}
            />
          </Card>
        ) : (
          <Card
            className="h-24"
            noPadding
            title={intl.formatMessage(
              {
                id: 'Room occupancy history',
              },
              { room: room?.Name },
            )}
          >
            <div className="w-full h-full flex items-center justify-center gap-1 text-xs">
              <LuCircleAlert className="size-5" />
              <span>
                {intl.formatMessage({
                  id: 'Please select a timeframe <= 31 days',
                })}
              </span>
            </div>
          </Card>
        )}
      </Transition>
      <Transition
        className=" relative col-span-4"
        show={(sensorData?.RoomSensor_aggregate.aggregate?.count ?? 0) > 0}
      >
        {timePeriodUnderOneMonth ? (
          <Card
            className="h-96"
            noPadding
            title={intl.formatMessage(
              {
                id: 'Room occupancy distribution',
              },
              { room: room?.Name },
            )}
          >
            <RoomOccupancyDistribution
              roomOccupancySensorCount={
                roomOccupancySensorCount?.f_history_room_occupancy_15m
              }
              loadingRoomOccupancySensorCount={loadingRoomOccupancySensorCount}
            />
          </Card>
        ) : (
          <Card
            className="h-24"
            noPadding
            title={intl.formatMessage(
              {
                id: 'Room occupancy distribution',
              },
              { room: room?.Name },
            )}
          >
            <div className="w-full h-full flex items-center justify-center gap-1 text-xs">
              <LuCircleAlert className="size-5" />
              <span>
                {intl.formatMessage({
                  id: 'Please select a timeframe <= 31 days',
                })}
              </span>
            </div>
          </Card>
        )}
      </Transition>
    </>
  );
}
