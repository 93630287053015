import { ExtendedMap } from '@/generic/components/BasicMap';
import Map from '@/generic/components/Map';
import BaseLayer from '@/generic/layers/BaseLayer';
import type Layer from '@/generic/layers/Layer';
import format from '@/utils/format';
import { primaryColorToRGBArray } from '@/utils/getColor';
import { TooltipWithBounds } from '@visx/tooltip';
import { DeviceTypes, GeometryType } from 'common/types';
import Card from 'generic/components/Card';
import LivePing from 'generic/components/LivePing';
import LuminaireIcon from 'generic/components/LuminaireIcon';
import Subtitle from 'generic/components/Subtitle';
import GeometryLayer from 'generic/layers/GeometryLayer';
import { useMqttAssetTrackerHistoryQuery } from 'graphql/types';
import type { MapBrowserEvent } from 'ol';
import { unByKey } from 'ol/Observable';
import type { EventsKey } from 'ol/events';
import { MultiPoint, Point } from 'ol/geom';
import { Circle, Fill, Stroke, Style } from 'ol/style';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'translations/Intl';
import { formattedDistance } from 'utils/date';
import useHasuraHeader, {
  HasuraPermissions,
} from 'utils/graphql/useHasuraHeaders';
import groupBy from 'utils/groupBy';
import useDeviceDetect from 'utils/useDeviceDetect';
import type { AssetData } from '../Cells';
import type {
  Asset,
  AssetHistoryFeatureType,
} from './components/AssetHistoryPopup';
import AssetHistoryPopup from './components/AssetHistoryPopup';
import TimeLine from './components/TimeLine';

const resetPosition = (assetData: AssetData) => ({
  id: assetData.Id,
  xCoordinate: assetData.XCoordinate,
  yCoordinate: assetData.YCoordinate,
  floor: {
    id: assetData.MqttGateway?.Floor?.Id ?? 0,
    number: assetData.MqttGateway?.Floor?.Number ?? 0,
  },
  buildingName: assetData.MqttGateway?.Floor?.Building?.Name ?? '',
  roomName: assetData.Room?.Name,
});

interface AssetHistoryProps {
  assetData: AssetData;
}

export default function AssetHistory({ assetData }: AssetHistoryProps) {
  const hasuraHeader = useHasuraHeader();
  const intl = useIntl();
  const { isMobile } = useDeviceDetect();
  const [selectedFloorId, setSelectedFloorId] = useState<number | undefined>(
    assetData.MqttGateway?.Floor?.Id,
  );
  const [selectedPosition, setSelectedPosition] = useState<{
    id: number;
    xCoordinate: number;
    yCoordinate: number;
    floor: {
      id: number;
      number: number;
    };
    roomName?: string;
    buildingName: string;
  }>(resetPosition(assetData));
  const [hoveredFeature, setHoveredFeature] = useState<
    AssetHistoryFeatureType | undefined
  >(undefined);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [olListenersKeys] = useState<EventsKey[]>([]);
  const [baseLayer] = useState(new BaseLayer());
  const [lineLayer] = useState(
    new GeometryLayer<Asset>({
      style: (feat, _, hoveredFeature) => [
        new Style({
          stroke: new Stroke({
            color: primaryColorToRGBArray(400, 0.8),
            width: 3,
          }),
        }),
        // Show all the points
        new Style({
          image: new Circle({
            radius:
              feat.getGeometry() === hoveredFeature?.getGeometry()
                ? feat.getProperties().now
                  ? 7
                  : 4
                : feat.getProperties().now
                  ? 6
                  : 3,
            fill: new Fill({
              color: primaryColorToRGBArray(
                feat.getProperties().now ? 600 : 300,
                0.8,
              ),
            }),
            stroke: new Stroke({
              color: primaryColorToRGBArray(
                feat.getProperties().now ? 700 : 400,
                0.8,
              ),
              width:
                feat.getGeometry() === hoveredFeature?.getGeometry() ? 3 : 2,
            }),
          }),
          geometry: (feature) => {
            // Return the coordinates of the first ring of the polygon
            const coordinates = (feature as AssetHistoryFeatureType)
              .getGeometry()
              ?.getCoordinates()[0];

            return new MultiPoint(coordinates ?? [0, 0]);
          },
        }),
      ],
    }),
  );
  const [layers] = useState<Layer[]>([baseLayer, lineLayer]);
  const [map] = useState(new ExtendedMap());
  const [geometries, setGeometries] = useState<Asset[]>([]);

  const [{ data, fetching }] = useMqttAssetTrackerHistoryQuery({
    context: useMemo(
      () => hasuraHeader(HasuraPermissions.VIEW_FINDMYASSET),
      [hasuraHeader],
    ),
    variables: {
      AssetId: assetData.Id,
    },
  });

  const setListener = useCallback(() => {
    olListenersKeys.push(
      baseLayer.on('change', (evt: any) => {
        setImageHeight(evt.height);
        setImageWidth(evt.width);
      }),
    );
  }, [baseLayer, olListenersKeys]);

  const setPosition = useCallback(
    (id: number | undefined) => {
      const historyEntry = assetHistoryData.find((d) => d.Id === id);

      setSelectedPosition(
        historyEntry
          ? {
              xCoordinate: historyEntry.XCoordinate,
              yCoordinate: historyEntry.YCoordinate,
              roomName: historyEntry.Room?.Name,
              buildingName: historyEntry.Floor.Building.Name,
              floor: {
                id: historyEntry.Floor.Id,
                number: historyEntry.Floor.Number,
              },
              id: historyEntry.Id,
            }
          : resetPosition(assetData),
      );

      // Needs to have a timeout in order for "postrender" to trigger after data was set
      setTimeout(() => lineLayer.olLayer.changed(), 500);
    },
    [assetData, lineLayer.olLayer.changed],
  );

  const showFeatureInfo = useCallback(
    (evt: MapBrowserEvent<PointerEvent>, feat?: AssetHistoryFeatureType) => {
      if (feat) {
        lineLayer.hoveredFeature = feat;
        lineLayer.olLayer.changed();
        setHoveredFeature(feat);
        setPosition(feat.getProperties().id);
      } else {
        setHoveredFeature(undefined);
        lineLayer.hoveredFeature = undefined;
        lineLayer.olLayer.changed();
        setPosition(undefined);
      }
      evt.map.getTargetElement().style.cursor = feat ? 'pointer' : '';
    },
    [lineLayer, setPosition],
  );

  useEffect(() => {
    setListener();
    return () => {
      unByKey(olListenersKeys);
    };
  }, [olListenersKeys, setListener]);

  useEffect(() => {
    if (data?.Floors[0]?.Id) {
      const floor = data.Floors.find((f) => f.Id === selectedPosition.floor.id);

      // Only set it if the floor image changed
      // If there is no imageWidth then no initial floor was set
      if (floor?.Image && (selectedFloorId !== floor?.Id || !imageWidth)) {
        baseLayer.setImage(floor.Image);
        setSelectedFloorId(floor.Id);
      }
    }
  }, [
    selectedFloorId,
    imageWidth,
    baseLayer,
    data?.Floors[0]?.Id,
    data?.Floors.find,
    selectedPosition.floor.id,
  ]);

  const assetHistoryData = useMemo(
    () => [
      ...(data?.MqttAssetTrackerHistories.map((d) => ({ ...d, Now: false })) ??
        []),
      {
        Floor: {
          Id: assetData.MqttGateway?.Floor?.Id ?? 0,
          Number: assetData.MqttGateway?.Floor?.Number ?? 0,
          Building: {
            Name: assetData.MqttGateway?.Floor?.Building?.Name ?? '',
          },
        },
        Room: {
          Id: assetData?.Room?.Id ?? 0,
          Name: assetData?.Room?.Name ?? '',
        },
        CreatedAt: new Date(assetData.UpdatedAt),
        Id: assetData.Id,
        XCoordinate: assetData.XCoordinate,
        YCoordinate: assetData.YCoordinate,
        Now: true,
      },
    ],
    [
      data?.MqttAssetTrackerHistories,
      assetData.Id,
      assetData.UpdatedAt,
      assetData?.Room?.Name,
      assetData?.Room?.Id,
      assetData.MqttGateway?.Floor?.Building?.Name,
      assetData.MqttGateway?.Floor?.Id,
      assetData.MqttGateway?.Floor?.Number,
      assetData.XCoordinate,
      assetData.YCoordinate,
    ],
  );

  useEffect(() => {
    if (imageHeight > 0 && imageWidth > 0 && assetHistoryData.length) {
      setGeometries(
        assetHistoryData
          .map((d, key) => {
            let nextEntry = assetHistoryData?.[key + 1];

            const changedFloor = nextEntry && d.Floor.Id !== nextEntry.Floor.Id;

            // Do not connect the lines if the floor changed
            if (changedFloor) {
              nextEntry = undefined;
            }

            return {
              id: d.Id,
              floorId: d.Floor.Id,
              date: new Date(d.CreatedAt),
              now: d.Now,
              name: assetData.Name,
              Geometry: {
                type: GeometryType.POLYGON,
                coordinates: [
                  nextEntry
                    ? [
                        [
                          d.XCoordinate * imageWidth,
                          imageHeight - d.YCoordinate * imageHeight,
                        ],
                        [
                          nextEntry.XCoordinate * imageWidth,
                          imageHeight - nextEntry.YCoordinate * imageHeight,
                        ],
                      ]
                    : [
                        [
                          d.XCoordinate * imageWidth,
                          imageHeight - d.YCoordinate * imageHeight,
                        ],
                      ],
                ],
              },
            };
          })
          // Filtering can only be done afterwards as we need to know if the floor changed
          // Only show the geometries of the selected floor
          .filter((d) => d.floorId === selectedFloorId),
      );
    }
  }, [
    imageHeight,
    imageWidth,
    assetData.Name,
    selectedFloorId,
    assetHistoryData,
  ]);

  useEffect(() => lineLayer.setFeatures(geometries), [geometries, lineLayer]);

  const timeLineData = useMemo(
    () =>
      assetHistoryData.length
        ? Object.entries(
            groupBy(
              assetHistoryData.filter((d, key) => {
                const previousEntry = assetHistoryData?.[key - 1];

                // Always show live position
                if (d.Now) return true;

                // Do not generate a new entry if room hasn't changed
                if (previousEntry && previousEntry.Room?.Id === d.Room?.Id) {
                  return false;
                }

                return true;
              }),
              (d) =>
                d.Now
                  ? intl.formatMessage({ id: 'Now' })
                  : format(d.CreatedAt, 'yyyy-MM-dd'), // Only format them this way to group the entries by date
            ),
          )
            .map(([date, assetData]) => ({
              timestamp: date,
              now: date === intl.formatMessage({ id: 'Now' }),
              entries:
                assetData
                  ?.sort(
                    (a, b) =>
                      new Date(b.CreatedAt).getTime() -
                      new Date(a.CreatedAt).getTime(),
                  )
                  .map((d) => ({
                    id: d.Id,
                    title: d.Room?.Name
                      ? intl.formatMessage(
                          { id: 'Building Floor Room' },
                          {
                            building: d.Floor.Building.Name,
                            number: d.Floor.Number,
                            room: d.Room.Name,
                          },
                        )
                      : intl.formatMessage(
                          { id: 'Building Floor' },
                          {
                            building: d.Floor.Building.Name,
                            number: d.Floor.Number,
                          },
                        ),
                    subtitle: d.Now ? (
                      <div className="flex relative space-x-3">
                        <LivePing />
                        <div>{formattedDistance(d.CreatedAt)}</div>
                      </div>
                    ) : (
                      format(d.CreatedAt, 'p')
                    ),
                    renderIcon: () => (
                      <LuminaireIcon
                        device={{
                          deviceType: DeviceTypes.ASSET,
                        }}
                      />
                    ),
                    active: selectedPosition.id === d.Id,
                  })) ?? [],
            }))
            // Always show "Now" entry on top
            .sort((a, b) =>
              a.now
                ? -1
                : new Date(b.timestamp).getTime() -
                  new Date(a.timestamp).getTime(),
            )
        : [],
    [assetHistoryData, intl.formatMessage, selectedPosition.id],
  );

  const highlightedFeatures = useMemo(
    () => ({
      features: [
        {
          geometry: new Point([
            selectedPosition.xCoordinate * imageWidth,
            imageHeight - selectedPosition.yCoordinate * imageHeight,
          ]),
          color: primaryColorToRGBArray(800),
        },
      ],
      layer: lineLayer,
    }),
    [
      imageHeight,
      imageWidth,
      lineLayer,
      selectedPosition.xCoordinate,
      selectedPosition.yCoordinate,
    ],
  );

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
      <div
        className="col-span-1 md:col-span-1 map-height overflow-y-auto"
        data-test-id="asset-history-list"
      >
        <TimeLine
          loading={fetching}
          data={timeLineData}
          setSelectedPosition={setPosition}
        />
      </div>
      <div className="col-span-1 md:col-span-3">
        <div>
          {selectedPosition?.roomName ? (
            <Subtitle
              data-test-id="asset-history-title"
              value={intl.formatMessage(
                {
                  id: 'Building Floor Room',
                },
                {
                  number: selectedPosition.floor.number,
                  building: selectedPosition.buildingName,
                  room: selectedPosition.roomName,
                },
              )}
            />
          ) : (
            <Subtitle
              data-test-id="asset-history-title"
              value={intl.formatMessage(
                {
                  id: 'Building Floor',
                },
                {
                  number: selectedPosition.floor.number,
                  building: selectedPosition.buildingName,
                },
              )}
            />
          )}
        </div>
        <div className="w-full h-96">
          <Map<AssetHistoryFeatureType>
            enableZoomButton={false}
            enableCanvasExport={false}
            map={map}
            layers={layers}
            isLoadingFeatures={fetching}
            highlightedFeatures={highlightedFeatures}
            onFeaturesHover={(hoveredFeatures, evt) => {
              const features = hoveredFeatures.map((hF) => hF.feature);
              showFeatureInfo(evt, features[0]);
            }}
            renderTooltip={(props) => {
              if (!hoveredFeature) return undefined;

              if (isMobile) {
                return (
                  <Card className="absolute bottom-0 right-0 left-0 z-20">
                    <AssetHistoryPopup hoveredFeature={hoveredFeature} />
                  </Card>
                );
              }
              return (
                <TooltipWithBounds {...props}>
                  <AssetHistoryPopup hoveredFeature={hoveredFeature} />
                </TooltipWithBounds>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
}
