import { Action } from '@/common/types';
import Modal from '@/generic/components/Modal';
import {
  Mda2MessageType,
  generateMda2Topic,
  getBeaconId,
  isMda2Topic,
} from '@/pages/AdminView/BuildingView/components/FloorList/components/FloorRoomView/components/FloorRoomMap/components/SelectNewBeaconCard/components/EnrollDevices/components/TopicCell/TopicCell';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import groupBy from '@/utils/groupBy';
import parseMda2Topic from '@/utils/parseMda2Topic';
import StyledButton from 'generic/components/Form/Button/StyledButton';
import Input from 'generic/components/Form/Input/Input';
import Select from 'generic/components/Form/Select';
import Switch from 'generic/components/Form/Switch';
import TextArea from 'generic/components/Form/TextArea';
import Transition from 'generic/components/Transition';
import { MqttSystems, RpcTypes, useSendRpcMutation } from 'graphql/types';
import { useMemo, useState } from 'react';
import {
  LuCodeXml,
  LuFileDigit,
  LuHouse,
  LuSave,
  LuTags,
} from 'react-icons/lu';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import parseBluerangeTopic from 'utils/parseBluerangeTopic';

export type RPCBeacons = {
  mqttTopic: string;
  mqttSystem: MqttSystems;
  isOffline: boolean;
  name: string;
};

interface SendRPCModalProps {
  setBeaconsToSendRPC: (beaconsToSendRPC?: RPCBeacons[]) => void;
  beaconsToSendRPC?: RPCBeacons[];
}

const checkValue = (value?: string) => {
  if (value) {
    const isHex = value.startsWith('0x');

    if (isHex) {
      const number = Number.parseInt(value, 16);

      if (Number.isNaN(number)) {
        return undefined;
      }

      return number;
    }

    const number = Number.parseInt(value, 10);

    if (Number.isNaN(number)) {
      return undefined;
    }

    return number;
  }
  return undefined;
};

function HexValue({ value }: { value: string | undefined }) {
  return (
    <Transition show={!!value?.startsWith('0x') && value.length > 2}>
      <p className="text-xs">
        <FormattedMessage id="Value" />: {checkValue(value)}
      </p>
    </Transition>
  );
}

export default function SendRPCModal({
  beaconsToSendRPC,
  setBeaconsToSendRPC,
}: SendRPCModalProps): React.JSX.Element {
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const [responseData, setResponseData] =
    useState<({ beacon: string; data: string | undefined } | undefined)[]>();
  const [responseError, setResponseError] = useState<string | undefined>();
  const [write, setWrite] = useState(false);
  const [selectedType, setSelectedType] = useState(RpcTypes.String);
  const [deviceAddress, setDeviceAddress] = useState<number>(0);
  const [holdingRegister, setHoldingRegister] = useState<string | undefined>();
  const [inputRegister, setInputRegister] = useState<string | undefined>();
  const [count, setCount] = useState<number | undefined>();
  const [values, setValues] = useState<string | undefined>();

  const [{ fetching }, sendRpc] = useSendRpcMutation();

  const resetValues = () => setBeaconsToSendRPC(undefined);

  const calculatedInputRegister = useMemo(
    () => checkValue(inputRegister),
    [inputRegister],
  );
  const calculatedHoldingRegister = useMemo(
    () => checkValue(holdingRegister),
    [holdingRegister],
  );

  const beacons = useMemo(
    () => beaconsToSendRPC && groupBy(beaconsToSendRPC, (i) => i.mqttSystem),
    [beaconsToSendRPC],
  );

  const mda2OnlineBeacons = beacons?.MDA2?.filter((b) => !b.isOffline);
  const bluerangeOnlineBeacons = beacons?.Bluerange?.filter(
    (b) => !b.isOffline,
  );

  const hasOnlineBeacons = !!(
    mda2OnlineBeacons?.length || bluerangeOnlineBeacons?.length
  );

  return (
    <Modal
      title={intl.formatMessage({
        id: 'RPC',
      })}
      action={Action.UPDATE}
      open={!!beaconsToSendRPC}
      setShowModal={resetValues}
    >
      <div className="flex flex-col space-y-2">
        <div className="flex space-y-2 justify-between items-center">
          <div className="flex flex-col">
            {hasOnlineBeacons && (
              <div className="flex space-x-2 items-center">
                <div>
                  <FormattedMessage id="Read" />
                </div>
                <Switch
                  isEnabled={write}
                  onSetEnable={() => setWrite(!write)}
                  disabledColor="bg-green-500"
                />
                <div>
                  <FormattedMessage id="Write enabled" />
                </div>
              </div>
            )}
            <div>
              <FormattedMessage
                id="Mqtt system elements"
                values={{
                  mqttSystem: MqttSystems.Bluerange,
                  count:
                    beacons?.Bluerange?.filter((b) => !b.isOffline).length ?? 0,
                }}
              />
              {' / '}
              <FormattedMessage
                id="Mqtt system elements"
                values={{
                  mqttSystem: MqttSystems.Mda2,
                  count: beacons?.MDA2?.filter((b) => !b.isOffline).length ?? 0,
                }}
              />
            </div>
          </div>

          {hasOnlineBeacons && (
            <Select
              label="Return type"
              value={selectedType}
              isDeselectable={false}
              onChangeSelected={(type) =>
                type && setSelectedType(type as RpcTypes)
              }
              options={Object.values(RpcTypes).map((d) => d.toString())}
              renderValue={(t) =>
                `${intl.formatMessage({ id: t as IntlMessageKeys })}`
              }
            />
          )}
        </div>
        {hasOnlineBeacons && (
          <>
            <Input
              type="number"
              min={0}
              placeholder={intl.formatMessage({ id: 'Device address' })}
              label={intl.formatMessage({ id: 'Device address' })}
              value={deviceAddress}
              renderIcon={({ className }) => <LuHouse className={className} />}
              onChangeValue={(val) =>
                setDeviceAddress(Number.parseInt(val, 10))
              }
            />
            <div className="space-y-0.5">
              <Input
                type="text"
                placeholder={intl.formatMessage({ id: 'Holding register' })}
                label={intl.formatMessage({ id: 'Holding register' })}
                value={holdingRegister}
                renderIcon={({ className }) => <LuSave className={className} />}
                onChangeValue={setHoldingRegister}
              />
              <HexValue value={holdingRegister} />
            </div>
            <div className="space-y-0.5">
              <Input
                type="text"
                placeholder={intl.formatMessage({ id: 'Input register' })}
                label={intl.formatMessage({ id: 'Input register' })}
                value={inputRegister}
                renderIcon={({ className }) => <LuTags className={className} />}
                onChangeValue={setInputRegister}
              />
              <HexValue value={inputRegister} />
            </div>
            <Input
              type="number"
              placeholder={intl.formatMessage({ id: 'Count' })}
              label={intl.formatMessage({ id: 'Count' })}
              value={count}
              renderIcon={({ className }) => (
                <LuCodeXml className={className} />
              )}
              onChangeValue={(c) => setCount(Number.parseInt(c, 10))}
            />
            <Input
              type="text"
              placeholder={intl.formatMessage({ id: 'Values' })}
              label={intl.formatMessage({ id: 'Values' })}
              value={values}
              renderIcon={({ className }) => (
                <LuFileDigit className={className} />
              )}
              onChangeValue={setValues}
            />
            <StyledButton
              isLoading={fetching}
              disabled={
                !deviceAddress ||
                (!count &&
                  typeof calculatedHoldingRegister === 'number' &&
                  !write) ||
                (typeof calculatedHoldingRegister === 'number' &&
                  typeof calculatedInputRegister === 'number') ||
                (typeof calculatedInputRegister === 'number' && write) ||
                (count && write) ||
                (!!values && !write) ||
                (!write &&
                  typeof calculatedHoldingRegister !== 'number' &&
                  typeof calculatedInputRegister !== 'number')
              }
              onClick={() => {
                setResponseError(undefined);
                setResponseData(undefined);
                sendRpc(
                  {
                    Input: (beaconsToSendRPC ?? []).map((b) => ({
                      mqttSystem: b.mqttSystem,
                      topic:
                        b.mqttSystem === MqttSystems.Bluerange
                          ? parseBluerangeTopic(b.mqttTopic)
                          : isMda2Topic(b.mqttTopic)
                            ? generateMda2Topic(
                                b.mqttTopic,
                                Mda2MessageType.ADMINCMD,
                              )
                            : `cmd/${parseMda2Topic(b.mqttTopic)}/modbus_rpc`,
                      write,
                      type: selectedType,
                      deviceAddress,
                      holdingRegister: calculatedHoldingRegister,
                      inputRegister: calculatedInputRegister,
                      count,
                      values,
                    })),
                  },
                  hasuraHeader(HasuraPermissions.READ_ALL),
                ).then((data) => {
                  if (data.error) {
                    setResponseError(data.error.message);
                  } else {
                    setResponseData(
                      data.data?.SendRPC?.successResponses.map((s) => ({
                        beacon: getBeaconId(s.topic) ?? '',
                        data: s.message ?? undefined,
                      })),
                    );
                  }
                });
              }}
            >
              <FormattedMessage id="Send" />
            </StyledButton>
            {responseData && (
              <TextArea text={JSON.stringify(responseData, null, 2)} />
            )}
            {responseError && <TextArea text={responseError} />}
          </>
        )}
        <Transition show={!hasOnlineBeacons}>
          <div className="text-red-500 text-xs">
            <FormattedMessage id="Command won't be sent for the following offline beacons" />
            <ul className="list-disc pl-5">
              {[...(beacons?.Bluerange ?? []), ...(beacons?.MDA2 ?? [])]
                ?.filter((b) => b.isOffline)
                .map((b) => (
                  <li key={b.name}>{b.name}</li>
                ))}
            </ul>
          </div>
        </Transition>
      </div>
    </Modal>
  );
}
