import {
  type DesksOccupancyHistoryLabel,
  useRoomOccupancyLabelHistoryQuery,
} from 'graphql/types';
import { useIntl } from 'translations/Intl';
import useReportFilter from 'utils/graphql/useReportFilter';
import BarChart from '../BarChart';
import ReportingPage from '../ReportingPage';

export default function ReportRoomLabelOccupancy() {
  const intl = useIntl();
  const reportFilter = useReportFilter();
  const [{ data, fetching }] = useRoomOccupancyLabelHistoryQuery(reportFilter);

  const typedData = (data?.ReportCharts[0]?.Data ?? { data: [] }) as {
    data: DesksOccupancyHistoryLabel[];
  };

  const occupancyData = typedData.data;

  return (
    <ReportingPage
      loading={fetching}
      reportPageId={data?.ReportCharts[0]?.ReportPageId}
      reportTitle="Meeting rooms occupancy by label"
    >
      <div className="h-96 w-full">
        <BarChart
          data={occupancyData ?? []}
          yScaleAccessor={(d) => d.AvgDailyOccupancy}
          yScaleLabel={intl.formatMessage({ id: 'Average Daily Occupancy' })}
          groupAccessor={(d) => d.Label}
          y1Accessor={(d) => d.MaxDailyOccupancy}
          y1Label={intl.formatMessage({ id: 'Max Daily Occupancy' })}
          y2Accessor={(d) => d.MaxHourlyOccupancy}
          y2Label={intl.formatMessage({ id: 'Max Hourly Occupancy' })}
          xAxisLabel={intl.formatMessage({ id: 'Label' })}
        />
      </div>
    </ReportingPage>
  );
}
