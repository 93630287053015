import StyledButton from 'generic/components/Form/Button/StyledButton';
import Tooltip from 'generic/components/Tooltip';
import { useState } from 'react';
import { LuCloudDownload } from 'react-icons/lu';
import { FormattedMessage } from 'translations/Intl';
import DownloadReportModal from './components/DownloadReportModal';

export default function ReportDownload() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Tooltip
        content={
          <StyledButton onClick={() => setOpenModal(true)}>
            <LuCloudDownload className="size-5" />
          </StyledButton>
        }
      >
        <FormattedMessage id="Download PDF" />
      </Tooltip>

      <DownloadReportModal open={openModal} setOpen={setOpenModal} />
    </>
  );
}
