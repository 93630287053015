import Tooltip from 'generic/components/Tooltip';
import {
  type ChangeEvent,
  type InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import { LuClock, LuEye } from 'react-icons/lu';
import Button from '../Button';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChangeValue: (arg: string) => void;
  renderIcon?: ({ className }: { className: string }) => React.JSX.Element;
  label?: string;
  error?: boolean;
  type: string;
  className?: string;
  iconClassName?: string;
  wrapperClassName?: string;
  tooltipText?: string;
  tooltipContent?: React.JSX.Element;
  selectContent?: React.JSX.Element;
  ref?: React.RefObject<HTMLInputElement | null>;
}

export default function Input({
  ref: forwardedRef,
  renderIcon = () => <LuClock className="size-5 dark:text-neutral-200" />,
  onChangeValue,
  value,
  label,
  error,
  iconClassName,
  className = '',
  disabled = false,
  required = false,
  placeholder = '',
  step,
  autoFocus = false,
  tooltipText,
  tooltipContent,
  wrapperClassName,
  selectContent,
  type,
  ...rest
}: InputProps) {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const internalRef = useRef<HTMLInputElement>(null);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeValue(e.target.value);
  };

  // forwardedRef is mostly used for copy button
  useEffect(() => {
    if (forwardedRef) {
      forwardedRef.current = internalRef.current;
    }
  }, [forwardedRef]);

  useEffect(() => {
    let timeOutId: NodeJS.Timeout;

    // Set focus on open because autoFocus doesn't work with Panel open/close
    if (autoFocus) {
      // Input needs to be mounted in order to be focusable
      // https://salesforce.stackexchange.com/questions/366963/setting-focus-on-conditionally-rendered-input/366973#366973
      timeOutId = setTimeout(() => internalRef.current?.focus(), 0);
    }

    return () => clearTimeout(timeOutId);
  }, [autoFocus]);

  // If no initial prop, the input is controlled by above component.
  return (
    <div className={`relative ${wrapperClassName ?? ''}`}>
      <label
        htmlFor={label}
        className="text-base md:text-sm text-neutral-700 dark:text-white text-left"
      >
        <div className="flex space-x-1 items-center">
          <div>
            {label}
            {label && required && <span className="text-red-700"> *</span>}
          </div>
          {label && !!tooltipText && (
            <Tooltip content={tooltipContent}>
              <p>{tooltipText}</p>
            </Tooltip>
          )}
        </div>
        <div
          className={`${label ? 'mt-1' : ''} relative rounded-md items-center`}
        >
          <div
            className={`absolute inset-y-0 text-neutral-500 ${
              iconClassName ?? ''
            } dark:text-neutral-200 px-2 flex items-center`}
          >
            {renderIcon({ className: 'size-5 dark:text-neutral-200' })}
          </div>
          <input
            ref={internalRef}
            name={label}
            value={value}
            placeholder={placeholder}
            required={required}
            disabled={disabled}
            onChange={onInputChange}
            type={passwordVisible ? 'text' : type}
            className={`w-full dark:bg-neutral-800 pl-10 focus:ring-primary-500 dark:focus:ring-neutral-500 focus:border-primary-500 dark:focus:border-neutral-500 block border-neutral-200 dark:border-neutral-700 text-base md:text-sm rounded-md disabled:opacity-50 ${
              error ? 'border-red-500' : ''
            } ${className ?? ''}`}
            {...rest}
          />
          {!selectContent && type === 'password' && (
            <div className="absolute inset-y-0 right-2 flex items-center">
              <Button onClick={() => setPasswordVisible(!passwordVisible)}>
                <LuEye className="size-5 hover:text-primary-500" />
              </Button>
            </div>
          )}
          <div className="absolute inset-y-0 right-0 flex items-center">
            {selectContent}
          </div>
        </div>
      </label>
    </div>
  );
}
