// https://stackoverflow.com/questions/42136098/array-groupby-in-typescript
const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce(
    (groups, item) => {
      // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
      (groups[key(item)] ||= []).push(item);
      return groups;
    },
    {} as Record<K, T[] | undefined>,
  );

export default groupBy;
