import { isAuthorized } from '@/router/pages/PrivateRoute';
import type { HasuraPermissions } from '@/utils/graphql/useHasuraHeaders';
import useStore from 'model/store';
import type { ReactNode } from 'react';

interface PrivateWrapperProps {
  roleRequired: HasuraPermissions | HasuraPermissions[];
  children: ReactNode;
}

export default function PrivateWrapper({
  roleRequired,
  children,
}: PrivateWrapperProps) {
  const userRoles = useStore((state) => state.user)?.roles;

  const authorized = Array.isArray(roleRequired)
    ? roleRequired.every((role) => isAuthorized(role, userRoles))
    : isAuthorized(roleRequired, userRoles);

  if (authorized) {
    return children;
  }

  return null;
}
