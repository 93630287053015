import { Themes } from '@/common/types';
import Legend from '@/generic/components/Chart/Legend';
import LoadingSpinner from '@/generic/components/LoadingSpinner';
import {
  useHistoryOccupancyDailyAggregatedQuery,
  useMeetingRoomsHistoryOccupancyDailyQuery,
} from '@/graphql/types';
import localize from '@/utils/format';
import getColor, { primaryColorToRGB } from '@/utils/getColor';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import { Brush } from '@visx/brush';
import type BaseBrush from '@visx/brush/lib/BaseBrush';
import type { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle';
import type { Bounds } from '@visx/brush/lib/types';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { PatternLines } from '@visx/pattern';
import { ParentSize } from '@visx/responsive';
import { scaleLinear, scaleOrdinal, scaleTime } from '@visx/scale';
import { Bar, Line } from '@visx/shape';
import { TooltipWithBounds, defaultStyles, useTooltip } from '@visx/tooltip';
import { bisector, extent } from 'd3-array';
import { addDays, differenceInDays, endOfDay, startOfDay } from 'date-fns';
import Tooltip from 'generic/components/Tooltip';
import useStore from 'model/store';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'translations/Intl';
import { endOfDayUTC, startOfYearUTC } from 'utils/date';
import useAnalyticsFilter from 'utils/graphql/useAnalyticsFilter';
import AreaChart, {
  type ChartData,
  type MeetingChartData,
} from './Components/AreaChart';

// Initialize some variables
const brushMargin = { top: 10, bottom: 15, left: 70, right: 40 };
const chartSeparation = 30;

// accessors
const getDate = (d: ChartData | MeetingChartData) => d.Date;
const getMaxPercentage = (d: ChartData) =>
  d.PercentageWarmMinutes + d.PercentageHotMinutes;
const getWarmPercentage = (d: ChartData) => d.PercentageWarmMinutes;
const getHotPercentage = (d: ChartData) => d.PercentageHotMinutes;
const getMaxUsage = (d: ChartData) => d.PercentageMaxDailyOccupancy;
const getUsedMeetingRoomPercentage = (d: MeetingChartData) =>
  d.PercentageUsedMeetingRooms;

const bisectDate = bisector<ChartData | MeetingChartData, Date>(
  (d) => d.Date,
).left;

interface ResponsiveBrushProps {
  margin?: { top: number; right: number; bottom: number; left: number };
  meetingRoomOccupancy?: boolean;
}

interface BrushProps extends ResponsiveBrushProps {
  width: number;
  height: number;
}

// We need to manually offset the handles for them to be rendered at the right position
// Source: https://airbnb.io/visx/brush
export function BrushHandle({
  x,
  height,
  isBrushActive,
}: BrushHandleRenderProps) {
  const pathWidth = 8;
  const pathHeight = 15;
  if (!isBrushActive) {
    return null;
  }
  return (
    <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
      <path
        fill="#f2f2f2"
        d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
        stroke="#999999"
        strokeWidth="1"
        style={{ cursor: 'ew-resize' }}
      />
    </Group>
  );
}

function BrushChart({
  width,
  height,
  margin = {
    top: 50,
    left: 70,
    bottom: 20,
    right: 40,
  },
  meetingRoomOccupancy = false,
}: BrushProps) {
  const patternId = `brush_pattern-${meetingRoomOccupancy}`;
  const brushRef = useRef<BaseBrush | null>(null);
  const dateRange = useStore((state) => state.userSettings.dateRange);
  const setDateRange = useStore((state) => state.userApi.setDateRange);
  const dateFrom = useMemo(() => new Date(dateRange.start), [dateRange.start]);
  const dateTo = useMemo(
    () => (dateRange.end ? new Date(dateRange.end) : null),
    [dateRange.end],
  );
  const [filteredChart, setFilteredChart] = useState<
    (ChartData | MeetingChartData)[]
  >([]);
  const theme = useStore((state) => state.userSettings.theme);
  const accentColor = primaryColorToRGB(400);
  const intl = useIntl();
  const hasuraHeader = useHasuraHeader();
  const { tooltipData, tooltipLeft, tooltipTop, showTooltip, hideTooltip } =
    useTooltip<ChartData | MeetingChartData>();

  const selectedBrushStyle = {
    fill: `url(#${patternId})`,
    stroke: theme.color === Themes.LIGHT ? 'black' : 'white',
  };

  const [{ data: historyData, fetching: loadingHistory }] =
    useHistoryOccupancyDailyAggregatedQuery({
      variables: {
        ...useAnalyticsFilter(),
        Start: startOfYearUTC(dateFrom),
        End: endOfDayUTC(new Date()),
      },
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
        [hasuraHeader],
      ),
      pause: meetingRoomOccupancy,
    });

  const [{ data: historyMeetingData, fetching: loadingMeetingHistory }] =
    useMeetingRoomsHistoryOccupancyDailyQuery({
      variables: {
        ...useAnalyticsFilter(),
        Start: startOfYearUTC(dateFrom),
        End: endOfDayUTC(new Date()),
      },
      context: useMemo(
        () => hasuraHeader(HasuraPermissions.VIEW_ANALYTICS),
        [hasuraHeader],
      ),
      pause: !meetingRoomOccupancy,
    });

  const chartData = useMemo(
    () =>
      historyData?.f_history_desks_occupancy_daily &&
      historyData.f_history_desks_occupancy_daily.length > 0
        ? historyData.f_history_desks_occupancy_daily.map((d) => ({
            ...d,
            Date: startOfDay(new Date(d.Date)),
          }))
        : Array.from(
            Array(differenceInDays(dateTo ?? dateFrom, dateFrom) + 1),
          ).map((_, i) => ({
            Date: startOfDay(addDays(dateFrom, i)),
            PercentageHotMinutes: 0,
            PercentageWarmMinutes: 0,
            NumberOfDesks: 0,
            PercentageMaxDailyOccupancy: 0,
          })),
    [
      dateFrom,
      dateTo,
      historyData,
      historyData?.f_history_desks_occupancy_daily,
    ],
  );

  const chartMeetingData = useMemo(
    () =>
      historyMeetingData?.f_history_rooms_occupancy_daily &&
      historyMeetingData.f_history_rooms_occupancy_daily.length > 0
        ? historyMeetingData.f_history_rooms_occupancy_daily.map((d) => ({
            ...d,
            Date: startOfDay(new Date(d.Date)),
          }))
        : Array.from(
            Array(differenceInDays(dateTo ?? dateFrom, dateFrom) + 1),
          ).map((_, i) => ({
            Date: startOfDay(addDays(dateFrom, i)),
            UsedMeetingRooms: 0,
            TotalMeetingRooms: 0,
            PercentageUsedMeetingRooms: 0,
          })),
    [
      dateFrom,
      dateTo,
      historyMeetingData,
      historyMeetingData?.f_history_rooms_occupancy_daily,
    ],
  );

  const onBrushChange = (domain: Bounds | null) => {
    if (!domain) return;
    const { x0, x1, y0, y1 } = domain;
    const chartCopy = meetingRoomOccupancy
      ? chartMeetingData.filter((s) => {
          const x = getDate(s).getTime();
          const y = getUsedMeetingRoomPercentage(s);
          return x > x0 && x < x1 && y > y0 && y < y1;
        })
      : chartData.filter((s) => {
          const x = getDate(s).getTime();
          const y = getMaxPercentage(s);
          return x > x0 && x < x1 && y > y0 && y < y1;
        });
    setFilteredChart(chartCopy);
  };

  const innerHeight = Math.max(height - margin.top - margin.bottom, 0);
  const topChartBottomMargin = chartSeparation + 10;
  const topChartHeight = 0.8 * innerHeight - topChartBottomMargin;
  const bottomChartHeight = innerHeight - topChartHeight - chartSeparation;

  // bounds
  const xMax = Math.max(width - margin.left - margin.right, 0);
  const yMax = Math.max(topChartHeight, 0);
  const innerWidth = width - margin.left - margin.right;
  const xBrushMax = Math.max(width - brushMargin.left - brushMargin.right, 0);
  const yBrushMax = Math.max(
    bottomChartHeight - brushMargin.top - brushMargin.bottom,
    0,
  );

  // scales
  const dateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xMax],
        domain: extent(filteredChart, getDate) as [Date, Date],
      }),
    [xMax, filteredChart],
  );
  const occupancyScale = useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        domain: [0, 100],
        nice: true,
      }),
    [yMax],
  );
  const brushDateScale = useMemo(
    () =>
      scaleTime<number>({
        range: [0, xBrushMax],
        domain: meetingRoomOccupancy
          ? (extent(chartMeetingData, getDate) as [Date, Date])
          : (extent(chartData, getDate) as [Date, Date]),
      }),
    [chartData, chartMeetingData, meetingRoomOccupancy, xBrushMax],
  );
  const brushPercentageScale = useMemo(
    () =>
      scaleLinear({
        range: [yBrushMax, 0],
        domain: [0, 100],
        nice: true,
      }),
    [yBrushMax],
  );

  const initialBrushPosition = useMemo(
    () =>
      (dateTo && {
        start: {
          x: Math.max(
            brushDateScale(dateFrom) > xBrushMax
              ? xBrushMax
              : brushDateScale(dateFrom),
            0,
          ),
        },
        end: { x: Math.min(brushDateScale(dateTo), xBrushMax) },
      }) ?? { start: { x: 0 }, end: { x: 0 } },
    [brushDateScale, dateFrom, dateTo, xBrushMax],
  );

  // Tooltip handler
  const handleTooltip = useCallback(
    (
      event:
        | React.TouchEvent<SVGRectElement>
        | React.MouseEvent<SVGRectElement>,
    ) => {
      const { x } = localPoint(event) || { x: 0 };
      const x0 = dateScale.invert(x - margin.left);
      const index = meetingRoomOccupancy
        ? bisectDate(chartMeetingData, x0, 1)
        : bisectDate(chartData, x0, 1);
      const hasMultipleDates = dateTo
        ? meetingRoomOccupancy
          ? chartMeetingData.filter(
              (d) => d.Date >= startOfDay(dateFrom) && d.Date <= dateTo,
            ).length > 1
          : chartData.filter(
              (d) => d.Date >= startOfDay(dateFrom) && d.Date <= dateTo,
            ).length > 1
        : false;
      const d0 = meetingRoomOccupancy
        ? chartMeetingData[index - 1]
        : chartData[index - 1];
      const d1 = meetingRoomOccupancy
        ? chartMeetingData[index]
        : chartData[index];
      let d = d0;

      // if multiple dates are selected
      if (hasMultipleDates && d1 && d0) {
        d =
          x0.valueOf() - getDate(d0).valueOf() >
          getDate(d1).valueOf() - x0.valueOf()
            ? d1
            : d0;
      }
      showTooltip({
        tooltipData: d,
        tooltipLeft: x,
        tooltipTop: occupancyScale(
          meetingRoomOccupancy
            ? getUsedMeetingRoomPercentage(d as MeetingChartData)
            : getMaxPercentage(d as ChartData),
        ),
      });
    },
    [
      dateScale,
      margin.left,
      chartData,
      meetingRoomOccupancy,
      chartMeetingData,
      showTooltip,
      occupancyScale,
      dateFrom,
      dateTo,
    ],
  );

  useEffect(() => {
    if (dateTo) {
      const filteredEntries = meetingRoomOccupancy
        ? chartMeetingData.filter(
            (d) => d.Date >= startOfDay(dateFrom) && d.Date <= dateTo,
          )
        : chartData.filter(
            (d) => d.Date >= startOfDay(dateFrom) && d.Date <= dateTo,
          );

      if (filteredEntries.length === 1 && filteredEntries[0]) {
        // Must add a second entry, because AreaChart needs 2 entries to show some data
        setFilteredChart([
          filteredEntries[0],
          {
            ...filteredEntries[0],
            Date: endOfDay(filteredEntries[0].Date),
          },
        ]);
      } else {
        setFilteredChart(filteredEntries);
      }
    } else {
      setFilteredChart(meetingRoomOccupancy ? chartMeetingData : chartData);
    }
  }, [chartData, chartMeetingData, dateFrom, dateTo, meetingRoomOccupancy]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (filteredChart.length > 0) {
        const start = filteredChart[0]?.Date ?? new Date();
        const end = filteredChart[filteredChart.length - 1]?.Date ?? new Date();
        const newRange = {
          start: start.toISOString(),
          end: endOfDay(end).toISOString(),
        };

        if (JSON.stringify(newRange) !== JSON.stringify(dateRange)) {
          setDateRange(newRange);
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [dateRange, setDateRange, filteredChart, filteredChart[0]?.Date]);

  return (
    <>
      <LoadingSpinner
        loading={
          (meetingRoomOccupancy && loadingMeetingHistory) ||
          (!meetingRoomOccupancy && loadingHistory)
        }
      />
      <div
        className="relative"
        data-test-id={`brush-chart-${meetingRoomOccupancy}`}
      >
        <svg width={width} height={height}>
          <AreaChart
            data={
              meetingRoomOccupancy
                ? (filteredChart as MeetingChartData[])
                : (filteredChart as ChartData[])
            }
            height={height}
            width={width}
            margin={{ ...margin, bottom: topChartBottomMargin }}
            yMax={yMax}
            xScale={dateScale}
            yScale={occupancyScale}
            meetingRoomOccupancy={meetingRoomOccupancy}
          />
          {width > 0 && height > 0 && (
            /* Just used for showing the tooltip */
            <Bar
              x={margin.left}
              y={margin.top}
              width={innerWidth}
              height={innerHeight}
              fill="transparent"
              data-test-id={`brush-chart-tooltip-area-${meetingRoomOccupancy}`}
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
            />
          )}
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft ?? 0, y: margin.top }}
                to={{
                  x: tooltipLeft ?? 0,
                  y: innerHeight - margin.bottom,
                }}
                stroke={
                  theme.color === Themes.LIGHT
                    ? getColor('NEUTRAL600')
                    : getColor('NEUTRAL300')
                }
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft ?? 0}
                cy={(tooltipTop ?? 0) + margin.top + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft ?? 0}
                cy={(tooltipTop ?? 0) + margin.top}
                r={4}
                fill={accentColor}
                stroke="white"
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
          <AreaChart
            hideLeftAxis
            data={meetingRoomOccupancy ? chartMeetingData : chartData}
            width={width}
            height={height}
            yMax={yBrushMax}
            xScale={brushDateScale}
            yScale={brushPercentageScale}
            margin={brushMargin}
            top={topChartHeight + topChartBottomMargin + margin.top}
            meetingRoomOccupancy={meetingRoomOccupancy}
          >
            <PatternLines
              id={patternId}
              height={8}
              width={8}
              stroke={accentColor}
              strokeWidth={1}
              orientation={['diagonal']}
            />
            <Brush
              // key is needed to force a re-render of the brush when the initialBrushPosition changes
              key={initialBrushPosition.end.x + initialBrushPosition.start.x}
              xScale={brushDateScale}
              yScale={brushPercentageScale}
              width={xBrushMax}
              height={yBrushMax}
              margin={brushMargin}
              handleSize={8}
              innerRef={brushRef}
              resizeTriggerAreas={['left', 'right']}
              brushDirection="horizontal"
              initialBrushPosition={initialBrushPosition}
              onChange={onBrushChange}
              onClick={() =>
                setFilteredChart(
                  meetingRoomOccupancy ? chartMeetingData : chartData,
                )
              }
              selectedBoxStyle={selectedBrushStyle}
              useWindowMoveEvents
              renderBrushHandle={(props) => <BrushHandle {...props} />}
            />
          </AreaChart>
        </svg>
        {tooltipData && (
          <div>
            <TooltipWithBounds
              top={(tooltipTop ?? 0) - (meetingRoomOccupancy ? -12 : 12)}
              left={(tooltipLeft ?? 0) + 12}
              style={{
                ...defaultStyles,
                backgroundColor:
                  theme.color === Themes.LIGHT
                    ? getColor('WHITE')
                    : getColor('NEUTRAL900'),
              }}
            >
              <div
                className="flex flex-col dark:text-neutral-200"
                data-test-id={`brush-chart-tooltip-${meetingRoomOccupancy}`}
              >
                {meetingRoomOccupancy ? (
                  <div className="flex">
                    <p style={{ color: getColor('RED') }}>
                      <FormattedMessage id="PercentageUsedMeetingRooms" />
                    </p>
                    {': '}
                    {`${getUsedMeetingRoomPercentage(
                      tooltipData as MeetingChartData,
                    ).toFixed(2)}%`}
                  </div>
                ) : (
                  <>
                    <div className="flex">
                      <p style={{ color: getColor('YELLOW') }}>
                        <FormattedMessage id="PercentageWarmMinutes" />
                      </p>
                      {': '}
                      {`${getWarmPercentage(tooltipData as ChartData).toFixed(
                        2,
                      )}%`}
                    </div>
                    <div className="flex">
                      <p style={{ color: getColor('RED') }}>
                        <FormattedMessage id="PercentageHotMinutes" />
                      </p>
                      {': '}
                      {`${getHotPercentage(tooltipData as ChartData).toFixed(
                        2,
                      )}%`}
                    </div>
                    <div className="flex">
                      <p
                        style={{
                          color:
                            theme.color === Themes.LIGHT
                              ? getColor('NEUTRAL600')
                              : getColor('NEUTRAL300'),
                        }}
                      >
                        <FormattedMessage id="PercentageMaxDailyOccupancy" />
                      </p>
                      {': '}
                      {`${getMaxUsage(tooltipData as ChartData).toFixed(2)}%`}
                    </div>
                  </>
                )}
              </div>
            </TooltipWithBounds>
            <TooltipWithBounds
              top={innerHeight - 30}
              left={tooltipLeft}
              style={{
                ...defaultStyles,
                backgroundColor:
                  theme.color === Themes.LIGHT
                    ? getColor('WHITE')
                    : getColor('NEUTRAL900'),
                minWidth: 72,
                textAlign: 'center',
              }}
            >
              <p className="dark:text-neutral-200">
                {localize(getDate(tooltipData as ChartData), 'eeeeee do LLL')}
              </p>
            </TooltipWithBounds>
          </div>
        )}
        {!meetingRoomOccupancy && (
          <div className="absolute w-full flex justify-center items-center top-7 text-xs">
            <Legend
              scaleType="ordinal"
              labelFormat={(d) =>
                intl.formatMessage({
                  id: d,
                })
              }
              scale={scaleOrdinal({
                domain: [
                  'PercentageHotMinutes',
                  'PercentageWarmMinutes',
                  'PercentageMaxDailyOccupancy',
                ],
                range: [
                  getColor('RED'),
                  getColor('YELLOW'),
                  theme.color === Themes.LIGHT
                    ? getColor('NEUTRAL600')
                    : getColor('NEUTRAL300'),
                ],
              })}
            />
            <Tooltip>
              <FormattedMessage id="Max desk usage in the selected period" />
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
}

function ResponsiveBrushChart(props: ResponsiveBrushProps): React.JSX.Element {
  return (
    <ParentSize>
      {({ width, height }) => (
        <BrushChart {...props} width={width} height={height} />
      )}
    </ParentSize>
  );
}

export default ResponsiveBrushChart;
