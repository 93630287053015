import {
  MqttSystems,
  useSetSceneIdentifyMutation,
  useSetSceneNoneMutation,
} from '@/graphql/types';
import useHasuraHeader, {
  HasuraPermissions,
} from '@/utils/graphql/useHasuraHeaders';
import parseBluerangeTopic from '@/utils/parseBluerangeTopic';
import { LuLightbulb } from 'react-icons/lu';
import { FormattedMessage, useIntl } from 'translations/Intl';

import useToast from '@/utils/graphql/useToast';
import SetSceneButton from '../SetSceneButton';

interface SetSceneIdentifyButtonProps {
  mqttTopic: string;
}

export default function SetSceneIdentifyButton({
  mqttTopic,
}: SetSceneIdentifyButtonProps) {
  const intl = useIntl();
  const toast = useToast();
  const hasuraHeader = useHasuraHeader();
  const [, setSceneIdentifyMutation] = useSetSceneIdentifyMutation();
  const [, setSceneNoneMutation] = useSetSceneNoneMutation();

  return (
    <SetSceneButton
      renderIcon={() => <LuLightbulb />}
      title="Set scene identify"
      timeout
      onProceed={() =>
        setSceneIdentifyMutation(
          {
            Topics: [parseBluerangeTopic(mqttTopic)],
            MqttSystem: MqttSystems.Bluerange,
          },
          hasuraHeader(HasuraPermissions.VIEW_ADMIN),
        ).then((data) => {
          if (data.error) {
            toast(data, {
              message: {
                type: 'error',
                content: intl.formatMessage({
                  id: 'Error blinking device',
                }),
              },
            });
          }
        })
      }
      onCancel={() =>
        setSceneNoneMutation(
          {
            Topics: [parseBluerangeTopic(mqttTopic)],
            MqttSystem: MqttSystems.Bluerange,
          },
          hasuraHeader(HasuraPermissions.VIEW_ADMIN),
        )
      }
    >
      <FormattedMessage id="Identify scene" />
    </SetSceneButton>
  );
}
