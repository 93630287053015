import type { Colors, JsonGeometry } from 'common/types';
import type Feature from 'ol/Feature';
import type OLMap from 'ol/Map';
import GeoJSON, { type GeoJSONFeature } from 'ol/format/GeoJSON';
import type { LineString, Point, Polygon } from 'ol/geom';
import type Geometry from 'ol/geom/Geometry';
import OLVectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import getColor from 'utils/getColor';
import Layer from './Layer';
import type TypedFeature from './TypedFeature';

export function isPoint(geometry: Geometry): geometry is Point {
  return (geometry as Point).getType() === 'Point';
}

export function isPolygon(geometry: Geometry): geometry is Polygon {
  return (geometry as Polygon).getType() === 'Polygon';
}

export function isLineString(geometry: Geometry): geometry is LineString {
  return (geometry as Polygon).getType() === 'LineString';
}

interface GeometryLayerInterface {
  Geometry: JsonGeometry;
}

type StyleFunction<
  T extends { [x: string]: any },
  K extends Geometry = Point | Polygon | LineString,
> = (
  feat: TypedFeature<T, K>,
  resolution: number,
  hoveredFeature?: TypedFeature<T, K>,
  showLabel?: boolean,
  selectedFeatureId?: number | string,
  showClimateSensors?: boolean,
) => Style[] | undefined;

function styleFunction<
  T extends { [x: string]: any },
  K extends Geometry = Point | Polygon | LineString,
>(
  feat: TypedFeature<T, K>,
  color: keyof typeof Colors,
  selectedFeature?: TypedFeature<T, K>,
) {
  const geometry = feat.getGeometry();

  if (!geometry) {
    console.error('No geometry');
    return;
  }

  return isPoint(geometry)
    ? // Default Point style
      [
        new Style({
          image: new CircleStyle({
            radius: 5,
            fill: new Fill({
              color: getColor(
                color,
                feat.getGeometry() === selectedFeature?.getGeometry()
                  ? '.4'
                  : '.9',
              ),
            }),
            stroke: new Stroke({
              color: getColor(
                color,
                feat.getGeometry() === selectedFeature?.getGeometry()
                  ? '.4'
                  : '1',
              ),
              width:
                feat.getGeometry() === selectedFeature?.getGeometry() ? 3 : 2,
            }),
          }),
        }),
      ]
    : // Default Polygon style
      [
        new Style({
          stroke: new Stroke({
            color: getColor(
              color,
              feat.getGeometry() === selectedFeature?.getGeometry()
                ? '.9'
                : '.8',
            ),
            width: 2,
          }),
          fill: new Fill({
            color: getColor(
              color,
              feat.getGeometry() === selectedFeature?.getGeometry()
                ? '.9'
                : '.75',
            ),
          }),
        }),
      ];
}

interface GeometryOptions<
  T extends { [x: string]: any },
  K extends Geometry = Point | Polygon | LineString,
> {
  style?: StyleFunction<T, K>;
  olLayer?: OLVectorLayer<VectorSource<Feature<K>>>;
  name?: string;
}

class GeometryLayer<
  T extends GeometryLayerInterface = any,
  K extends Geometry = Geometry | Point | Polygon | LineString,
> extends Layer {
  features?: Feature<K>[];

  hoveredFeature?: TypedFeature<T, K>;

  color: keyof typeof Colors = 'GREEN';

  private selectedFeature?: TypedFeature<T, K>;

  private selectedFeatureId?: number | string;

  private showLabel?: boolean;

  private showClimateSensors?: boolean;

  init(map: OLMap): void {
    super.init(map);
    this.map = map;
  }

  constructor(options?: GeometryOptions<T, K>) {
    super({
      ...{
        olLayer:
          options?.olLayer ||
          new OLVectorLayer({
            source: new VectorSource<TypedFeature<T, K>>(),
            style: (feature, resolution) =>
              options?.style
                ? options.style(
                    feature as TypedFeature<T, K>,
                    resolution,
                    this.hoveredFeature,
                    this.showLabel,
                    this.selectedFeatureId,
                    this.showClimateSensors,
                  )
                : styleFunction<T, K>(
                    feature as TypedFeature<T, K>,
                    this.color,
                    this.hoveredFeature,
                  ),
          }),
      },
      name: options?.name,
    });
  }

  setFeatures(feats: T[]): void {
    const source = this.olLayer.getSource() as VectorSource<Feature<K>>;
    const newFeatures = new GeoJSON().readFeatures(
      this.toGeoJson(feats),
    ) as Feature<K>[];
    this.features = newFeatures;
    source.clear();
    source.addFeatures(newFeatures);
  }

  setShowClimateSensors(showClimateSensors: boolean): void {
    this.showClimateSensors = showClimateSensors;
  }

  setShowLabel(showLabel: boolean): void {
    this.showLabel = showLabel;
  }

  getFeatures() {
    return (
      this.olLayer.getSource() as VectorSource<TypedFeature<T, K>>
    ).getFeatures();
  }

  setSelectedFeature(selectedFeature?: {
    feature: TypedFeature<T, K>;
    accessor: (f: T) => number | string;
  }) {
    if (selectedFeature) {
      this.selectedFeature = selectedFeature.feature;
      this.selectedFeatureId = selectedFeature.accessor(
        selectedFeature.feature.getProperties(),
      );
    } else {
      this.selectedFeature = undefined;
      this.selectedFeatureId = undefined;
    }
  }

  getSelectedFeature() {
    return this.selectedFeature;
  }

  toGeoJson(feats?: T[]): GeoJSONFeature {
    const features = feats?.map((feat) => {
      const { Geometry: geom } = feat;
      return {
        type: 'Feature',
        geometry: geom,
        properties: {
          ...feat,
        },
      };
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }
}

export default GeometryLayer;
