import type { MarginProps } from 'common/types';
import Axis from 'generic/components/Chart/Axis';
import Legend from 'generic/components/Chart/Legend';
import Select from 'generic/components/Form/Select';
import LoadingSpinner from 'generic/components/LoadingSpinner';
import Transition from 'generic/components/Transition/Transition';
import { getLocation } from 'generic/components/layout/FilterBar/FilterBar';
import { useIntl } from 'translations/Intl';
import getColor from 'utils/getColor';

import { RAINBOW } from '@/constants';
import localize from '@/utils/format';
import { ParentSize } from '@visx/responsive';
import { scaleOrdinal } from '@visx/scale';
import { Grid, Tooltip, XYChart } from '@visx/xychart';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import useXYChartTheme from 'utils/useXYChartTheme';
import Filter from './components/Filter';
import LineChart, {
  UsageType,
  isDeskUsage,
  isRoom,
  type ChartData,
  type ChartVariables,
} from './components/LineChart/LineChart';
import Utilization from './components/Utilization';

interface ResponsiveChartComparerProps {
  margin?: MarginProps;
  meetingRoomOccupancy?: boolean;
  chartVariables: ChartVariables[];
  setChartVariables: React.Dispatch<React.SetStateAction<ChartVariables[]>>;
}

interface ChartComparerProps extends ResponsiveChartComparerProps {
  height: number;
}

function ChartComparer({
  height,
  margin = {
    top: 100,
    left: 50,
    bottom: 40,
    right: 40,
  },
  meetingRoomOccupancy = false,
  chartVariables,
  setChartVariables,
}: ChartComparerProps) {
  const intl = useIntl();
  const location = useLocation();
  const theme = useXYChartTheme({ colorRange: RAINBOW });
  const [loading, setLoading] = useState(false);
  const [chartType, setChartType] = useState(UsageType.OCCUPANCY);
  const [numTicks] = useState(4);

  const getTooltipLocation = (data?: ChartVariables) =>
    getLocation(intl, location, data?.Building, data?.Floor, data?.Room);

  const renderTooltip = (data: ChartData) => {
    if (isRoom(data)) {
      return Number.isNaN(data.PercentageUsedMeetingRooms)
        ? '–'
        : `${data.PercentageUsedMeetingRooms.toFixed(2)}%`;
    }

    if (isDeskUsage(data)) {
      return Number.isNaN(data.PercentageMaxDailyOccupancy)
        ? '–'
        : `${data.PercentageMaxDailyOccupancy.toFixed(2)}%`;
    }

    return Number.isNaN(data.PercentageHotMinutes)
      ? '–'
      : `${data.PercentageHotMinutes.toFixed(2)}%`;
  };

  return (
    <>
      <LoadingSpinner loading={loading} />
      <XYChart
        margin={margin}
        theme={theme}
        height={height}
        xScale={{
          type: 'time',
        }}
        yScale={{
          type: 'linear',
          domain: [0, 100],
          nice: true,
          zero: false,
        }}
      >
        <Grid
          rows
          numTicks={numTicks}
          columns={false}
          strokeDasharray="1,3"
          stroke={getColor('NEUTRAL600')}
        />
        {chartVariables.map((c) => (
          <LineChart
            setLoading={setLoading}
            meetingRoomOccupancy={meetingRoomOccupancy}
            key={c.Id}
            chartVariables={c}
            usageType={chartType}
          />
        ))}
        <Axis
          orientation="left"
          numTicks={numTicks}
          tickFormat={(d) => `${d}%`}
        />
        <Axis
          orientation="bottom"
          numTicks={numTicks}
          tickFormat={(v: string) => localize(new Date(v), 'eeeeee do LLL')}
        />
        <Tooltip<ChartData>
          showVerticalCrosshair
          renderTooltip={({ tooltipData, colorScale }) => (
            <div className="dark:text-neutral-200">
              {localize(
                tooltipData?.nearestDatum?.datum.Date ?? new Date(),
                'eeeeee do LLL',
              )}
              {Object.values(tooltipData?.datumByKey ?? {}).map(
                (tooltipObject) => (
                  <div key={tooltipObject.key}>
                    <span
                      style={{
                        color: colorScale?.(tooltipObject.key),
                        textDecoration:
                          tooltipData?.nearestDatum?.key === tooltipObject.key
                            ? 'underline'
                            : undefined,
                      }}
                    >
                      {getTooltipLocation(
                        chartVariables.find((c) => c.Id === tooltipObject.key),
                      )}
                    </span>{' '}
                    {renderTooltip(tooltipObject.datum)}
                  </div>
                ),
              )}
            </div>
          )}
        />
      </XYChart>
      <Filter
        meetingRoomOccupancy={meetingRoomOccupancy}
        chartVariables={chartVariables}
        setChartVariables={setChartVariables}
      />
      <div className="absolute w-full flex flex-wrap justify-center top-6 text-xs">
        <Legend
          key={chartVariables.length}
          scaleType="ordinal"
          labelFormat={(r) => r}
          scale={scaleOrdinal({
            domain: chartVariables.map((d) =>
              getLocation(
                intl,
                location,
                d.Building,
                d.Floor,
                d.Room,
                d.Labels,
              ),
            ),
            range: RAINBOW,
          })}
        />
      </div>
      <Transition
        className="absolute top-6 right-2"
        show={!meetingRoomOccupancy}
      >
        <Select
          value={chartType}
          onChangeSelected={(selected) =>
            selected && setChartType(selected as UsageType)
          }
          options={Object.values(UsageType).map((u) => u.toString())}
          isDeselectable={false}
          renderValue={(t) => intl.formatMessage({ id: t as UsageType })}
        />
      </Transition>
    </>
  );
}

export default function ResponsiveChartComparer(
  props: ResponsiveChartComparerProps,
) {
  const { chartVariables, meetingRoomOccupancy } = props;
  return (
    <>
      <div className="relative h-96">
        <ParentSize>
          {({ height }) => <ChartComparer {...props} height={height} />}
        </ParentSize>
      </div>
      <div className="grid p-2">
        {chartVariables.map((c) => (
          <Utilization
            meetingRoomOccupancy={meetingRoomOccupancy}
            key={c.Id}
            chartVariables={c}
          />
        ))}
      </div>
    </>
  );
}
