import Button from 'generic/components/Form/Button';

import { type Cell, type Row, flexRender } from '@tanstack/react-table';
import { LuChevronRight } from 'react-icons/lu';

interface RenderedCellProps<T> {
  row: Row<T>;
  cell: Cell<T, any>;
}

export default function RenderedCell<T>({ row, cell }: RenderedCellProps<T>) {
  if (cell.getIsGrouped()) {
    return (
      // If it's a grouped cell, add an expander and row count
      <Button
        onClick={() => row.toggleExpanded()}
        className={`flex items-center gap-1 ${
          row.getCanExpand() ? 'cursor-pointer' : 'cursor-normal'
        }`}
      >
        <LuChevronRight
          className={`size-5 transition-transform ${
            row.getIsExpanded() ? 'rotate-90' : ''
          }`}
        />
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
        <span>({row.subRows.length})</span>
      </Button>
    );
  }
  if (cell.getIsAggregated()) {
    return (
      <>
        {flexRender(
          cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell,
          cell.getContext(),
        )}
      </>
    );
  }
  if (cell.getIsPlaceholder()) {
    return null;
  }

  return (
    <div data-test-id={cell.id.split('_').pop()}>
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </div>
  );
}
