import {
  type DeskFeatures,
  type DrawingDeskFeatures,
  type NumberArray2,
  type NumberArray4,
  isNonNullable,
} from '@/common/types';
import type VectorLayer from '@/generic/layers/VectorLayer';
import centroid from '@turf/centroid';
import { difference } from '@turf/difference';
import { featureCollection, lineString, polygon } from '@turf/helpers';
import lineOffset from '@turf/line-offset';
import type GeometryLayer from 'generic/layers/GeometryLayer';
import Feature from 'ol/Feature';
import type MapBrowserEvent from 'ol/MapBrowserEvent';
import type { Coordinate } from 'ol/coordinate';
import LineString from 'ol/geom/LineString';
import Point from 'ol/geom/Point';
import Polygon, { fromExtent } from 'ol/geom/Polygon';
import Pointer from 'ol/interaction/Pointer';
import type VectorSource from 'ol/source/Vector';
import { reRenderDeskOutlineFeatures } from '../utils/helpers';
import DeskMoveEvent, { DeskMoveEventType } from './deskMoveEvent';

export const isDrawingDeskFeature = (
  feat: DrawingDeskFeatures | DeskFeatures,
): feat is DrawingDeskFeatures => {
  if (
    (feat as DrawingDeskFeatures).getProperties().__typename === 'DrawingDesks'
  ) {
    return true;
  }
  return false;
};

export const isDeskFeature = (
  feat: DrawingDeskFeatures | DeskFeatures,
): feat is DeskFeatures => {
  if ((feat as DeskFeatures).getProperties().__typename === 'Desks') {
    return true;
  }
  return false;
};

// Generic function to get index for desk & drawingDesk feature
export const getDeskFeatureIndex = (
  feature: DrawingDeskFeatures | DeskFeatures,
) => {
  if (isDeskFeature(feature)) {
    return feature.getProperties().Sensor?.Index ?? 0;
  }
  if (isDrawingDeskFeature(feature)) {
    return feature.getProperties().Index ?? 0;
  }
  return 0;
};

export const getRotations = (rotation: number): NumberArray4 => {
  if ([-90, 0, 90, 180].includes(rotation)) {
    return [180, 90, 0, -90];
  }
  if (rotation > 90) {
    return [rotation, rotation - 90, rotation - 180, rotation - 270];
  }
  if (rotation < 90 && rotation > 0) {
    return [rotation + 90, rotation, rotation - 90, rotation - 180];
  }
  if (rotation < 0 && rotation > -90) {
    return [rotation + 180, rotation + 90, rotation, rotation - 90];
  }
  return [rotation + 270, rotation + 180, rotation + 90, rotation];
};

export const getDestination = (
  position: NumberArray2,
  distance: number,
  rotation: number,
): Coordinate => [
  position[0] + distance * Math.cos(rotation * (Math.PI / 180)),
  position[1] + distance * Math.sin(rotation * (Math.PI / 180)),
];

const getDeskIndex = (
  beaconCoords: NumberArray2,
  polygonCoords: number[][][],
  rotation: number,
  rotations: NumberArray4,
): number => {
  const centroidCoords = centroid(polygon(polygonCoords)).geometry.coordinates;
  const centroidRotation =
    (Math.atan2(
      (centroidCoords[1] ?? 0) - beaconCoords[1],
      (centroidCoords[0] ?? 0) - beaconCoords[0],
    ) *
      180) /
    Math.PI;
  if (rotation === rotations[0]) {
    if (centroidRotation < rotations[0] && centroidRotation > rotations[1]) {
      return 1;
    }
    if (centroidRotation < rotations[1] && centroidRotation > rotations[2]) {
      return 4;
    }
    if (centroidRotation < rotations[2] && centroidRotation > rotations[3]) {
      return 3;
    }
    return 2;
  }
  if (rotation === rotations[1]) {
    if (centroidRotation < rotations[0] && centroidRotation > rotations[1]) {
      return 2;
    }
    if (centroidRotation < rotations[1] && centroidRotation > rotations[2]) {
      return 1;
    }
    if (centroidRotation < rotations[2] && centroidRotation > rotations[3]) {
      return 4;
    }
    return 3;
  }
  if (rotation === rotations[2]) {
    if (centroidRotation < rotations[0] && centroidRotation > rotations[1]) {
      return 3;
    }
    if (centroidRotation < rotations[1] && centroidRotation > rotations[2]) {
      return 2;
    }
    if (centroidRotation < rotations[2] && centroidRotation > rotations[3]) {
      return 1;
    }
    return 4;
  }
  if (centroidRotation < rotations[0] && centroidRotation > rotations[1]) {
    return 4;
  }
  if (centroidRotation < rotations[1] && centroidRotation > rotations[2]) {
    return 3;
  }
  if (centroidRotation < rotations[2] && centroidRotation > rotations[3]) {
    return 2;
  }
  return 1;
};

const getAssociatedIndex = (index: number): number => {
  if (index === 1) {
    return 2;
  }
  if (index === 2) {
    return 1;
  }
  if (index === 3) {
    return 4;
  }
  // if (index === 4)
  return 3;
};

const getOppositeIndex = (index: number) => {
  if (index === 1) {
    return 4;
  }
  if (index === 2) {
    return 3;
  }
  if (index === 3) {
    return 2;
  }
  // if (index === 4)
  return 1;
};

export const getMiddlePoint = (
  pointA?: Coordinate,
  pointB?: Coordinate,
): Coordinate | null => {
  if (
    pointA &&
    pointB &&
    typeof pointA[0] === 'number' &&
    typeof pointA[1] === 'number' &&
    typeof pointB[0] === 'number' &&
    typeof pointB[1] === 'number'
  ) {
    return [(pointA[0] + pointB[0]) / 2, (pointA[1] + pointB[1]) / 2];
  }
  return null;
};

export const getDistance = (pointA: NumberArray2, pointB: NumberArray2) =>
  Math.sqrt((pointB[0] - pointA[0]) ** 2 + (pointB[1] - pointA[1]) ** 2);

export const getDeskAtIndex = (
  index: number,
  deskFeatures: (DrawingDeskFeatures | DeskFeatures)[],
) =>
  deskFeatures
    .find((d) => getDeskFeatureIndex(d) === index)
    ?.getGeometry()
    ?.getCoordinates();

export const getOffset = (
  deskFeatures: (DrawingDeskFeatures | DeskFeatures)[],
  currentIndex: number,
  beaconCoords: NumberArray2,
  deskCoords: NumberArray2,
) => {
  const [deskX, deskY] = deskCoords;
  const [beaconX, beaconY] = beaconCoords;
  if (
    deskFeatures.length === 1 ||
    (deskFeatures.length === 3 && currentIndex === 3)
  ) {
    return [Math.abs(beaconX - deskX), Math.abs(beaconY - deskY)];
  }
  const middlePt = [3, 4].includes(currentIndex)
    ? getMiddlePoint(
        getDeskAtIndex(3, deskFeatures),
        getDeskAtIndex(4, deskFeatures),
      )
    : getMiddlePoint(
        getDeskAtIndex(1, deskFeatures),
        getDeskAtIndex(2, deskFeatures),
      );
  if (
    middlePt &&
    typeof middlePt[0] === 'number' &&
    typeof middlePt[1] === 'number'
  ) {
    const offsetX = Math.sqrt(
      (middlePt[0] - deskX) ** 2 + (middlePt[1] - deskY) ** 2,
    );
    const offsetY = Math.sqrt(
      (middlePt[0] - beaconX) ** 2 + (middlePt[1] - beaconY) ** 2,
    );
    return [offsetX, offsetY];
  }
  return undefined;
};

// Function so the desk index doesn't need to be passed and "getDeskRotation" is still easily testable
export const getDeskFeatureRotation = (
  beaconCoords: number[],
  deskFeatures: (DrawingDeskFeatures | DeskFeatures)[],
) => {
  return getDeskRotation(
    beaconCoords,
    [getDeskAtIndex(1, deskFeatures), getDeskAtIndex(2, deskFeatures)].filter(
      Boolean,
    ),
  );
};

// Return desk rotation in degrees
export const getDeskRotation = (
  beaconCoords: number[],
  coordinates: (Coordinate | undefined)[],
): number | null => {
  const [beaconX, beaconY] = beaconCoords;
  if (coordinates.length === 1) {
    const [deskToMoveCoords] = coordinates;
    const [deskX, deskY] = deskToMoveCoords || [];
    if (
      typeof beaconX === 'number' &&
      typeof beaconY === 'number' &&
      typeof deskX === 'number' &&
      typeof deskY === 'number'
    ) {
      const rotation =
        (Math.atan2(deskY - beaconY, deskX - beaconX) * 180) / Math.PI;
      // rounded to avoid problematic tiny decimals
      return Math.round(rotation * 10 ** 10) / 10 ** 10;
    }
    return null;
  }
  const middlePt = getMiddlePoint(coordinates[0], coordinates[1]);
  const [middlePtX, middlePtY] = middlePt || [];
  if (
    typeof middlePtY === 'number' &&
    typeof middlePtX === 'number' &&
    typeof beaconY === 'number' &&
    typeof beaconX === 'number'
  ) {
    // Define rotation from line between beacon and the middle point between the two first desks
    const rotation =
      (Math.atan2(middlePtY - beaconY, middlePtX - beaconX) * 180) / Math.PI;
    return Math.round(rotation * 10 ** 10) / 10 ** 10;
  }
  return null;
};

const translateMirrorDesk = (
  deltaX: number,
  deltaY: number,
  rotation: number,
  beaconPosition: NumberArray2,
  middlePt: Coordinate,
  newCoordinates: Coordinate,
  associatedDesk: DrawingDeskFeatures,
) => {
  const [middlePtX, middlePtY] = middlePt;
  const [newCoordinatesX, newCoordinatesY] = newCoordinates;
  if (
    typeof middlePtX === 'number' &&
    typeof middlePtY === 'number' &&
    typeof newCoordinatesX === 'number' &&
    typeof newCoordinatesY === 'number'
  ) {
    if ([-90, 0, 90, 180].includes(rotation)) {
      associatedDesk.getGeometry()?.translate(deltaX, deltaY);
    } else {
      // https://stackoverflow.com/questions/3306838/algorithm-for-reflecting-a-point-across-a-line/3307181#3307181
      const mSlope =
        (beaconPosition[1] - middlePtY) / (beaconPosition[0] - middlePtX);
      const c =
        (beaconPosition[0] * middlePtY - middlePtX * beaconPosition[1]) /
        (beaconPosition[0] - middlePtX);
      const d =
        (newCoordinatesX + (newCoordinatesY - c) * mSlope) / (1 + mSlope ** 2);
      associatedDesk.setGeometry(
        new Point([
          2 * d - newCoordinatesX,
          2 * d * mSlope - newCoordinatesY + 2 * c,
        ]),
      );
    }
  }
  return associatedDesk;
};

interface MoveBeaconType {
  layer: VectorLayer<Polygon>;
  beaconLayers: GeometryLayer<any, Point>[];
  drawingBeaconLayer: GeometryLayer;
  extraLimitSource: VectorSource<Feature<Polygon>>;
}

const translateOneDesk = (
  deskToMove: DrawingDeskFeatures | DeskFeatures,
  rotation: number,
  deltaX: number,
  deltaY: number,
  beaconX: number,
  beaconY: number,
  mouseCoordinateX: number,
  mouseCoordinateY: number,
): Feature<Point> => {
  // Don't allow new coordinates to be further than the beacon position
  if (
    (rotation === 0 && mouseCoordinateX > beaconX + 5) ||
    (rotation === 180 && mouseCoordinateX < beaconX - 5) ||
    (rotation < 180 && rotation > 0 && mouseCoordinateY > beaconY) ||
    (rotation < 0 && mouseCoordinateY < beaconY)
  ) {
    if ([0, 180].includes(rotation)) {
      deskToMove
        .getGeometry()
        ?.translate([0, 180].includes(rotation) ? deltaX : -deltaX, 0);
    }
    if ((rotation > 0 && deltaY > 0) || (rotation < 0 && deltaY < 0)) {
      // Movement away from the beacon
      const radiansRotation = rotation * (Math.PI / 180);
      const newDeltaY = Math.abs(deltaY * Math.sin(radiansRotation));
      const newDeltaX = Math.sqrt(deltaY ** 2 - newDeltaY ** 2);

      deskToMove
        .getGeometry()
        ?.translate(
          rotation > 90 || rotation < -90 ? -newDeltaX : newDeltaX,
          rotation < 0 ? -newDeltaY : newDeltaY,
        );
    } else {
      // Movement in direction to the beacon
      const radiansRotation = (90 - rotation) * (Math.PI / 180);
      const newDeltaX = Math.abs(deltaY * Math.sin(radiansRotation));
      const newDeltaY = Math.sqrt(deltaY ** 2 - newDeltaX ** 2);

      deskToMove
        .getGeometry()
        ?.translate(
          (rotation < 90 && rotation > 0) || (rotation < 0 && rotation > -90)
            ? -newDeltaX
            : newDeltaX,
          rotation < 0 ? newDeltaY : -newDeltaY,
        );
    }
  }
  return deskToMove;
};

class MoveDesk extends Pointer {
  deskToMove?: DrawingDeskFeatures | DeskFeatures;

  coordinate: number[] | null;

  middlePt: number[] | null;

  rotation: number | null;

  layer: MoveBeaconType['layer'];

  source: VectorSource<DrawingDeskFeatures>; // drawingFeature

  beaconLayers: MoveBeaconType['beaconLayers'];

  drawingBeaconLayer: MoveBeaconType['drawingBeaconLayer'];

  extraLimitSource: MoveBeaconType['extraLimitSource'];

  deskLimits: Feature<Polygon>[];

  mapExtentPolygon: Polygon | null;

  selectedFeat: Feature<Point> | undefined;

  constructor(options: MoveBeaconType) {
    const { layer, beaconLayers, drawingBeaconLayer, extraLimitSource } =
      options;
    super();
    this.layer = layer;
    this.beaconLayers = beaconLayers;
    this.drawingBeaconLayer = drawingBeaconLayer;
    this.extraLimitSource = extraLimitSource;
    this.rotation = null;
    this.deskToMove = undefined;
    this.coordinate = null;
    this.middlePt = null;
    this.source =
      this.layer.olLayer.getSource() as VectorSource<DrawingDeskFeatures>;
    this.deskLimits = [];
    this.mapExtentPolygon = null;
    this.selectedFeat = undefined;
  }

  translateMirrorIfPossible(
    deltaX: number,
    deltaY: number,
    beaconX: number,
    beaconY: number,
    currentIndex: number,
    associatedDesks: DrawingDeskFeatures[],
    shouldMoveOppositeDesks = false,
  ) {
    if (this.deskToMove && this.middlePt && typeof this.rotation === 'number') {
      const deskClone = this.deskToMove.clone() as DrawingDeskFeatures;
      const deskGeomClone = deskClone.getGeometry();
      if (deskGeomClone) {
        // Clone desks to translate then and verify if the new position is within allowed limits
        deskGeomClone.translate(deltaX, deltaY);
        const translatedCoords = deskGeomClone.getCoordinates();
        const currentDeskLimit = this.deskLimits
          .find((f) => f.get('index') === currentIndex)
          ?.getGeometry();
        const associatedDesk = associatedDesks.find(
          (f) => getDeskFeatureIndex(f) === getAssociatedIndex(currentIndex),
        );
        if (!associatedDesk) {
          console.error('Associated desk not found');
          return;
        }
        const associatedDeskClone =
          associatedDesk.clone() as DrawingDeskFeatures;
        const associatedDeskGeomClone = translateMirrorDesk(
          [-90, 90].includes(this.rotation) ? -deltaX : deltaX,
          [0, 180].includes(this.rotation) ? -deltaY : deltaY,
          this.rotation,
          [beaconX, beaconY],
          this.middlePt,
          (this.deskToMove.getGeometry() as Point).getCoordinates(),
          associatedDeskClone,
        ).getGeometry();

        // Verify if the clone's new position is within allowed limits
        if (
          currentDeskLimit?.intersectsCoordinate(translatedCoords) &&
          associatedDeskGeomClone &&
          this.mapExtentPolygon?.intersectsCoordinate(
            associatedDeskGeomClone.getCoordinates(),
          )
        ) {
          // Should check all 4 movements simultaneously here
          if (shouldMoveOppositeDesks) {
            // To move desks on the opposite side of the selected desk
            const oppositeIndexesFeatures = associatedDesks.filter((f) =>
              [1, 2].includes(currentIndex)
                ? [3, 4].includes(getDeskFeatureIndex(f))
                : [1, 2].includes(getDeskFeatureIndex(f)),
            );

            const mirrorPoints = this.getMirrorPoints(associatedDesks);
            const [mirrorPt1, mirrorPt2] = mirrorPoints || [];
            if (mirrorPt1 && mirrorPt2) {
              const oppositeIndex = getOppositeIndex(currentIndex);
              const oppositeIndexesCloneFeats = oppositeIndexesFeatures.map(
                (d) =>
                  translateMirrorDesk(
                    this.rotation && [-90, 90].includes(this.rotation)
                      ? oppositeIndex === getDeskFeatureIndex(d)
                        ? deltaX
                        : -deltaX
                      : -deltaX,
                    this.rotation && [-90, 90].includes(this.rotation)
                      ? -deltaY
                      : oppositeIndex !== getDeskFeatureIndex(d)
                        ? -deltaY
                        : deltaY,
                    this.rotation ?? 0,
                    mirrorPt1,
                    [mirrorPt2[0], mirrorPt2[1]],
                    (
                      (
                        [deskClone, associatedDeskClone].find(
                          (f) =>
                            getDeskFeatureIndex(f) ===
                            getOppositeIndex(getDeskFeatureIndex(d)),
                        ) as DrawingDeskFeatures
                      ).getGeometry() as Point
                    ).getCoordinates(),
                    d.clone() as DrawingDeskFeatures,
                  ),
              );
              const oppositeIndexesCloneCoords = oppositeIndexesCloneFeats
                .map((f) => f.getGeometry()?.getCoordinates())
                .filter((c) => c) as number[][];
              if (
                oppositeIndexesCloneFeats.length ===
                  oppositeIndexesCloneCoords.length &&
                !oppositeIndexesCloneCoords.find(
                  (coords) =>
                    !this.mapExtentPolygon?.intersectsCoordinate(coords),
                )
              ) {
                this.deskToMove.setGeometry(deskGeomClone);
                associatedDesk.setGeometry(associatedDeskGeomClone);
                for (const f of oppositeIndexesFeatures) {
                  f.setGeometry(
                    oppositeIndexesCloneFeats
                      .find(
                        (newFeat) =>
                          getDeskFeatureIndex(f) ===
                          getDeskFeatureIndex(newFeat),
                      )
                      ?.getGeometry(),
                  );
                }
              }
            }
          } else {
            this.deskToMove.setGeometry(deskGeomClone);
            associatedDesk.setGeometry(associatedDeskGeomClone);
          }
        }
      }
    }
  }

  getMirrorPoints(
    associatedDesks: DrawingDeskFeatures[],
  ): [NumberArray2, NumberArray2] | null {
    if (this.deskToMove) {
      const currentIndex = getDeskFeatureIndex(this.deskToMove);
      const associatedIndex = getAssociatedIndex(currentIndex);
      const associatedDesk = (
        (
          associatedDesks.find(
            (f) => f.getProperties().Index === associatedIndex,
          ) as Feature<Point>
        ).getGeometry() as Point
      ).getCoordinates();
      const oppositeDesk1 = (
        (
          associatedDesks.find(
            (f) => f.getProperties().Index === getOppositeIndex(currentIndex),
          ) as Feature<Point>
        ).getGeometry() as Point
      ).getCoordinates();
      const oppositeDesk2 = (
        (
          associatedDesks.find(
            (f) =>
              f.getProperties().Index === getOppositeIndex(associatedIndex),
          ) as Feature<Point>
        ).getGeometry() as Point
      ).getCoordinates();
      const currentDeskCoords = (
        this.deskToMove.getGeometry() as Point
      ).getCoordinates();
      const [currentDeskCoordsX, currentDeskCoordsY] = currentDeskCoords;
      const [oppositeDesk1X, oppositeDesk1Y] = oppositeDesk1;
      const [associatedDeskX, associatedDeskY] = associatedDesk;
      const [oppositeDesk2X, oppositeDesk2Y] = oppositeDesk2;
      if (
        typeof currentDeskCoordsX === 'number' &&
        typeof currentDeskCoordsY === 'number' &&
        typeof associatedDeskX === 'number' &&
        typeof associatedDeskY === 'number' &&
        typeof oppositeDesk1X === 'number' &&
        typeof oppositeDesk1Y === 'number' &&
        typeof oppositeDesk2X === 'number' &&
        typeof oppositeDesk2Y === 'number'
      ) {
        return [
          [
            (currentDeskCoordsX + oppositeDesk1X) / 2,
            (currentDeskCoordsY + oppositeDesk1Y) / 2,
          ],
          [
            (associatedDeskX + oppositeDesk2X) / 2,
            (associatedDeskY + oppositeDesk2Y) / 2,
          ],
        ];
      }
    }

    return null;
  }

  handleDownEvent(evt: MapBrowserEvent<PointerEvent>): boolean {
    const deskFeatures = this.source.getFeatures();
    const deskIds = deskFeatures.map((f) => f.getProperties().Index);

    const drawingBeaconFeats = (
      this.drawingBeaconLayer.olLayer.getSource() as VectorSource<
        Feature<Point>
      >
    ).getFeatures();
    this.deskToMove = evt.map.forEachFeatureAtPixel(evt.pixel, (f) => {
      const feature = f as DrawingDeskFeatures;

      if (deskFeatures.indexOf(feature) > -1) {
        return feature;
      }

      return undefined;
    });

    this.mapExtentPolygon = fromExtent(
      evt.map.getView().getProjection().getExtent(),
    );

    [this.selectedFeat] = [
      ...this.beaconLayers.map((l) => l.getSelectedFeature()),
      ...drawingBeaconFeats,
    ].filter(isNonNullable);
    const beaconCoords = this.selectedFeat?.getGeometry()?.getCoordinates();
    if (!beaconCoords) {
      return false;
    }
    const [beaconX, beaconY] = beaconCoords;
    const deskCoords = this.deskToMove?.getGeometry()?.getCoordinates();
    if (
      !this.deskToMove ||
      !this.selectedFeat ||
      typeof beaconX !== 'number' ||
      typeof beaconY !== 'number' ||
      !deskCoords
    ) {
      this.deskToMove = undefined;
      this.selectedFeat = undefined;
      this.mapExtentPolygon = null;
      return false;
    }

    this.coordinate = deskCoords;
    this.rotation = getDeskFeatureRotation([beaconX, beaconY], deskFeatures);
    this.middlePt = getMiddlePoint(
      getDeskAtIndex(1, deskFeatures),
      getDeskAtIndex(2, deskFeatures),
    );
    const map = this.getMap();

    if (deskFeatures.length > 0 && map && typeof this.rotation === 'number') {
      const { rotation } = this;
      // 1/ Get distance from extent
      const extent = map.getView().getProjection().getExtent();
      const distance = Math.max(...extent);

      // 2/ Get 4 destinations based on initial rotation
      const rotations = getRotations(rotation);
      const destinations = rotations.map((bearing) =>
        getDestination([beaconX, beaconY], distance, bearing),
      );
      const [destination1, destination2, destination3, destination4] =
        destinations;
      if (destination1 && destination2 && destination3 && destination4) {
        // 3/ Create 2 lines from destinations
        const line1 = new LineString([destination1, destination3]);
        const line2 = new LineString([destination2, destination4]);

        const thickLineUnits = 'kilometers';
        const thickLineWidth = 100;

        // Create polygons from lines to be able to use turf difference
        const thickLines = [line1, line2].map((l) => {
          const lineOffset1 = lineOffset(
            lineString(l.getCoordinates()),
            thickLineWidth,
            {
              units: thickLineUnits,
            },
          );
          const lineOffset2 = lineOffset(
            lineString(l.getCoordinates()),
            -thickLineWidth,
            {
              units: thickLineUnits,
            },
          );
          const polygonCoords = lineOffset1.geometry.coordinates[0]
            ? [
                [
                  ...lineOffset1.geometry.coordinates,
                  ...lineOffset2.geometry.coordinates.reverse(),
                  lineOffset1.geometry.coordinates[0],
                ],
              ]
            : undefined;
          return polygonCoords ? polygon(polygonCoords) : undefined;
        });

        // 4/ Split Extent base on dividing lines
        const polyFeats = [] as Feature<Polygon>[];
        const difference1 = thickLines[1]
          ? difference(
              featureCollection([
                polygon(fromExtent(extent).getCoordinates()),
                thickLines[1],
              ]),
            )
          : null;
        if (difference1) {
          const difference2 = [];
          for (const c of difference1.geometry.coordinates) {
            const diff = thickLines[0]
              ? difference(
                  featureCollection([
                    polygon(c as number[][][]),
                    thickLines[0],
                  ]),
                )
              : null;
            if (diff) {
              difference2.push(diff);
            }
          }
          for (const diffGeom of difference2) {
            for (const c of diffGeom.geometry.coordinates) {
              const deskId = getDeskIndex(
                [beaconX, beaconY],
                c as number[][][],
                rotation,
                rotations,
              );
              // Add Polygon to vizualize desk move limits
              if (
                deskIds.includes(deskId) &&
                !(deskFeatures.length === 3 && deskId === 3)
              ) {
                polyFeats.push(
                  new Feature({
                    geometry: new Polygon(c as number[][][]),
                    index: getDeskIndex(
                      [beaconX, beaconY],
                      c as number[][][],
                      rotation,
                      rotations,
                    ),
                  }),
                );
              }
            }
          }
        }
        this.deskLimits = polyFeats;
        this.extraLimitSource.clear();
        if (deskFeatures.length > 1) {
          this.extraLimitSource.addFeatures(this.deskLimits);
        }

        this.dispatchEvent(
          new DeskMoveEvent(DeskMoveEventType.START, this.deskToMove, evt),
        );
        return true;
      }
    }
    return false;
  }

  handleDragEvent(evt: MapBrowserEvent<PointerEvent>): void {
    const [mouseCoordinateX, mouseCoordinateY] = evt.coordinate;
    const [deskCoordinateX, deskCoordinateY] = this.coordinate || [];

    const deskFeatures = this.source.getFeatures();

    if (
      typeof this.rotation !== 'number' ||
      !this.deskToMove ||
      typeof mouseCoordinateX !== 'number' ||
      typeof mouseCoordinateY !== 'number' ||
      typeof deskCoordinateX !== 'number' ||
      typeof deskCoordinateY !== 'number'
    ) {
      return;
    }
    const deltaX = mouseCoordinateX - deskCoordinateX;
    const deltaY = mouseCoordinateY - deskCoordinateY;

    const associatedDesks = deskFeatures
      .filter((f) => f !== this.deskToMove)
      .sort(
        (a, b) =>
          (a.getProperties().Index ?? 0) - (b.getProperties().Index ?? 0),
      );

    if (this.selectedFeat) {
      const currentIndex = getDeskFeatureIndex(this.deskToMove);

      const [beaconX, beaconY] = (
        this.selectedFeat.getGeometry() as Point
      ).getCoordinates();
      if (typeof beaconX === 'number' && typeof beaconY === 'number') {
        if (deskFeatures.length === 1) {
          const newPoint = new Point([mouseCoordinateX, mouseCoordinateY]);
          if (
            this.mapExtentPolygon?.intersectsCoordinate(
              newPoint.getCoordinates(),
            )
          ) {
            // Only move if the translated geometry stays within the map extent
            this.deskToMove.setGeometry(newPoint);
          }
        }
        if (this.middlePt) {
          if (deskFeatures.length === 2) {
            this.translateMirrorIfPossible(
              deltaX,
              deltaY,
              beaconX,
              beaconY,
              currentIndex,
              associatedDesks,
            );
          }
          if (deskFeatures.length === 3) {
            if ([1, 2].includes(getDeskFeatureIndex(this.deskToMove))) {
              this.translateMirrorIfPossible(
                deltaX,
                deltaY,
                beaconX,
                beaconY,
                currentIndex,
                associatedDesks,
              );
            } else {
              // For Desk index 3 only allow Y translation
              const [deskX, deskY] = (
                this.deskToMove.getGeometry() as Point
              ).getCoordinates();

              if (typeof deskX === 'number' && typeof deskY === 'number') {
                const deskRotation =
                  (Math.atan2(deskY - beaconY, deskX - beaconX) * 180) /
                  Math.PI;

                const translatedDeskCloneGeom = translateOneDesk(
                  this.deskToMove.clone() as DrawingDeskFeatures,
                  deskRotation,
                  deltaX,
                  deltaY,
                  beaconX,
                  beaconY,
                  mouseCoordinateX,
                  mouseCoordinateY,
                ).getGeometry();
                if (
                  translatedDeskCloneGeom &&
                  this.mapExtentPolygon?.intersectsCoordinate(
                    translatedDeskCloneGeom.getCoordinates(),
                  )
                ) {
                  // Only move if the translated geometry stays within the map extent
                  this.deskToMove.setGeometry(translatedDeskCloneGeom);
                }
              }
            }
          }
          if (deskFeatures.length === 4) {
            this.translateMirrorIfPossible(
              deltaX,
              deltaY,
              beaconX,
              beaconY,
              currentIndex,
              associatedDesks,
              true,
            );
          }
        }

        reRenderDeskOutlineFeatures(this.selectedFeat);
      }
    }
    this.coordinate = evt.coordinate;
  }

  handleUpEvent(evt: MapBrowserEvent<PointerEvent>): boolean {
    if (this.deskToMove) {
      const deskFeatures = this.source.getFeatures() as DrawingDeskFeatures[];
      if (this.selectedFeat) {
        const [beaconX, beaconY] = (
          this.selectedFeat.getGeometry() as Point
        ).getCoordinates();
        const [deskX, deskY] =
          this.deskToMove.getGeometry()?.getCoordinates() || [];
        const currentIndex = getDeskFeatureIndex(this.deskToMove);

        if (
          typeof beaconX === 'number' &&
          typeof beaconY === 'number' &&
          typeof deskX === 'number' &&
          typeof deskY === 'number'
        ) {
          this.dispatchEvent(
            new DeskMoveEvent(
              DeskMoveEventType.END,
              this.deskToMove,
              evt,
              getOffset(
                deskFeatures,
                currentIndex,
                [beaconX, beaconY],
                [deskX, deskY],
              ),
            ),
          );
        }
      }
    }
    this.selectedFeat = undefined;
    this.rotation = null;
    this.extraLimitSource.clear();
    this.mapExtentPolygon = null;
    this.middlePt = null;
    this.deskToMove = undefined;
    this.coordinate = null;
    this.deskLimits = [];
    return false;
  }
}

export default MoveDesk;
